import * as React from "react";
import { SearchTerm } from "@presentation/pages/search/filters/SearchTerm";
import Stars2 from "@presentation/pages/search/filters/Stars2";
import Highlights from "@presentation/pages/search/filters/Highlights";
import Facilities from "@presentation/pages/search/filters/Facilities";
import { AiOutlineClose, AiOutlineFilter } from "react-icons/ai";
import { Button } from "@presentation/shared/components/button/Button";
import { useState } from "react";
import { PriceSlider } from "@presentation/pages/search/filters/PriceSlider";

export function Filters({ filters }) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div className="lg:hidden">
        <Button type="outline" onClick={() => setVisible(true)}>
          <span className="inline-flex items-center">
            <AiOutlineFilter />
            <span className="ml-3">Filters</span>
          </span>
        </Button>
        {visible && (
          <div className="fixed top-0 left-0 w-full h-full bg-white z-[1000] px-6 py-4 overflow-y-scroll">
            <span
              className="absolute top-0 right-0 m-4 cursor-pointer"
              onClick={() => setVisible(false)}
            >
              <AiOutlineClose />
            </span>
            <SearchTerm {...filters.byName} />
            <PriceSlider {...filters.byPriceRange} />
            <Stars2 {...filters.byStars} />
            <Highlights {...filters.byHighlights} />
            <Facilities {...filters.byFacilities} />
          </div>
        )}
      </div>
      <div className="hidden lg:block w-80 pr-6">
        <SearchTerm {...filters.byName} />
        <PriceSlider {...filters.byPriceRange} />
        {/*<PriceRange {...filters.byPriceRange} />*/}
        <Stars2 {...filters.byStars} />
        <Highlights {...filters.byHighlights} />
        <Facilities {...filters.byFacilities} />
      </div>
    </>
  );
}
