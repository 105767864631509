import * as React from 'react';
import { FaStar } from 'react-icons/fa';

type RatingProps = {
  value: number;
  text?: string;
};

function Rating({ value, text }: RatingProps) {
  const result = Array.from(Array(value).keys());

  return (
    <div className="inline-flex">
      {result.map((iconType, i) => (
        <FaStar key={i} />
      ))}
      {text && <span>{text}</span>}
    </div>
  );
}

export default Rating;
