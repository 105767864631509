import * as React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigation } from "./navigation-context";
import { SEARCH_TYPES } from "@config/const/enums";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { Settings } from "../../../config/tenants/settings";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";
import { HotelResultDto } from "@infrastructure/api/dtos/HotelResultDto";

const DATE_FORMAT = "YYYY-MM-DD";
const EMPTY_ARRAY = [];

interface SelectOption {
  name: string;
}

interface SearchTypeConfig {
  current: boolean;
  searchType: string;
  departureType: string;
  returnType: string;
  returnId: string;
  departureId: number;
  destinationId: string;
  departureDate: string;
  returnDate: string;
  adults: number;
  children: Array<number>;

  setDepartureId(val: number): void;

  setReturnType?(val: string): void;

  setDestinationId(val: number): void;

  setDepartureDate(val: string): void;

  setReturnDate(val: string): void;

  setAdults(val: number): void;

  setChildren(val: number[]): void;

  departureOptions: SelectOption[];
  destinationOptions: SelectOption[];
  departureDateOptions: string[];
  returnDateOptions: string[];
  canSearch: boolean;

  doSearch(): void;

  loadingResults: boolean;
  results: HotelResultDto[];

  detailsUrl(id: number, accId?: number): string;

  bookingUrl(id: number, accId: number): string;

  hotelId: number;
  accommodationId: string;
  reservationData: object;

  performSearch(): void;

  resetSearch(): void;
}

interface SearchContextConfig {
  charter: SearchTypeConfig;
  hotel: SearchTypeConfig;
  current: SearchTypeConfig;
}

const SearchRepositoryContext = createContext<SearchContextConfig>(null);

export const useSearchRepository = () => useContext(SearchRepositoryContext);

export function SearchRepositoryProvider({ children }) {
  const value = useProvideSearchRepository();
  return (
    <SearchRepositoryContext.Provider value={value}>
      {children}
    </SearchRepositoryContext.Provider>
  );
}

function useSearchOptions(searchType, options, navUrl): SearchTypeConfig {
  const current = searchType === options.searchType;
  const departureType = searchType === SEARCH_TYPES.charter ? "airport" : "any";
  const [departureId, setDepartureId] = useState(options.departureId);
  const [returnType, setReturnType] = useState(options.returnType);
  const [returnId, setReturnId] = useState(options.returnId);
  const [departureDate, setDepartureDate] = useState(options.departureDate);
  const [returnDate, setReturnDate] = useState(options.returnDate);
  const [adults, setAdults] = useState(options.adults ?? 2);
  const [children, setChildren] = useState(options.children ?? []);
  const [departureOptions, setDepartureOptions] = useState([]);
  const [returnOptions, setReturnOptions] = useState([]);
  const [departureDateOptions, setDepartureDateOptions] = useState([]);
  const [returnDateOptions, setReturnDateOptions] = useState([]);
  const [hotelId, setHotelId] = useState(null);
  const [accommodationId, setAccommodationId] = useState(null);

  /**
   * Whenever options from params are changed, we need to update selection
   */

  useEffect(() => {
    const fields = {
      departureId: [departureId, setDepartureId],
      returnType: [returnType, setReturnType],
      returnId: [returnId, setReturnId],
      departureDate: [departureDate, setDepartureDate],
      returnDate: [returnDate, setReturnDate],
      adults: [adults, setAdults],
      children: [children, setChildren],
      hotelId: [hotelId, setHotelId],
      accommodationId: [accommodationId, setAccommodationId],
    };
    Object.entries(fields).forEach(([key, [val, setVal]]) => {
      if (options[key] && options[key] !== val) {
        setVal(options[key]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  /**
   * On first load, for charters, load the departure options, and select the default, if any
   */
  const { requestAsync: requestDepartures } = useService(Requests.departures);
  useEffect(() => {
    if (searchType === SEARCH_TYPES.charter) {
      requestDepartures({ searchType }).then((data) => {
        setDepartureOptions(data);
        const id = data.find((it) => it.default)?.id;
        if (id) {
          setDepartureId(id);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * When the departure id changes, reload the return options
   * Cache the return values until it is not present in the return options
   */
  const { requestAsync: requestReturns } = useService(Requests.destinations);
  useEffect(() => {
    if (departureId || searchType !== SEARCH_TYPES.charter) {
      requestReturns({ searchType, departureId }).then((data) => {
        const options = data.map((item) => ({
          ...item,
          type_id: item.id,
          id: item.type + "/" + item.id,
        }));
        setReturnOptions(options);
        if (returnType || returnId) {
          const exists = options.some(
            (it) => it.type === returnType && it.type_id === returnId
          );
          if (!exists) {
            setReturnType(null);
            setReturnId(null);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureId]);

  /**
   * Destination object is based on 2 composite values
   */
  const destination = useMemo(
    () =>
      returnOptions?.find(
        (it) => it.type === returnType && it.type_id === returnId
      ),
    [returnOptions, returnType, returnId]
  );

  const destinationId = destination?.id;
  const setDestinationId = useCallback(
    (value) => {
      if (!value) {
        setReturnType(null);
        setReturnId(null);
      } else {
        const existing = returnOptions?.find((it) => it.id === value);
        setReturnType(existing?.type);
        setReturnId(existing?.type_id);
      }
    },
    [returnOptions]
  );

  /**
   * When selected return values change
   * Retrieve dates for charter, and generate them for hotels
   */
  const { requestAsync: requestDepartureDates } = useService(
    Requests.departureDates
  );

  useEffect(() => {
    // Load departure dates
    if (departureId && returnType && returnId) {
      if (searchType === SEARCH_TYPES.charter) {
        requestDepartureDates({
          searchType,
          departureId,
          returnType,
          returnId,
        }).then((data) => {
          setDepartureDateOptions(data);
          if (departureDate && !data.includes(departureDate)) {
            setDepartureDate(null);
          }
        });
      } else {
        // Generate all possible dates
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnType, returnId]);

  /**
   * When departure date changes
   * Retrieve dates for charter, and generate them for hotels
   */
  const { requestAsync: requestReturnDates } = useService(Requests.returnDates);

  useEffect(() => {
    // Load departure dates
    if (departureId && returnType && returnId && departureDate) {
      if (searchType === SEARCH_TYPES.charter) {
        requestReturnDates({
          searchType,
          departureId,
          returnType,
          returnId,
          departureDate,
        }).then((data) => {
          setReturnDateOptions(data);
          if (returnDate && !data.includes(returnDate)) {
            setReturnDate(null);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType, departureDate, requestDepartureDates]);

  useEffect(() => {
    if (searchType === SEARCH_TYPES.hotel) {
      const {
        minBookingStartDays,
        maxBookingStartDays,
        minBookingDurationDays,
        maxBookingDurationDays,
      } = Settings.search[searchType];
      const dates = [];
      for (let i = minBookingStartDays; i <= maxBookingStartDays; i++) {
        dates.push(moment.utc().add(i, "days").format(DATE_FORMAT));
      }
      setDepartureDateOptions(dates);
      if (departureDate) {
        const dates = [];
        const departure = moment.utc(departureDate);
        for (let i = minBookingDurationDays; i <= maxBookingDurationDays; i++) {
          dates.push(departure.clone().add(i, "days").format(DATE_FORMAT));
        }
        setReturnDateOptions(dates);
      }
    }
  }, [searchType, departureDate]);

  const canSearch = useMemo(() => {
    return Boolean(
      (searchType !== SEARCH_TYPES.charter || (departureType && departureId)) &&
        returnType &&
        returnId &&
        departureDate &&
        returnDate &&
        adults
    );
  }, [
    searchType,
    departureType,
    departureId,
    returnType,
    returnId,
    departureDate,
    returnDate,
    adults,
  ]);

  /**
   * Effectively do the search request on the API
   */
  const [results, setResults] = useState(null);
  const { loading: loadingResults, requestAsync: requestResults } = useService(
    Requests.results
  );

  const navigate = useNavigate();

  const performSearch = useCallback(() => {
    requestResults({
      searchType,
      departureId,
      returnType,
      returnId,
      departureDate,
      returnDate,
      adults,
      children,
    }).then((data) => {
      setResults(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchType,
    departureId,
    returnType,
    returnId,
    departureDate,
    returnDate,
    adults,
    children,
  ]);

  const doSearch = useCallback(() => {
    const url = navUrl("search", {
      searchType,
      departureType,
      departureId,
      returnType,
      returnId,
      departureDate,
      returnDate,
      adults,
      children,
    });
    navigate(url);
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navUrl,
    searchType,
    departureType,
    departureId,
    returnType,
    returnId,
    departureDate,
    returnDate,
    adults,
    children,
    navigate,
    performSearch,
  ]);

  const createUrl = useCallback(
    (page, hotelId = null, accommodationId = null) => {
      return navUrl(page, {
        searchType,
        departureType,
        departureId,
        returnType,
        returnId,
        departureDate,
        returnDate,
        adults,
        children,
        hotelId,
        accommodationId,
      });
    },
    [
      navUrl,
      searchType,
      departureType,
      departureId,
      returnType,
      returnId,
      departureDate,
      returnDate,
      adults,
      children,
    ]
  );

  const detailsUrl = useCallback(
    (hotel, accommodation) => createUrl("details", hotel, accommodation),
    [createUrl]
  );

  const bookingUrl = useCallback(
    (hotel, accommodation) => createUrl("booking", hotel, accommodation),
    [createUrl]
  );

  const reservationData = useMemo(() => {
    return {
      searchType,
      airport: departureId,
      destType: returnType,
      destId: returnId,
      departureDate,
      returnDate,
      adults,
      children,
      hotelId,
      accommodationId,
    };
  }, [
    adults,
    children,
    departureDate,
    departureId,
    returnDate,
    returnId,
    returnType,
    searchType,
    hotelId,
    accommodationId,
  ]);

  const resetSearch = useCallback(() => {
    setDepartureDate(null);
    setResults(null);
  }, []);

  return {
    current,
    searchType,
    departureType,
    departureId,
    returnType,
    returnId,
    destinationId,
    departureDate,
    returnDate,
    adults,
    children,
    setDepartureId,
    setDestinationId,
    setDepartureDate,
    setReturnDate,
    setAdults,
    setChildren,
    departureOptions,
    destinationOptions: returnOptions,
    departureDateOptions,
    returnDateOptions,
    canSearch,
    doSearch,
    loadingResults,
    results: results ?? EMPTY_ARRAY,
    detailsUrl,
    bookingUrl,
    hotelId,
    accommodationId,
    reservationData,
    performSearch,
    resetSearch,
  };
}

function useProvideSearchRepository() {
  // We observe the change in search options
  // Need to keep cache and provide the data if options didn't change
  const { options: orlOptions, navUrl } = useNavigation();
  const [searchType, setSearchType] = useState(orlOptions.searchType);

  const options = useMemo(
    () => ({ ...orlOptions, searchType }),
    [orlOptions, searchType]
  );

  const charter = useSearchOptions(SEARCH_TYPES.charter, options, navUrl);
  const hotel = useSearchOptions(SEARCH_TYPES.hotel, options, navUrl);

  const current = charter.current ? charter : hotel;

  return {
    setSearchType,
    charter,
    hotel,
    current,
  };
}
