import PropTypes from "prop-types";
import classNames from "classnames";
import { useCallback, useRef } from "react";

function TextInput(props) {
  const ref = useRef();

  const {
    className,
    inputClassName,
    placeholder = "",
    value,
    onChange: onExternalChange,
    disabled = false,
    multiline = false,
    rows = 5,
    hasError = false,
    prefix = null,
    suffix = null,
    right = false,
    Icon = null,
    type = "text",
    ...rest
  } = props;

  const onChange = useCallback(
    (val) => {
      onExternalChange(val?.target ? val.target.value : val);
    },
    [onExternalChange]
  );

  const commonClasses =
    "px-4 py-2 focus:ring-indigo-500 block w-full shadow-sm border";
  const roundingClasses = {
    "rounded-l": !prefix && suffix,
    "rounded-r": prefix && !suffix,
    rounded: !prefix && !suffix,
  };

  if (multiline) {
    return (
      <textarea
        className={classNames(
          "mt-1",
          commonClasses,
          roundingClasses,
          hasError
            ? "border-red-300 focus:border-red-500"
            : "border-gray-300 focus:border-indigo-500",
          { "bg-gray-100 text-gray-400": disabled },
          className,
          inputClassName
        )}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        disabled={disabled}
        {...rest}
      />
    );
  }

  return (
    <div className={classNames("flex flex-row", className)}>
      {prefix && (
        <div
          className={classNames(
            "bg-gray-200 rounded-l border border-l-0",
            hasError ? "border-red-500" : "border-gray-400"
          )}
        >
          {prefix}
        </div>
      )}
      <div className="relative text-sm w-full">
        <input
          ref={ref}
          type={type}
          className={classNames(
            commonClasses,
            roundingClasses,
            hasError
              ? "border-red-500 focus:border-red-500"
              : "border-gray-300 focus:border-indigo-500",
            { "bg-gray-100 text-gray-400": disabled, "pl-10": Icon },
            right ? "text-right" : "text-left",
            inputClassName
          )}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
        />
        {Icon && (
          <Icon
            size={22}
            className="text-gray-200 absolute top-2 left-2 cursor-text"
            onClick={() => ref.current?.focus()}
          />
        )}
      </div>
      {suffix && (
        <div
          className={classNames(
            "flex items-center bg-gray-200 rounded-r px-3",
            hasError ? "border-red-300" : "border-gray-400"
          )}
        >
          {suffix}
        </div>
      )}
    </div>
  );
}

TextInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  hasError: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  right: PropTypes.bool,
  Icon: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
};

export default TextInput;
