import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import agentRoutes from "./agent/routes";
import appRoutes from "./presentation/routes";
import { CompanyProvider } from "@application/context/company-context";
import { UserProvider } from "@agent/context/user-context";
import { theme } from "@presentation/theme/theme";
import { AppProvider } from "@application/context/app-context";
import { Settings } from "@config/tenants/bootstrap";
import * as ReactGA from "react-ga";
import { GoogleFontLoader } from "@dependencies/react-google-font-loader";

if (Settings.googleAnalyticsTrackingId) {
  ReactGA.initialize(Settings.googleAnalyticsTrackingId, { debug: true });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const router = createBrowserRouter([...agentRoutes, ...appRoutes]);

if (Settings.rootNode) {
  const root = ReactDOM.createRoot(Settings.rootNode);

  root.render(
    // <React.StrictMode>
    <AppProvider>
      {theme.fonts
        .filter((it) => it.import)
        .map((it) => (
          <link key={it.import} href={it.import} rel="stylesheet" />
        ))}
      <GoogleFontLoader
        fonts={theme.fonts.filter((it) => !it.import)}
        subsets={["latin-ext", "cyrillic-ext"]}
      />
      <CompanyProvider>
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </CompanyProvider>
    </AppProvider>
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
