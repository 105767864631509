import { useEffect, useState } from 'react';

export function useIsMobile(): boolean {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    let timeout;
    const listener = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => setWidth(window.innerWidth), 200);
    };
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return width < 768;
}
