import * as React from "react";
import { useCompany } from "@application/context/company-context";
import { cx } from "@presentation/vendor/classnames";
import { useTheme } from "../../theme/useTheme";

function FooterBottom() {
  const { footer } = useTheme();
  const { name } = useCompany();

  return (
    <div className={cx(footer.bottom.container)}>
      <div className="container mx-auto flex justify-between items-center">
        <div>
          &copy; Copyright {name} {new Date().getFullYear()}. All rights
          reserved.
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default FooterBottom;
