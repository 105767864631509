import * as React from 'react';
import { cx } from '@presentation/vendor/classnames';

interface NumberInputProps {
  value: number;
  canIncrease: boolean;
  canDecrease: boolean;

  onIncrease(): void;

  onDecrease(): void;
}

export function NumberInput(props: NumberInputProps) {
  const { value, canIncrease, canDecrease, onIncrease, onDecrease } = props;
  const boxCx = 'border border-gray-300 text-sm px-2 py-1 mx-0.5 rounded';
  const btnCx = 'hover:border-gray-500 cursor-pointer';
  return (
    <div className="flex justify-between items-center">
      <div
        className={cx(boxCx, canDecrease ? btnCx : 'cursor-default opacity-40')}
        onClick={canDecrease ? onDecrease : undefined}
      >
        -
      </div>
      <div className={cx(boxCx, 'w-8 text-center')}>{value}</div>
      <div
        className={cx(boxCx, canIncrease ? btnCx : 'cursor-default opacity-40')}
        onClick={canIncrease ? onIncrease : undefined}
      >
        +
      </div>
    </div>
  );
}
