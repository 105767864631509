import * as React from 'react';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import * as classNames from 'classnames';
import { useOnClickOutside } from '@presentation/shared/hooks/useOnClickOutside';
import { NumberInput } from './NumberInput';
import { MdPeopleOutline } from 'react-icons/md';
import { RiArrowDownSLine } from 'react-icons/ri';
import { Settings } from '@config/tenants/settings.default';
import { T } from '@config/lang';

interface NumberGuestsInputProps {
  searchType: string;
  adults: number;
  children: number[];

  onAdultsChange(val: number): void;

  onChildrenChange(val: number[] | ((v: number[]) => number[])): void;

  label?: string;
}

export function NumberGuestsInput(props: NumberGuestsInputProps): ReactElement {
  const {
    label,
    searchType,
    adults,
    children,
    onAdultsChange,
    onChildrenChange,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const onCancel = useCallback(() => setOpen(false), []);

  const ref = useRef();
  useOnClickOutside(ref, onCancel);

  const { adultOptions, childrenOptions } = useMemo(() => {
    const {
      minNumAdults = 1,
      maxNumAdults = 4,
      minNumChildren = 0,
      maxNumChildren = 3,
    } = Settings.search?.[searchType] ?? {};

    return {
      adultOptions: {
        value: adults,
        canDecrease: adults > minNumAdults,
        canIncrease: adults < maxNumAdults,
        onDecrease: () => onAdultsChange(adults - 1),
        onIncrease: () => onAdultsChange(adults + 1),
      },
      childrenOptions: {
        value: children.length,
        canDecrease: children.length > minNumChildren,
        canIncrease: children.length < maxNumChildren,
        onDecrease: () => onChildrenChange((chd) => chd.slice(0, -1)),
        onIncrease: () => onChildrenChange((chd) => [...chd, 0]),
      },
    };
  }, [searchType, adults, children.length, onAdultsChange, onChildrenChange]);

  const setChildAge = useCallback(
    (age, idx) => {
      children.splice(idx, 1, age);
      onChildrenChange([...children]);
    },
    [children, onChildrenChange]
  );

  return (
    <div
      className={classNames(
        'relative flex flex-col justify-center items-center border border-gray-300 rounded shadow-sm select-none'
      )}
      onClick={open ? undefined : () => setOpen(true)}
    >
      <div className="w-full flex justify-between items-center text-base cursor-default bg-white rounded">
        <span
          className={classNames(
            'p-2 text-xl text-gray-500',
            open ? 'text-gray-500' : 'text-gray-300'
          )}
        >
          <MdPeopleOutline />
        </span>
        <span className="ml-1 mr-2 pt-5 flex flex-col flex-grow justify-center items-start flex-nowrap overflow-x-hidden">
          {label && (
            <div
              className={classNames(
                'absolute top-0 w-full text-xs text-gray-400 py-0.5'
              )}
            >
              {label}
            </div>
          )}
          <div className="inline-flex items-center text-sm md:text-base py-0.5">
            <span className="whitespace-nowrap">
              {adults === 1
                ? `1 ${T.search.adult}`
                : `${adults} ${T.search.adults}`}
            </span>
            {children?.length > 0 && (
              <>
                <span className="whitespace-nowrap mr-1 ml-0.5">,</span>
                <span className="whitespace-nowrap">
                  {children.length === 1
                    ? `1 ${T.search.child}`
                    : `${children.length} ${T.search.children}`}
                </span>
              </>
            )}
          </div>
        </span>
        <span className="flex items-center pr-2">
          <RiArrowDownSLine
            className={classNames(
              'w-5 h-5 text-gray-400 hover:text-gray-600 transition-transform ease-in-out duration-200 transform',
              open ? 'rotate-180' : 'rotate-0'
            )}
            aria-hidden="true"
          />
        </span>
      </div>
      <div ref={ref} className="relative w-full right-0">
        {open && (
          <div className="absolute mt-2 border border-gray-200 shadow bg-white rounded p-4 text-base w-72">
            <div className="flex items-center justify-between my-3">
              <div className="mr-3">
                <div className="font-bold">Adults</div>
                <div className="text-xs text-gray-500">17+ years</div>
              </div>
              <NumberInput {...adultOptions} />
            </div>
            <div className="flex items-center justify-between my-3">
              <div className="mr-3">
                <div className="font-bold">Children</div>
                <div className="text-xs text-gray-500">0-17 years</div>
              </div>
              <NumberInput {...childrenOptions} />
            </div>
            <div className="grid grid-cols-2 text-sm gap-4">
              {children.map((child, idx) => {
                return (
                  <div>
                    <div className="text-xs font-bold">Child {idx + 1} age</div>
                    <select
                      className="my-1 pl-2 pr-0 py-0 text-sm border border-gray-300"
                      value={child}
                      onChange={(e) => setChildAge(e.target.value, idx)}
                    >
                      {Array.from(new Array(16).keys()).map((age) => (
                        <option key={age} value={age}>
                          {age} years
                        </option>
                      ))}
                    </select>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
