import Rating from '../../shared/components/rating/Rating';

const DetailsBreadcrumb = ({ hotel }) => {
  return (
    <div>
      <h2 className="font-bold text-3xl">{hotel.name}</h2>
      <p>{hotel.location}</p>
      <div className="pb-2 text-green-500">
        <Rating value={hotel.category} />
      </div>
    </div>
  );
};

export default DetailsBreadcrumb;
