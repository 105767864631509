import { HomeLayout } from "../../shared/layout/HomeLayout";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { NewsLetter } from "@presentation/pages/home/NewsLetter";
import { Offers } from "@presentation/pages/home/Offers";

function HomePage() {
  const { data: offers } = useService(Requests.offers, true);

  return (
    <HomeLayout>
      <Offers offers={offers} />
      <NewsLetter />
    </HomeLayout>
  );
}

export default HomePage;
