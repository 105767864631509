import PropTypes from 'prop-types';
import classNames from 'classnames';

function FormField({
  className,
  label,
  description,
  children,
  error,
  hideErrors = false,
}) {
  return (
    <div className={classNames('mb-3', className)}>
      <label className={'block font-bold text-gray-600'}>{label}</label>
      <div className="mt-2">{children}</div>
      {description && (
        <p className="mt-2 text-sm text-gray-400">{description}</p>
      )}
      {!hideErrors && (
        <p
          className={classNames(
            'mt-1 text-sm transition duration-500 transition-colors',
            error ? 'text-red-500' : 'text-white',
          )}
        >
          {error}&nbsp;
        </p>
      )}
    </div>
  );
}

FormField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.string,
  children: PropTypes.any,
  error: PropTypes.any,
  hideErrors: PropTypes.bool,
};

export default FormField;
