import { useRef, useState } from "react";
import { Button } from "@presentation/shared/components/button/Button";
import { RegistrationForm } from "@presentation/pages/giveaway/RegistrationForm";
import { FaPlayCircle } from "react-icons/fa";
import { Settings } from "@config/tenants/settings";

export function GiveAway() {
  const video = useRef<HTMLVideoElement>();
  const [step, setStep] = useState(1);

  const onEnded = () => {
    setStep(1);
  };

  const playAgain = () => {
    setStep(0);
    if (video.current) {
      video.current.controls = true;
      video.current.muted = false;
      video.current?.play();
    }
  };

  const register = () => {
    setStep(2);
  };

  return (
    <div className="w-screen h-screen bg-primary-900 relative">
      <video
        ref={video}
        className="w-screen h-screen bg-primary-900"
        onEnded={onEnded}
      >
        <source
          src={Settings.endpoint + "/videos/ETC_Travel_video.mp4"}
          type="video/mp4"
        />
      </video>

      {step < 0 && (
        <div
          className="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center"
          onClick={playAgain}
        >
          <span className="text-9xl opacity-70 text-white">
            <FaPlayCircle />
          </span>
        </div>
      )}

      {step === 1 && (
        <div className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center">
          <div className="flex flex-col items-center h-screen justify-around">
            <div />
            <span
              className="text-9xl opacity-70 text-white"
              onClick={playAgain}
            >
              <FaPlayCircle />
            </span>
            <Button onClick={register}>Учествувај во наградната игра</Button>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-70">
          <RegistrationForm />
        </div>
      )}
    </div>
  );
}
