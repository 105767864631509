import * as React from "react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Settings } from "@config/tenants/bootstrap";
import { LANGUAGE_NAMES, T, translations } from "@config/lang";

/**
 * This context is responsible for:
 *  - reading environment variables
 *  - reading props from the root node
 *  - exporting static config
 */

interface AppContextType {
  language: string;
  languageName: string;

  setLanguage(language: string): void;
}

const AppContext = createContext<AppContextType>(null);

export const useApp = () => useContext(AppContext);

type Props = {
  children: ReactNode;
};

export function AppProvider({ children }: Props) {
  const [language, setLanguage] = useState<string>(Settings.language);

  const value = useMemo(() => {
    return {
      language,
      languageName: LANGUAGE_NAMES[language],
      setLanguage,
    };
  }, [language]);

  useEffect(() => {
    const data = translations[language];
    sessionStorage.setItem("lang", language);
    Object.entries(data).forEach(([key, value]) => {
      T[key] = value;
    });
  }, [language]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
