import * as React from "react";
import { IconType } from "react-icons/lib";

type Props = {
  Icon: IconType;
  title: string;
};

export function PageTitle({ Icon, title }: Props) {
  return (
    <div className="text-xl font-bold text-gray-500 inline-flex items-center whitespace-nowrap">
      <Icon className="mr-4" size={28} /> {title}
    </div>
  );
}
