import { PageLayout } from "../../shared/layout/PageLayout";
import { NewBooking } from "@presentation/pages/booking/NewBooking";
import { useSearchRepository } from "@presentation/shared/context/search-repository-context";

function BookingPage() {
  const { current } = useSearchRepository();
  const { hotelId, accommodationId } = current;

  return (
    <PageLayout>
      <div className="container mx-auto">
        <NewBooking hotelId={hotelId} accommodationId={accommodationId} />
      </div>
    </PageLayout>
  );
}

export default BookingPage;
