import * as React from "react";
import SearchBox from "../../shared/components/search-box/SearchBox";
import ResultList from "./ResultList";
import { PageLayout } from "../../shared/layout/PageLayout";
import { Filters } from "./Filters";
import { useFiltering } from "@presentation/pages/search/useFiltering";
import { useSearchRepository } from "@presentation/shared/context/search-repository-context";
import { useEffect } from "react";
import { cx } from "@presentation/vendor/classnames";
import { useTheme } from "@presentation/theme/useTheme";

function SearchPage() {
  const { searchBox } = useTheme();
  const { filters, filteredResults } = useFiltering();
  const {
    current: { performSearch, canSearch, loadingResults },
  } = useSearchRepository();

  useEffect(() => {
    if (!loadingResults && !filteredResults.length && canSearch) {
      performSearch();
    }
  }, [canSearch]);

  return (
    <PageLayout>
      <div className={cx("py-4", searchBox.internal.container)}>
        <div className="container mx-auto">
          <SearchBox />
        </div>
      </div>
      <div className="container mx-auto flex flex-col lg:flex-row py-4">
        <Filters filters={filters} />
        <ResultList results={filteredResults} />
      </div>
    </PageLayout>
  );
}

export default SearchPage;
