import ReactSlider from "react-slider";
import "./PriceSlider.css";
import { priceEuro } from "@presentation/shared/utils/price-format";
import * as React from "react";

const STEP = 50;

export function PriceSlider(props) {
  const { value, setValue, options } = props;
  
  if (!options) {
    return null;
  }

  const [minPrice, maxPrice] = options;
  const min = Math.floor(minPrice / STEP) * STEP;
  const max = Math.ceil(maxPrice / STEP) * STEP;

  return (
    <div className="flex flex-col items-stretch">
      <h6 className="text-lg border-b border-gray-300 mb-2">Price range</h6>
      <div className="flex justify-between items-center">
        <span>{priceEuro(value?.[0] ?? options?.[0])}</span>
        <span>{priceEuro(value?.[1] ?? options?.[1])}</span>
      </div>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        defaultValue={options}
        value={value ?? options}
        onChange={setValue}
        min={Math.floor(min / STEP) * STEP}
        max={Math.ceil(max / STEP) * STEP}
        step={STEP}
        minDistance={STEP}
      />
    </div>
  );
}
