import { useService } from "@infrastructure/api/hooks/use-service";
import { T } from "@config/lang";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { BsCalendarPlus } from "react-icons/bs";
import { PageTitle } from "@agent/components/PageTitle";
import { ButtonGroup } from "@agent/components/ButtonGroup";
import { SearchBox } from "@agent/components/SearchBox";
import { useSearch } from "@agent/hooks/useSearch";
import { FullPageLoader } from "@presentation/shared/components/loader/FullPageLoader";
import { Button } from "@presentation/shared/components/button/Button";
import { Requests } from "@infrastructure/api/requests";
import {
  HotelResult,
  HotelResultRoom,
  SearchResults,
} from "@agent/pages/new-booking/SearchResults";
import { BookNow } from "@agent/pages/new-booking/BookNow";
import { Settings } from "@config/tenants/settings.default";
import { SEARCH_TYPES } from "@config/const/enums";

export function NewBooking() {
  const searchTypeMap = {
    [SEARCH_TYPES.charter]: T.search_type_charter,
    [SEARCH_TYPES.hotel]: T.search_type_hotel,
  };

  const searchTypes = (
    Settings.search.order ?? Object.values(SEARCH_TYPES)
  ).map((it) => ({
    value: it,
    name: searchTypeMap[it],
  }));

  const [searchType, setSearchType] = useState(
    searchTypes?.[0]?.value ?? SEARCH_TYPES.charter
  );

  const search = useSearch(searchType);
  const {
    loading: loadingSearch,
    extendLastNight,
    setExtendLastNight,
  } = search;

  const { show_extend_hotel_night } = Settings.booking;

  const {
    loading: loadingResults,
    data: results,
    requestAsync: performSearch,
  } = useService(Requests.results);

  const loading = loadingSearch || loadingResults;

  const doSearch = async () => {
    if (search.isValid) {
      const {
        searchType,
        departureId,
        returnType,
        returnId,
        departDate: departureDate,
        returnDate,
        extendLastNight,
        adults,
        children,
      } = search;
      await performSearch({
        searchType,
        departureId,
        returnType,
        returnId,
        departureDate,
        returnDate,
        extendLastNight,
        adults,
        children,
      });
    }
  };

  useEffect(() => {
    doSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendLastNight]);

  const filteredResults = useMemo(() => results, [results]);

  const [booking, setBooking] = useState(null);

  const bookNow = (hotel: HotelResult, room: HotelResultRoom) => {
    setBooking({ hotel, room, search });
  };

  return (
    <div>
      {loading && <FullPageLoader />}
      {booking && <BookNow {...booking} onHide={() => setBooking(null)} />}
      <div className="flex justify-between items-center mb-4">
        <PageTitle Icon={BsCalendarPlus} title={T.agent.new_booking} />
        <ButtonGroup
          className="ml-4"
          options={searchTypes}
          value={searchType}
          onChange={setSearchType}
        />
        {show_extend_hotel_night && searchType === SEARCH_TYPES.charter ? (
          <div className="inline-flex items-center">
            <input
              type="checkbox"
              className="mr-3"
              checked={extendLastNight}
              onChange={(e) => setExtendLastNight(e.target.checked)}
            />
            {T.booking.extend_last_night}
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="pb-4 lg:flex justify-between">
        <SearchBox search={search} />
        <Button
          className="ml-4"
          type="primary"
          onClick={doSearch}
          disabled={!search.isValid}
        >
          {T.search.search}
        </Button>
      </div>
      <SearchResults results={filteredResults} bookNow={bookNow} />
    </div>
  );
}
