import * as React from 'react';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';
import { ReactNode } from 'react';

type Option = {
  id: string;
  name: string;
  selected: boolean;
  count: number;
};

type CustomCheckboxProps = {
  toggle(option: Option): void;
  option: Option;
  renderName?(): ReactNode;
};

function CustomCheckbox({ toggle, option, renderName }: CustomCheckboxProps) {
  return (
    <div
      key={option.id}
      onClick={() => toggle(option)}
      className="flex items-center justify-between my-1"
    >
      <div className="flex items-center">
        <div className="check mx-1 text-blue-700">
          {option.selected ? <FiCheckSquare /> : <FiSquare />}
        </div>
        <div className="flex-grow name px-1 text-sm text-gray-700">
          {renderName ? renderName() : option.name}
        </div>
      </div>
      <div className="text-sm text-gray-400">{option.count}</div>
    </div>
  );
}

export default CustomCheckbox;
