import { useNavigate } from "react-router-dom";
import { useSearchRepository } from "@presentation/shared/context/search-repository-context";
import { useEffect, useMemo, useRef, useState } from "react";
import { Settings } from "@config/tenants/settings.default";
import { T } from "@config/lang";
import { useBookingForm } from "@presentation/pages/booking/useBookingForm";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { BookingSummary } from "@presentation/pages/booking/BookingSummary";
import { FlightInfo } from "@presentation/pages/details/FlightInfo";
import PassengerBookingInfo from "@presentation/pages/booking/PassengerBookingInfo";
import ContactBookingInfo from "@presentation/pages/booking/ContactBookingInfo";
import FormField from "@presentation/shared/components/form-field/FormField";
import TextInput from "@presentation/shared/components/text-input/TextInput";
import RadioInput from "@presentation/shared/components/radio/RadioInput";
import { Button } from "@presentation/shared/components/button/Button";
import { InlineSpinner } from "@presentation/shared/components/inline-spinner/InlineSpinner";
import { FullPageLoader } from "@presentation/shared/components/loader/FullPageLoader";
import { PAGES, useIFrame } from "@presentation/shared/context/iframe-context";
import { TermsModal } from "@presentation/pages/booking/TermsModal";

const REQUIRE_PASS_NO = Settings.booking.passengers.require_passport_no;

const termsRegExp = /\[(\w+:[^\]]+)]/;
const popupLinks = ["terms", "privacy"];

export function NewBooking({ hotelId, accommodationId }) {
  const navigate = useNavigate();
  const { iframe, setPage } = useIFrame();
  const { current } = useSearchRepository();

  const { results, reservationData, loadingResults, canSearch, performSearch } =
    current;

  const paymentTypes = useMemo(
    () =>
      Settings.booking.payments
        .filter((it) => it.public)
        .map((it) => ({ ...it, value: it.type, name: T[it.label] })),
    []
  );

  const loaded = useRef(false);
  useEffect(() => {
    if (!loaded.current && canSearch && !results?.length && !loadingResults) {
      loaded.current = true;
      performSearch();
    }
  }, [canSearch, results, performSearch, loadingResults, loaded]);

  const hotel = useMemo(
    () => results?.find((it) => it.id === hotelId),
    [results, hotelId]
  );

  const room = useMemo(
    () =>
      hotel?.room_details?.find(
        (it) => it.accommodation_id?.toString() === accommodationId?.toString()
      ),
    [hotel, accommodationId]
  );

  const { values, fields, isValid, errors } = useBookingForm(current);

  const [showErrors, setShowErrors] = useState(false);

  const { loading: loadingPostBooking, requestAsync: postBooking } = useService(
    Requests.postBooking
  );

  const [error, setError] = useState(null);

  const handleSubmit = () => {
    setShowErrors(true);
    if (isValid) {
      const payload = {
        ...reservationData,
        children: [...reservationData["children"]].sort((a, b) =>
          a < b ? -1 : 1
        ),
        ...values,
        hotelId,
        accommodationId,
        extendedStay: false,
        stop_sale: room?.stop_sale,
      };

      setError(null);
      postBooking({ payload })
        .then((data) => {
          if (data?.success) {
            if (iframe) {
              setPage(PAGES.CONFIRMATION, null, null, data.code);
            } else {
              navigate(`/confirmation/${data.code}`);
            }
          } else {
            setError(data.message);
          }
        })
        .catch(() => {
          setError(
            "An error occurred. Cannot proceed with booking confirmation. Please try again."
          );
        });
    }
  };

  const [termsPage, setTermsPage] = useState(null);

  const renderTermsOfUse = () => {
    const parts = T.booking.agree_to_terms?.split(termsRegExp);
    return parts.map((part) => {
      for (const slug of popupLinks) {
        if (part.startsWith(slug + ":")) {
          return (
            <span
              className="text-blue-600 hover:text-blue-400 cursor-pointer"
              onClick={() => setTermsPage(slug)}
            >
              {part.substring(slug.length + 1)}
            </span>
          );
        }
      }
      return <span>{part}&nbsp;</span>;
    });
  };

  if (loadingResults) {
    return <FullPageLoader />;
  }

  if (!room) {
    return null;
  }

  return (
    <div>
      {termsPage && (
        <TermsModal page={termsPage} onHide={() => setTermsPage(null)} />
      )}

      <h6 className="py-4 text-2xl">{T.booking.booking_summary}</h6>
      <BookingSummary hotel={hotel} room={room} />

      <FlightInfo charter={hotel} />

      <h6 className="py-2 font-bold text-lg">
        {T.booking.passenger_information}
      </h6>

      <div className="border border-gray-200 bg-gray-50 p-6">
        {values.passengers.map((passenger, idx) => (
          <PassengerBookingInfo
            key={idx}
            number={idx + 1}
            value={passenger}
            field={fields.passengers[idx]}
            errors={showErrors ? errors.passenger[idx] ?? {} : {}}
            requirePassNo={REQUIRE_PASS_NO}
          />
        ))}
      </div>

      <ContactBookingInfo
        value={values.contact}
        field={fields.contact}
        errors={showErrors ? errors.contact : {}}
      />

      <div className="mt-4 flex">
        <FormField
          className="w-full"
          label={T.booking.special_request}
          hideErrors
        >
          <TextInput value={values.demand} onChange={fields.demand} multiline />
        </FormField>
      </div>

      {paymentTypes.length > 1 && (
        <div className="mt-4 flex">
          <FormField className="w-1/2" label={T.booking.payment_method}>
            <RadioInput
              horizontal
              name="payment-type"
              options={paymentTypes}
              value={values.paymentMethod}
              onChange={fields.paymentMethod}
            />
          </FormField>
        </div>
      )}

      <div className="py-3 flex items-start">
        <input
          type="checkbox"
          className="mr-3 mt-1"
          checked={values.agreeTerms}
          onChange={() => fields.agreeTerms(!values.agreeTerms)}
        />
        <div>{renderTermsOfUse()}</div>
      </div>

      {error && (
        <div className="border border-red-500 my-4 p-4 bg-red-100">
          {error?.response?.data?.message}
        </div>
      )}

      <div className="my-6 flex justify-end">
        <Button
          onClick={handleSubmit}
          type="primary"
          disabled={!values.agreeTerms || loadingPostBooking}
        >
          {loadingPostBooking && <InlineSpinner />}
          <span>{T.results.book_now}</span>
        </Button>
      </div>
    </div>
  );
}
