import { T } from "@config/lang";
import { priceEuro } from "@presentation/shared/utils/price-format";
import * as React from "react";
import { Button } from "@presentation/shared/components/button/Button";
import Rating from "@presentation/shared/components/rating/Rating";
import { useEffect, useState } from "react";
import { HotelDetails } from "@agent/pages/new-booking/HotelDetails";
import { MoreRooms } from "@agent/pages/new-booking/MoreRooms";

export interface HotelResultRoom {
  id: number;
  accommodation_id: number;
  acc_image: string;
  room: string;
  room_type: string;
  adl: number;
  chd: number;
  board: string;
  days: number;
  nights: number;
  stop_sale: number;
  promotions: number;
  price: number;
}

export interface Flight {
  arrive: string;
  code: string;
  depart: string;
  duration: string;
  flight_company: string;
  flight_company_logo: string;
  flight_date: string;
  from_airport: string;
  from_airport_code: string;
  from_country: string;
  infant_limit: number;
  seats_limit: number;
  to_airport: string;
  to_airport_code: string;
  to_country: string;
}

export interface GalleryImage {
  medium: string;
  large: string;
}

export interface HotelResult extends HotelResultRoom {
  name: string;
  category: number;
  location: string;
  description?: string;
  map?: string;
  image: {
    medium: string;
    large: string;
  };
  room_details: HotelResultRoom[];
  checkin: string;
  checkout: string;
  depart_flight?: Flight;
  return_flight?: Flight;
  gallery: GalleryImage[];
}

type Props = {
  results?: HotelResult[];
  bookNow(hotel: HotelResult, room: HotelResultRoom): void;
};

export function SearchResults({ results, bookNow: bookNowParent }: Props) {
  const [details, setDetails] = useState<HotelResult>(null);
  const [moreRooms, setMoreRooms] = useState<HotelResult>(null);

  const bookNow = (hotel: HotelResult, room: HotelResultRoom) => {
    setDetails(null);
    setMoreRooms(null);
    bookNowParent(hotel, room);
  };

  useEffect(() => {
    setDetails(null);
    setMoreRooms(null);
  }, [results]);

  return (
    <>
      {details && (
        <HotelDetails hotel={details} onHide={() => setDetails(null)} />
      )}
      {moreRooms && (
        <MoreRooms
          hotel={moreRooms}
          bookNow={bookNow}
          onHide={() => setMoreRooms(null)}
        />
      )}
      <table className="w-full">
        <thead>
          <tr>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.results.name}
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.results.room_type}
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.results.price}
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.agent.action}
            </th>
          </tr>
        </thead>
        <tbody>
          {results?.map((hotel) => {
            return (
              <tr key={hotel.id} className="even:bg-gray-100">
                <td className="px-2 py-2 text-left max-w-[400px]">
                  <div>
                    <span
                      className="font-bold hover:text-primary-500 cursor-pointer"
                      onClick={() => setDetails(hotel)}
                    >
                      {hotel.name}
                    </span>
                    <span className="ml-5 text-yellow-400 text-sm">
                      <Rating value={hotel.category} />
                    </span>
                  </div>
                  <div className="text-sm">{hotel.location}</div>
                </td>
                <td className="px-2 py-2 text-left text-sm">
                  <div className="whitespace-nowrap truncate max-w-[400px]">
                    {hotel.room}
                  </div>
                  <div className="whitespace-nowrap truncate max-w-[400px]">
                    {hotel.room_type}
                  </div>
                  <div>{hotel.board}</div>
                </td>
                <td className="px-2 py-2 whitespace-nowrap text-left">
                  {hotel.stop_sale === 1 && (
                    <div>
                      <span className="bg-red-800 text-white px-2 py-1 font-bold">
                        Stop sale
                      </span>
                    </div>
                  )}
                  <div className="text-base whitespace-nowrap">
                    {priceEuro(hotel.price)}
                  </div>
                  {hotel.promotions > 0 && (
                    <del className="mr-2 whitespace-nowrap text-gray-400">
                      {priceEuro(hotel.price + hotel.promotions)}
                    </del>
                  )}
                </td>
                <td className="px-2 py-2 whitespace-nowrap text-left">
                  <div className="grid grid-cols-2 gap-4">
                    <Button
                      type="primary"
                      onClick={() => bookNow(hotel, hotel.room_details[0])}
                      size="sm"
                    >
                      {T.results.book_now}
                    </Button>
                    <Button
                      type="secondary"
                      onClick={() => setMoreRooms(hotel)}
                      size="xs"
                    >
                      {T.results.more_rooms}
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
