import { cx } from "@presentation/vendor/classnames";
import * as React from "react";
import { Argument } from "classnames";

type Props<T> = {
  className?: Argument;
  value: T;
  onChange(value: T): void;
  options: {
    value: T;
    name: string;
  }[];
};

export function ButtonGroup<T>({
  className,
  options,
  value,
  onChange,
}: Props<T>) {
  return (
    <div className={cx("inline-flex flex-nowrap", className)}>
      {options.map((option) => {
        const isActive = option.value === value;
        return (
          <button
            key={option.value.toString()}
            className={cx(
              "px-4 py-2 first:rounded-l-lg last:rounded-r-lg border-y first:border-l last:border-r hover:bg-blue-50 select-none",
              { "bg-blue-100": isActive }
            )}
            onClick={() => onChange(option.value)}
          >
            {option.name}
          </button>
        );
      })}
    </div>
  );
}
