import * as React from 'react';
import Rating from './Rating';
import CustomCheckbox from './CustomCheckbox';

const Stars2 = ({ options, toggle }) => {
  return (
    <div className="pt-4">
      <h6 className="text-lg border-b border-gray-300">Category</h6>
      <div className="collection-brand-filter">
        {options.map((star) => (
          <CustomCheckbox
            key={star.value}
            option={star}
            toggle={toggle}
            renderName={() => (
              <span className="text-yellow-400">
                <Rating value={star.value} />
              </span>
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default Stars2;
