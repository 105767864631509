import * as React from "react";
import HeaderTop from "./HeaderTop";
import HeaderMenu from "./HeaderMenu";
import { useTheme } from "../../theme/useTheme";
import SearchBoxHome from "../components/search-box/SearchBoxHome";
import { cx } from "@presentation/vendor/classnames";
import { useCompany } from "@application/context/company-context";
import { useSearchRepository } from "@presentation/shared/context/search-repository-context";

function Header({ home }) {
  const { services } = useCompany();
  const { current } = useSearchRepository();
  const service =
    services?.find((it) => it.service_name === current?.searchType) ??
    services?.[0];

  const { image, title, subtitle } = service ?? {};
  const { header } = useTheme();

  const styles = home ? header.home : header.page;

  if (header.hide) {
    return null;
  }

  return (
    <header
      className={cx("flex flex-col bg-cover bg-center", styles.background)}
      style={
        styles.showBackground ? { backgroundImage: `url("${image}")` } : {}
      }
    >
      <HeaderTop />
      <HeaderMenu />
      <div className={cx(styles.container)}>
        {styles.message && (
          <div className={cx(styles.message)}>
            <div className={cx(styles.title)}>{title}</div>
            <div className={cx(styles.subtitle)}>{subtitle}</div>
          </div>
        )}
        {home && <SearchBoxHome />}
      </div>
    </header>
  );
}

export default Header;
