import * as React from "react";
import { ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { dateNames, shortDayNames } from "./date-names";
import { BiCalendar } from "react-icons/bi";
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
} from "react-icons/hi";
import { padzero } from "./date-picker";
import { cx } from "@presentation/vendor/classnames";
import { useOnClickOutside } from "@presentation/shared/hooks/useOnClickOutside";

export enum DateFormats {
  STRING = "string",
  ARRAY = "array",
  DATE = "date",
  OBJECT = "object",
}

const SelectionMode = {
  YEAR: 1,
  MONTH: 2,
  DAY: 3,
};

type DatePickerComponentProps = {
  value: { year: number; month: number; day: number };
  onChange: (value: { year: number; month: number; day: number }) => void;
  minDate?: { year: number; month: number; day: number };
  maxDate?: { year: number; month: number; day: number };
  disabled?: boolean;
  placeholder?: string | ReactElement;
  enabledDates: Array<{ year: number; month: number; day: number }>;
  rightAligned?: boolean;
};

function DatePickerComponent({
  value,
  onChange,
  minDate,
  maxDate,
  disabled = false,
  placeholder = <span>&nbsp;</span>,
  enabledDates,
  rightAligned = false,
}: DatePickerComponentProps) {
  const today = useMemo(() => parseDate(new Date(), DateFormats.DATE), []);

  const [mode, setMode] = useState(SelectionMode.DAY);
  const [current, setCurrent] = useState(today);

  const startYear = useMemo(
    () => 10 * Math.trunc(current.year / 10),
    [current]
  );

  /**
   * Popup control
   */
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));
  const onToggleOpen = useCallback(() => {
    if (disabled) return;
    if (open) {
      setOpen(false);
    } else {
      setMode(SelectionMode.DAY);
      setCurrent(value ?? minDate ?? today);
      setOpen(true);
    }
  }, [open, value, minDate, today, disabled]);

  /**
   * Navigation by clicking on the arrows
   */
  const [onFastPrev, onPrev, onNext, onFastNext] = useMemo(() => {
    const onFast = (inc = 1) => {
      const yearInc = inc * (mode === SelectionMode.YEAR ? 10 : 1);
      setCurrent((c) => ({ ...c, year: c.year + yearInc }));
    };
    const onSlow = (inc = 1) => {
      setCurrent((c) => {
        let year = c.year;
        let month = c.month + inc;
        if (month < 0) {
          month = 11;
          year--;
        } else if (month > 11) {
          month = 0;
          year++;
        }
        return { ...c, year, month };
      });
    };
    return [
      () => onFast(-1),
      () => onSlow(-1),
      () => onSlow(1),
      () => onFast(1),
    ];
  }, [mode]);

  /**
   * The list of days, months or years to display
   */
  const displayList = useMemo(() => {
    if (mode === SelectionMode.YEAR) {
      return Array.from(new Array(10).keys()).map((y) => {
        const year = startYear + y;
        const disable =
          (minDate && year < minDate.year) || (maxDate && year > maxDate.year);
        return {
          isActive: value?.year === year,
          name: year,
          value: year,
          disable,
        };
      });
    } else if (mode === SelectionMode.MONTH) {
      return dateNames.map((name, m) => ({
        isActive: value?.year === current.year && value?.month === m,
        name: name.substr(0, 3),
        value: m,
        disable:
          (minDate && current.year < minDate.year) ||
          (minDate?.year === current.year && m < minDate.month) ||
          (maxDate && current.year > maxDate.year) ||
          (maxDate?.year === current.year && m > maxDate.month),
      }));
    } else {
      const firstDate = new Date(current.year, current.month, 1);
      const lastDate = new Date(current.year, current.month + 1, 1);
      const startDate = new Date(firstDate);
      const endDate = new Date(lastDate);
      startDate.setDate(startDate.getDate() - firstDate.getDay());
      endDate.setDate(endDate.getDate() + 7 - lastDate.getDay());

      const result = [];
      for (
        let curr = new Date(startDate);
        curr.getTime() < endDate.getTime();
        curr.setDate(curr.getDate() + 1)
      ) {
        const val = {
          year: curr.getFullYear(),
          month: curr.getMonth(),
          day: curr.getDate(),
        };
        const isActive =
          value?.year === val.year &&
          value?.month === val.month &&
          value?.day === val.day;
        let disable =
          enabledDates &&
          !enabledDates.some(
            (it) =>
              it.year === val.year &&
              it.month === val.month &&
              it.day === val.day
          );
        if (!disable && minDate) {
          disable =
            disable ||
            val.year < minDate.year ||
            (val.year === minDate.year &&
              (val.month < minDate.month ||
                (val.month === minDate.month && val.day < minDate.day)));
        }
        if (!disable && maxDate) {
          disable =
            disable ||
            val.year > maxDate.year ||
            (val.year === maxDate.year &&
              (val.month > maxDate.month ||
                (val.month === maxDate.month && val.day > maxDate.day)));
        }
        result.push({
          isActive,
          name: curr.getDate(),
          fade: current.month !== curr.getMonth(),
          value: val,
          disable,
        });
      }
      return result;
    }
  }, [mode, current, value, minDate, maxDate, startYear, enabledDates]);

  /**
   * When the user selects an item on the list
   */
  const onClickList = useCallback(
    (val) => {
      if (mode === SelectionMode.YEAR) {
        setCurrent((c) => ({ ...c, year: val }));
        setMode(SelectionMode.MONTH);
      } else if (mode === SelectionMode.MONTH) {
        setCurrent((c) => ({ ...c, month: val }));
        setMode(SelectionMode.DAY);
      } else {
        setOpen(false);
        onChange(val);
      }
    },
    [mode, onChange]
  );

  const arrowClasses = "cursor-pointer text-gray-600 hover:text-blue-400 px-1";
  const yearClasses = "cursor-pointer no-select hover:text-blue-600 px-2";

  return (
    <div className="flex relative" ref={ref}>
      <div
        className={cx(
          "w-full py-2 pl-7 pr-3 text-center text-sm bg-white border border-gray-300 rounded",
          disabled ? "bg-gray-200" : "cursor-pointer"
        )}
        onClick={onToggleOpen}
      >
        <div className="absolute text-gray-400 left-2">
          <BiCalendar size={18} />
        </div>
        {value
          ? `${padzero(value.day)}.${padzero(value.month + 1)}.${value.year}`
          : placeholder}
      </div>

      {open && (
        <div
          className={cx(
            "absolute mt-11 w-72 bg-white border-gray-500 shadow-lg rounded-lg p-3 z-50",
            rightAligned ? "right-0" : "left-0"
          )}
        >
          <div
            className={cx("flex flex-row items-center justify-between py-3")}
          >
            <div className="flex flex-row">
              <span className={arrowClasses} onClick={onFastPrev}>
                <HiChevronDoubleLeft />
              </span>
              {mode === SelectionMode.DAY && (
                <span className={arrowClasses} onClick={onPrev}>
                  <HiChevronLeft />
                </span>
              )}
            </div>
            {mode === SelectionMode.YEAR && (
              <div>{`${startYear} - ${startYear + 9}`}</div>
            )}
            {mode === SelectionMode.MONTH && (
              <div
                className={yearClasses}
                onClick={() => setMode(SelectionMode.YEAR)}
              >
                {current.year}
              </div>
            )}
            {mode === SelectionMode.DAY && (
              <div className="flex flex-row">
                <span
                  className={yearClasses}
                  onClick={() => setMode(SelectionMode.MONTH)}
                >
                  {dateNames[current.month]}
                </span>
                <span
                  className={yearClasses}
                  onClick={() => setMode(SelectionMode.YEAR)}
                >
                  {current.year}
                </span>
              </div>
            )}
            <div className="flex flex-row">
              {mode === SelectionMode.DAY && (
                <span className={arrowClasses} onClick={onNext}>
                  <HiChevronRight />
                </span>
              )}
              <span className={arrowClasses} onClick={onFastNext}>
                <HiChevronDoubleRight />
              </span>
            </div>
          </div>
          <div>
            {mode === SelectionMode.DAY && (
              <div className="grid grid-cols-7 gap-2 border-b border-gray-200 text-sm text-gray-400 text-center">
                {Array.from(new Array(7).keys()).map((d) => (
                  <div key={d}>{shortDayNames[d].substring(0, 3)}</div>
                ))}
              </div>
            )}
            <div
              className={cx(
                "grid",
                mode === SelectionMode.DAY
                  ? "grid-cols-7 gap-2"
                  : "grid-cols-4 gap-3"
              )}
            >
              {displayList.map(
                ({ isActive, name, value, fade, disable }, idx) => (
                  <div
                    key={idx}
                    className={cx(
                      "cursor-pointer text-sm flex justify-center",
                      fade || disable ? "text-gray-300" : "text-gray-800",
                      { "bg-gray-100": disable },
                      mode === SelectionMode.DAY ? "py-1" : "py-3"
                    )}
                    onClick={() => !disable && onClickList(value)}
                  >
                    <div
                      className={cx(
                        "p-1 text-center rounded-full",
                        mode === SelectionMode.DAY ? "p-1" : "p-3",
                        isActive
                          ? "font-bold bg-blue-500 text-white"
                          : disabled
                          ? "text-gray-400"
                          : "hover:text-blue-500"
                      )}
                      style={
                        mode === SelectionMode.DAY ? { minWidth: "1.8rem" } : {}
                      }
                    >
                      {name}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function parseDate(value, dateFormat) {
  if (!value) return null;
  if (dateFormat === DateFormats.ARRAY) {
    return { year: value[0], month: value[1], day: value[2] };
  } else if (dateFormat === DateFormats.STRING) {
    const arr = value
      .substring(0, 11)
      .split("-")
      .map((it) => Number.parseInt(it, 10));
    return { year: arr[0], month: arr[1] - 1, day: arr[2] };
  } else if (dateFormat === DateFormats.DATE) {
    return {
      year: value.getFullYear(),
      month: value.getMonth(),
      day: value.getDate(),
    };
  }
  return value;
}

type DateValue =
  | string
  | Array<number>
  | Date
  | { year: number; month: number; day: number };

type DatePickerProps = {
  value: DateValue;
  onChange: (value: DateValue) => void;
  dateFormat:
    | DateFormats.STRING
    | DateFormats.ARRAY
    | DateFormats.DATE
    | DateFormats.OBJECT;
  minDate:
    | string
    | Array<number>
    | Date
    | { year: number; month: number; day: number };
  maxDate:
    | string
    | Array<number>
    | Date
    | { year: number; month: number; day: number };
  enabledDates?:
    | Array<string>
    | Array<Array<number>>
    | Array<Date>
    | Array<{ year: number; month: number; day: number }>;
  rightAligned?: boolean;
  // [string]: any,
};

function DatePicker({
  value,
  onChange,
  dateFormat = DateFormats.OBJECT,
  minDate,
  maxDate,
  enabledDates,
  ...rest
}: DatePickerProps) {
  const formatted = useMemo(() => {
    const r = {
      enabledDates: enabledDates?.map((it) => parseDate(it, dateFormat)),
      value: parseDate(value, dateFormat),
      minDate: parseDate(minDate, dateFormat),
      maxDate: parseDate(maxDate, dateFormat),
    };

    if (r.enabledDates?.length > 0) {
      if (!r.minDate) {
        r.minDate = r.enabledDates[0];
      }
      if (!r.maxDate) {
        r.maxDate = r.enabledDates[r.enabledDates.length - 1];
      }
    }
    return r;
  }, [dateFormat, value, minDate, maxDate, enabledDates]);

  const onChangeInternal = useCallback(
    (value) => {
      if (!value) return null;
      const { year, month, day } = value;
      if (dateFormat === DateFormats.ARRAY) {
        onChange([year, month, day]);
      } else if (dateFormat === DateFormats.STRING) {
        onChange(`${year}-${padzero(month + 1)}-${padzero(day)}`);
      } else if (dateFormat === DateFormats.DATE) {
        onChange(new Date(year, month, day));
      } else {
        onChange(value);
      }
    },
    [onChange, dateFormat]
  );

  return (
    <DatePickerComponent {...formatted} onChange={onChangeInternal} {...rest} />
  );
}

export default DatePicker;
