import { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaBed,
  FaBus,
  FaPlane,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaUtensils,
} from "react-icons/fa";
import { AiOutlineZoomIn } from "react-icons/ai";
import HotelGallery from "./HotelGallery";
import MoreRooms from "./MoreRooms";
import HotelMap from "./HotelMap";
import {
  MdOutlineBedroomParent,
  MdOutlineLocationOn,
  MdReadMore,
} from "react-icons/md";
import HotelDetails from "./HotelDetails";
import { priceEuro } from "../../shared/utils/price-format";
import Rating from "../../shared/components/rating/Rating";
import { useSearchRepository } from "../../shared/context/search-repository-context";
import { Button } from "../../shared/components/button/Button";
import { cx } from "../../vendor/classnames";
import { useTheme } from "../../theme/useTheme";
import { T } from "@config/lang";
import { PAGES, useIFrame } from "@presentation/shared/context/iframe-context";
import { HotelResult } from "@agent/pages/new-booking/SearchResults";
import { SEARCH_TYPES } from "@config/const/enums";

type Props = {
  result: HotelResult;
  allowBooking: boolean;
};

function Result({ result, allowBooking }: Props) {
  const { iframe, setPage } = useIFrame();
  const {
    current: { searchType, detailsUrl, bookingUrl },
  } = useSearchRepository();
  const { results } = useTheme();

  const isCharter = searchType === SEARCH_TYPES.charter;
  const [showMoreRooms, setShowMoreRooms] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showGallery, setShowGallery] = useState(false);

  const goToDetails = (id) => {
    setPage(PAGES.DETAILS, id);
  };

  const goToBooking = (id, accommodationId) => {
    setPage(PAGES.BOOKING, id, accommodationId);
  };

  return (
    <>
      {showGallery && (
        <HotelGallery
          mainImage={result.image}
          gallery={result.gallery}
          onHide={() => setShowGallery(false)}
        />
      )}
      {showMoreRooms && (
        <MoreRooms
          charter={result}
          rooms={result.room_details}
          onHide={() => setShowMoreRooms(false)}
          allowBooking={allowBooking}
        />
      )}
      {showMap && (
        <HotelMap charter={result} onHide={() => setShowMap(false)} />
      )}
      {showDetails && (
        <HotelDetails charter={result} onHide={() => setShowDetails(false)} />
      )}

      <div className={cx(results.single, "md:flex my-10")}>
        <div
          className="min-h-[240px] w-full md:w-1/2 lg:w-1/3 xl:w-1/4 relative bg-cover bg-center"
          style={{
            backgroundImage: `url(${result.image.medium})`,
          }}
        >
          {result.gallery?.length > 1 && (
            <div
              className="absolute left-1/2 top-1/2 hover:opacity-100 opacity-50 w-12 h-12 -m-4 text-white text-3xl cursor-pointer"
              onClick={() => setShowGallery(true)}
            >
              <AiOutlineZoomIn />
            </div>
          )}
        </div>
        <div
          className={cx(
            results.data,
            "description md:flex flex-col md:w-3/5 lg:w-3/4 ml-3"
          )}
        >
          <div className="md:flex">
            <div className="md:flex-grow">
              <p className="font-light text-sm text-gray-700">
                {result.location}
              </p>
              {allowBooking ? (
                <>
                  {iframe ? (
                    <span
                      className="font-bold text-xl text-gray-700 hover:text-primary-500 cursor-pointer"
                      onClick={() => goToDetails(result.id)}
                    >
                      {result.name}
                    </span>
                  ) : (
                    <Link
                      to={detailsUrl(result.id)}
                      className="font-bold text-xl text-gray-700 hover:text-primary-500"
                    >
                      {result.name}
                    </Link>
                  )}
                </>
              ) : (
                <span className="font-bold text-xl text-gray-700">
                  {result.name}
                </span>
              )}
              <div className="text-xs text-green-500">
                <Rating value={result.category} />
              </div>
            </div>
            <div>
              {isCharter ? (
                <div className="font-light text-gray-700">
                  <span className="flex items-center">
                    <FaPlaneDeparture className="mr-2" />{" "}
                    {result.depart_flight.flight_date}
                  </span>
                  <span className="flex items-center">
                    <FaPlaneArrival className="mr-2" />{" "}
                    {result.return_flight.flight_date}
                  </span>
                </div>
              ) : (
                <div className="font-light text-gray-700">
                  <span className="flex items-center">{result.checkin}</span>
                  <span className="flex items-center">{result.checkout}</span>
                </div>
              )}
            </div>
          </div>
          <div className="w-12 my-2 border-gray-400 border-t" />
          <div className="md:flex justify-between">
            <div className="text-sm font-light text-gray-700">
              <span className="flex items-center">
                <FaBed className="mr-2" /> {result.room} - {result.room_type}
              </span>
              <span className="flex items-center">
                <FaUtensils className="mr-2" /> {result.board}
              </span>
              {isCharter && (
                <>
                  <span className="flex items-center">
                    <FaPlane className="mr-2" /> Charter Flight, Baggage 23kg,
                    Airport Taxes.
                  </span>
                  <span className="flex items-center">
                    <FaBus className="mr-2" /> Transfer
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-grow justify-between items-end">
            <div className="flex">
              {result.map && (
                <div
                  className="inline-flex items-center pr-4 py-1 cursor-pointer text-blue-800 hover:text-blue-500"
                  onClick={() => setShowMap(true)}
                >
                  <MdOutlineLocationOn />
                  <span className="ml-1">Location</span>
                </div>
              )}
              {result.description && (
                <div
                  className="inline-flex items-center pr-4 py-1 cursor-pointer text-blue-800 hover:text-blue-500"
                  onClick={() => setShowDetails(true)}
                >
                  <MdReadMore />
                  <span className="ml-1">Details</span>
                </div>
              )}
            </div>
            <div className="flex flex-col items-end">
              {result.stop_sale === 1 && (
                <div className="bg-red-800 text-white px-2 font-bold">
                  Stop sale
                </div>
              )}
              {result.promotions > 0 && (
                <del className="text-base text-gray-500 whitespace-nowrap">
                  {priceEuro(result.price + result.promotions)}
                </del>
              )}
              <div>
                <span className="font-bold text-lg whitespace-nowrap">
                  {priceEuro(result.price)}
                </span>
              </div>

              {allowBooking &&
                (iframe ? (
                  <Button
                    onClick={() =>
                      goToBooking(result.id, result.accommodation_id)
                    }
                    type="primary"
                  >
                    {T.results.book_now}
                  </Button>
                ) : (
                  <Button
                    to={bookingUrl(result.id, result.accommodation_id)}
                    type="primary"
                  >
                    {T.results.book_now}
                  </Button>
                ))}

              {result.room_details?.length > 1 && (
                <div
                  className="inline-flex items-center my-2 cursor-pointer text-primary-800 hover:text-primary-500"
                  onClick={() => setShowMoreRooms(true)}
                >
                  <MdOutlineBedroomParent />
                  <span className="ml-2">{T.results.more_rooms}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Result;
