import { useSearchRepository } from "@presentation/shared/context/search-repository-context";
import { FullPageLoader } from "@presentation/shared/components/loader/FullPageLoader";
import ResultList from "@presentation/pages/search/ResultList";
import { Settings } from "@config/tenants/settings.default";
import * as React from "react";

export function Search() {
  const {
    current: { results, loadingResults },
  } = useSearchRepository();

  if (loadingResults) {
    return <FullPageLoader />;
  }

  return (
    <ResultList results={results} allowBooking={Settings.iframe.allowBooking} />
  );
}
