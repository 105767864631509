import { createContext, useContext, useState } from "react";

export const PAGES = {
  SEARCH: "SEARCH",
  DETAILS: "DETAILS",
  BOOKING: "BOOKING",
  CONFIRMATION: "CONFIRMATION",
};

type ContextType = {
  iframe: boolean;
  page: string;
  hotelId: number;
  accommodationId: string;
  confirmationCode: string;
  setPage(
    page: string,
    id?: number,
    accommodationId?: string,
    confirmationCode?: string
  ): void;
};

const IFrameContext = createContext<ContextType>({
  iframe: false,
  page: PAGES.SEARCH,
  hotelId: null,
  accommodationId: null,
  confirmationCode: null,
  setPage(page: string) {
    console.log(page);
  },
});

export const useIFrame = () => useContext<ContextType>(IFrameContext);

export function IFrameProvider({ iframe = false, children }) {
  const value = useProvideIFrame(iframe);
  return (
    <IFrameContext.Provider value={value}>{children}</IFrameContext.Provider>
  );
}

function useProvideIFrame(iframe): ContextType {
  const [page, setPageInternal] = useState(PAGES.SEARCH);
  const [hotelId, setHotelId] = useState(null);
  const [accommodationId, setAccommodationId] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState(null);

  const setPage = (
    page,
    id = null,
    accommodationId = null,
    confirmationCode = null
  ) => {
    setPageInternal(page);
    setHotelId(id);
    setAccommodationId(accommodationId);
    setConfirmationCode(confirmationCode);
  };

  return {
    iframe,
    page,
    setPage,
    hotelId,
    accommodationId,
    confirmationCode,
  };
}
