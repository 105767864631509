import { useCallback, useEffect, useState } from "react";
import { HttpMethod } from "../const";
import { Axios } from "../http-client";

const EMPTY = {};

export function useService(service, autoload = false, initialArgs = EMPTY) {
  const [loading, setLoading] = useState(autoload);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const requestAsync = useCallback(
    async (data = {}) => {
      const serviceData = service({
        ...initialArgs,
        ...data,
      });
      if (!serviceData) {
        return;
      }
      const { url, method = HttpMethod.GET, payload, params } = serviceData;
      setLoading(true);
      return Axios.request({ method, url, data: payload, params })
        .then((response) => {
          const { data } = response;
          setData(data);
          return data;
        })
        .catch((err) => {
          setError(err);
          throw err;
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    [service]
  );

  const request = useCallback(
    (...args) => {
      requestAsync(...args);
    },
    [requestAsync]
  );

  useEffect(() => {
    if (autoload) {
      request();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    data,
    error,
    request,
    requestAsync,
  };
}
