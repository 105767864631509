import * as React from "react";
import { Agent } from "./Agent";
import NotFoundError from "../presentation/pages/error/NotFoundError";
import { menu } from "@agent/menu";

const agentRoutes = [
  {
    path: "/agent",
    element: <Agent />,
    errorElement: <NotFoundError />,
    children: menu()
      .reduce((prev, curr) => [...prev, ...curr.items], [])
      .filter((it) => it.Component)
      .map(({ to, Component }) => ({
        path: "/agent" + to,
        element: <Component />,
        strict: true,
      })),
  },
];

export default agentRoutes;
