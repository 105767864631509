import * as React from 'react';
import { useState } from 'react';
import {
  FaBath,
  FaMountain,
  FaParking,
  FaTv,
  FaUtensils,
  FaWalking,
  FaWifi,
} from 'react-icons/fa';
import CustomCheckbox from './CustomCheckbox';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Facilities = ({ options, toggle }) => {
  const [collapsed, setCollapsed] = useState([]);

  const facilitiesTypeIcon = (id, props = {}) => {
    switch (id) {
    case 1:
      return <FaBath {...props} />;
    case 2:
      return <FaWalking {...props} />;
    case 3:
      return <FaParking {...props} />;
    case 7:
      return <FaMountain {...props} />;
    case 8:
      return <FaTv {...props} />;
    case 9:
      return <FaUtensils {...props} />;
    case 10:
      return <FaWifi {...props} />;
    default:
      return <FaWalking {...props} />;
    }
  };

  const isOpen = (cat) => !collapsed.includes(cat.id);
  const toggleCollapse = (cat) => {
    return setCollapsed(
      isOpen(cat)
        ? [...collapsed, cat.id]
        : [...collapsed.filter((it) => it !== cat.id)]
    );
  };

  return (
    <div className="pt-4">
      <h6 className="text-lg border-b border-gray-300">Facilities</h6>
      {options
        .filter((it) => it.facilities?.length > 0)
        .map((facilityCategory) => (
          <div key={facilityCategory.id} className="mb-3">
            <div
              className="flex justify-between items-center"
              onClick={() => toggleCollapse(facilityCategory)}
            >
              <div className="flex items-center">
                <span className="mr-2">
                  {facilitiesTypeIcon(facilityCategory.id)}
                </span>
                <span className="text-sm">{facilityCategory.name}</span>
              </div>
              <span className="text-gray-600 bg-gray-200 rounded-full text-sm">
                {isOpen(facilityCategory) ? <FiChevronDown /> : <FiChevronUp />}
              </span>
            </div>
            {isOpen(facilityCategory) && (
              <div>
                <div className="collection-brand-filter">
                  {facilityCategory.facilities.map((facility) => (
                    <CustomCheckbox
                      key={facility.id}
                      option={facility}
                      toggle={toggle}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Facilities;
