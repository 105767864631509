export const filterByName = (hotels, text) => {
  if (!text || text.length === 0) {
    return hotels;
  }
  return hotels.filter(
    (hotel) => hotel.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
  );
};

export const filterByPriceRange = (hotels, priceRange) => {
  if (!priceRange) {
    return hotels;
  }
  const [minPrice, maxPrice] = priceRange;
  return hotels.filter(
    (hotel) => hotel.price >= minPrice && hotel.price <= maxPrice
  );
};

export const filterByStarRating = (hotels, selectedStarRatings) => {
  if (selectedStarRatings.length === 0) {
    return hotels;
  }

  return hotels.filter((hotel) =>
    selectedStarRatings.some((value) => hotel.category === value)
  );
};

export const filterByHighlights = (hotels, selectedHighlights) => {
  if (selectedHighlights.length === 0) {
    return hotels;
  }

  return hotels.filter((hotel) =>
    selectedHighlights.every((id) => hotel.highlights.includes(id))
  );
};

export const filterByFacilities = (hotels, selectedFacilities) => {
  if (selectedFacilities.length === 0) {
    return hotels;
  }

  return hotels.filter((hotel) =>
    selectedFacilities.every((id) => hotel.facilities.includes(id))
  );
};
