import Rating from "@presentation/shared/components/rating/Rating";
import { priceEuro } from "@presentation/shared/utils/price-format";
import { useSearchRepository } from "@presentation/shared/context/search-repository-context";
import { SEARCH_TYPES } from "@config/const/enums";
import { T } from "@config/lang";
import { Settings } from "@config/tenants/settings.default";

export function BookingSummary({ hotel, room }) {
  const { current } = useSearchRepository();
  const { searchType } = current;
  const isCharter = searchType === SEARCH_TYPES.charter;
  const extraDays = isCharter ? Settings.search.charter.extraDays : 0;

  return (
    <div className="flex">
      <div className="w-60 mr-4">
        <img src={hotel?.image?.medium} className="w-full" alt="" />
      </div>
      <div>
        <p className="font-light text-sm text-gray-700">{hotel.location}</p>
        <h6 className="font-bold text-xl text-gray-700">{hotel.name}</h6>
        <div className="text-xs text-green-500">
          <Rating value={hotel.category} />
        </div>
        <div className="">
          <div>
            {T.results.room_type}: {room.room} {room.room_type}
          </div>
          <div>
            {T.results.service}: {room.board}
          </div>
          <div>
            {T.search.guests}: {room.adl} {T.search.adults}, {room.chd}{" "}
            {room.chd === 1 ? T.search.child : T.search.children}
          </div>
          {isCharter ? (
            <>
              <div>
                {hotel.real_days + extraDays}{" "}
                {hotel.real_days + extraDays === 1
                  ? T.search.day
                  : T.search.days}
                , {hotel.nights}{" "}
                {hotel.nights === 1 ? T.search.night : T.search.nights},{" "}
              </div>
            </>
          ) : (
            <>
              <div>
                {T.results.check_in}: {hotel.checkin}
              </div>
              <div>
                {T.results.check_out}: {hotel.checkout}
              </div>
              <div>
                {room.days} {room.days === 1 ? T.search.night : T.search.nights}
              </div>
            </>
          )}
        </div>
        {room.stop_sale === 1 && (
          <div className="inline-block bg-red-800 text-white px-2 font-bold">
            Stop sale
          </div>
        )}
        <div className="text-lg whitespace-nowrap">
          {T.results.total_price}:{" "}
          <span className={"font-bold"}>{priceEuro(room.price)}</span>
        </div>
      </div>
    </div>
  );
}
