import Modal from "../../shared/components/modal/Modal";
import { useIsMobile } from "@presentation/shared/hooks/useIsMobile";

function HotelMap({ charter, onHide }) {
  const isMobile = useIsMobile();

  if ((charter.map ?? "").trim().length < 20) {
    return null;
  }

  return (
    <Modal
      title="Location"
      onHide={onHide}
      fullScreen={isMobile}
      height={isMobile ? "auto" : "fixed"}
    >
      <iframe
        title="hotel-map"
        src={charter.map}
        allowFullScreen=""
        className="w-full h-full border-0"
      />
    </Modal>
  );
}

export default HotelMap;
