import * as React from "react";
import { useTheme } from "@presentation/theme/useTheme";
import { cx } from "@presentation/vendor/classnames";
import { T } from "@config/lang";

type CCPaymentProps = {
  successUrl: string;
  ccPaymentData: {
    method: "post" | "get";
    action: string;
    fields?: {
      [key: string]: string;
    };
  };
};

export function CCPayment({ successUrl, ccPaymentData }: CCPaymentProps) {
  const { button } = useTheme();
  const { action, method, fields } = ccPaymentData;

  return (
    <div className="mt-3">
      <p className="mt-2">{T.booking.funds_reserved}</p>
      <div className="flex justify-between items-center">
        <p className="payAmount">{T.booking.pay_online}</p>
        <div>
          <form action={action} method={method}>
            {fields && (
              <>
                {Object.entries(fields).map(([name, value]) => (
                  <input type="hidden" name={name} value={value} />
                ))}
                <input type="hidden" name="afterOkUrl" value={successUrl} />
              </>
            )}
            <button
              type="submit"
              className={cx(button.base, button.primary[0], button.size.base)}
            >
              {T.booking.proceed_to_payment}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
