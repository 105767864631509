import TextInput from "@presentation/shared/components/text-input/TextInput";
import { Button } from "@presentation/shared/components/button/Button";
import { useMemo, useState } from "react";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { cx } from "@presentation/vendor/classnames";
import { T } from "@config/lang";

const EMAIL_REGEX = /^[\w-.]+@([A-Z0-9-]+\.)+[A-Z]{2,}$/i;

export function NewsLetter() {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const isValid = useMemo(() => email && EMAIL_REGEX.test(email), [email]);

  const { loading, requestAsync } = useService(Requests.newsletter);

  const onSubmit = () => {
    requestAsync({ email }).then(() => {
      setEmail("");
      setSubscribed(true);
    });
  };

  return (
    <section
      className={cx("py-16 text-center bg-primary-100 bg-opacity-30", {
        "opacity-30": loading,
      })}
    >
      <div className="flex flex-wrap justify-center">
        <div className="flex-basis w-full shrink-0 grow-0 md:w-10/12 md:px-3 lg:w-8/12 xl:w-6/12">
          <div className="mb-4 inline-block rounded-full bg-primary-500 p-4 shadow-lg shadow-primary-300 dark:shadow-primary-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="h-6 w-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
          </div>

          <h2 className="mb-4 text-3xl font-bold">
            {subscribed
              ? T.newsletter.subscribed
              : T.newsletter.subscribe_to_the_newsletter}
          </h2>

          <p className="mb-6 text-neutral-500 dark:text-neutral-200">
            {subscribed
              ? T.newsletter.you_are_now_subscribed
              : T.newsletter.we_will_write_rarely}
          </p>

          {subscribed || (
            <div className="mb-4 flex-row md:mb-0 md:flex">
              <div className="relative mb-3 w-full md:mr-3 md:mb-0">
                <TextInput
                  value={email}
                  onChange={setEmail}
                  placeholder={T.newsletter.enter_your_email}
                  inputClassName="peer block min-h-[auto] w-full rounded border-0 py-[0.32rem] px-3 leading-[2.15]"
                />
              </div>
              <Button
                type="primary"
                disabled={loading || !isValid}
                onClick={onSubmit}
                className="whitespace-nowrap"
              >
                {T.newsletter.subscribe}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
