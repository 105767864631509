import * as React from "react";
import { AiOutlineKey, AiOutlineMail } from "react-icons/ai";
import { Button } from "@presentation/shared/components/button/Button";
import { useState } from "react";
import { useUser } from "@agent/context/user-context";
import { T } from "@config/lang";
import { useCompany } from "@application/context/company-context";
import { Settings } from "@config/tenants/settings";

export function LoginForm(): React.ReactElement {
  const { login } = useUser();
  const { logo, name } = useCompany();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const doLogin = () => {
    login({ username, password });
  };

  const isValid = username && password;

  return (
    <div
      className="flex flex-col w-screen h-screen justify-center items-center bg-cover bg-center"
      style={{
        backgroundImage: Settings.b2b.background
          ? `url(${Settings.b2b.background})`
          : null,
      }}
    >
      <div className="mx-auto w-[400px] border rounded-xl px-10 py-4 mb-20 bg-white bg-opacity-90">
        <img src={logo} className="max-h-[60px] mb-4 mx-auto" alt={name} />
        <h1 className="font-bold text-center text-2xl text-gray-400 mb-4">
          {T.b2b_login}
        </h1>
        <label
          htmlFor="username"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {T.username}
        </label>
        <div className="relative mb-6">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <AiOutlineMail />
          </div>
          <input
            type="text"
            id="username"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="name@email.com"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

        <label
          htmlFor="password"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {T.password}
        </label>
        <div className="relative mb-6">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <AiOutlineKey />
          </div>
          <input
            type="password"
            id="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="********"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="relative mb-6">
          <Button type="primary" onClick={doLogin} disabled={!isValid}>
            {T.btn_login}
          </Button>
        </div>
      </div>
    </div>
  );
}
