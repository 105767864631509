import React from "react";
import App from "./App";
import NotFoundError from "./pages/error/NotFoundError";
import SearchPage from "./pages/search/SearchPage";
import StaticPage from "./pages/static/StaticPage";
import BookingPage from "./pages/booking/BookingPage";
import DetailsPage from "./pages/details/DetailsPage";
import HomePage from "./pages/home/HomePage";
import { ConfirmationPage } from "./pages/confirmation/ConfirmationPage";
import { FrameApp } from "./FrameApp";
import { GiveAway } from "@presentation/pages/giveaway/GiveAway";

const appRoutes = [
  {
    path: "iframe",
    element: <FrameApp />,
  },
  {
    path: "giveaway",
    element: <GiveAway />,
  },
  {
    path: "/",
    element: <App />,
    errorElement: <NotFoundError />,
    children: [
      {
        path: "search/:data",
        element: <SearchPage />,
      },
      {
        path: "details/:data",
        element: <DetailsPage />,
      },
      {
        path: "booking/:data",
        element: <BookingPage />,
      },
      {
        path: "confirmation/:code",
        element: <ConfirmationPage />,
      },
      {
        path: ":page",
        element: <StaticPage />,
      },
      {
        path: "",
        element: <HomePage />,
      },
    ],
  },
];

export default appRoutes;
