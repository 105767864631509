import Modal from "@presentation/shared/components/modal/Modal";
import * as React from "react";

export function HotelDetails({ hotel, onHide }) {
  const mainImage = hotel.image?.large;

  const gallery: string[] =
    hotel.gallery?.slice(1, 5).map((it) => it.medium) ?? [];
  let cols = mainImage ? 2 : 0;
  if (gallery.length >= 1) {
    cols += 1;
  }
  if (gallery.length >= 3) {
    cols += 1;
  }

  return (
    <Modal title={hotel.name} onHide={onHide}>
      <div>
        {mainImage && (
          <div
            className="w-full grid grid-cols-1 md:grid-rows-2 md:grid-cols-4 md:gap-4"
            style={{
              gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
            }}
          >
            <div
              className="bg-cover bg-center bg-no-repeat md:col-span-2 h-96 md:row-span-2"
              style={{ backgroundImage: `url(${mainImage})` }}
            />
            {gallery.map((image) => (
              <div
                key={image}
                className="bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}
