const MapSection = ({ hotel }) => {
  if ((hotel.map ?? '').trim().length < 20) {
    return null;
  }

  return (
    <div className="mt-10">
      <h6 className="text-lg font-bold">Location</h6>
      <iframe
        title="hotel-map"
        src={hotel.map}
        style={{ border: '0' }}
        allowFullScreen=""
      />
    </div>
  );
};

export default MapSection;
