import * as React from 'react';

export function SearchTerm({ value, setValue }) {
  return (
    <div className="search-bar my-3">
      <div className="relative z-0 w-full group">
        <input
          type="text"
          name="search_hotel"
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <label
          htmlFor="search_hotel"
          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Search by name...
        </label>
      </div>
    </div>
  );
}
