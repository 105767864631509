import * as React from "react";
import { createContext, useContext, useEffect } from "react";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { FullPageLoader } from "@presentation/shared/components/loader/FullPageLoader";
import { useApp } from "@application/context/app-context";

export type CompanyContextType = {
  loading: boolean;
  logo: string;
  secondary_logo: string;
  name: string;
  slogan: string;
  location: string;
  address: string;
  map: string;
  zipCode: string;
  phone: string;
  email: string;
  web: string;
  currency: string;
  currencyValue: number;
  languages: { locale: string; name: string }[];
  default_language: string;
  socials: {
    id: number;
    setting_id: number;
    icon: string;
    name: string;
    link: string;
  }[];
  services: {
    id: number;
    setting_id: number;
    service_name: string;
    image: string;
    title: string;
    subtitle: string;
  }[];
  facilities: {
    id: number;
    icon: string;
    name: string;
    facilities: {
      id: number;
      name: string;
    }[];
  }[];
  highlights: {
    id: number;
    name: string;
  }[];
};

const CompanyContext = createContext<CompanyContextType>(null);

export const useCompany = () => useContext(CompanyContext);

export function CompanyProvider({ children }) {
  const value = useProvideCompany();
  if (value.loading) {
    return <FullPageLoader />;
  }
  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  );
}

function useProvideCompany() {
  const { language } = useApp();
  const {
    data: settings,
    loading: loadingSettings,
    request: loadSettings,
  } = useService(Requests.settings, true);

  const {
    data: config,
    loading: loadingConfig,
    request: loadConfig,
  } = useService(Requests.config, false);

  useEffect(() => {
    if (language) {
      loadConfig();
      loadSettings();
    }
  }, [language, loadConfig, loadSettings]);

  // useEffect(() => {
  //   if (settings) {
  //     document.title = settings.name;
  //   }
  // }, [settings]);

  return {
    loading: loadingSettings || loadingConfig,
    ...settings,
    ...config,
  };
}
