import Modal from "@presentation/shared/components/modal/Modal";
import { T } from "@config/lang";
import {
  HotelResult,
  HotelResultRoom,
} from "@agent/pages/new-booking/SearchResults";
import PassengerBookingInfo from "./PassengerBookingInfo";
import ContactBookingInfo from "./ContactBookingInfo";
import TextInput from "@presentation/shared/components/text-input/TextInput";
import RadioInput from "@presentation/shared/components/radio/RadioInput";
import { useMemo, useState } from "react";
import { Settings } from "@config/tenants/settings.default";
import { useBookingForm } from "@presentation/pages/booking/useBookingForm";
import { UseSearchReturnType } from "@agent/hooks/useSearch";
import { Button } from "@presentation/shared/components/button/Button";
import Rating from "@presentation/shared/components/rating/Rating";
import { priceEuro } from "@presentation/shared/utils/price-format";
import * as React from "react";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { FullPageLoader } from "@presentation/shared/components/loader/FullPageLoader";

const REQUIRE_PASS_NO = Settings.booking.passengers.require_passport_no;

type Props = {
  hotel: HotelResult;
  room: HotelResultRoom;
  search: UseSearchReturnType;
  onHide(): void;
};

export function BookNow({ hotel, room, search, onHide }: Props) {
  const [showErrors, setShowErrors] = useState(false);
  const { values, fields, errors, isValid } = useBookingForm({
    ...search,
    departureDate: search.departDate,
  });

  const paymentTypes = useMemo(
    () =>
      Settings.booking.payments
        .filter((it) => it.public)
        .map((it) => ({ ...it, value: it.type, name: T[it.label] })),
    []
  );

  const { loading: loadingPostBooking, requestAsync: postBooking } = useService(
    Requests.postBooking
  );

  const onBookNow = () => {
    if (!isValid) {
      setShowErrors(true);
    } else {
      const payload = {
        searchType: search.searchType,
        airport: search.departureId,
        destType: search.returnType,
        destId: search.returnId,
        departureDate: search.departDate,
        returnDate: search.returnDate,
        adults: search.adults,
        children: search.children,
        hotelId: hotel.id,
        accommodationId: room.accommodation_id,
        ...values,
        extendedStay: false,
        stop_sale: room.stop_sale,
        agreeTerms: true,
      };

      postBooking({ payload }).then((data) => {
        if (data.success) {
          onHide();
        }
      });
    }
  };

  return (
    <Modal
      title={T.booking.new_booking}
      onHide={onHide}
      width="auto"
      height="auto"
      footer={
        <>
          <Button type="outline" onClick={onHide}>
            {T.cancel}
          </Button>
          <Button type="primary" onClick={onBookNow}>
            {T.results.book_now}
          </Button>
        </>
      }
    >
      {loadingPostBooking && <FullPageLoader />}
      <div className="px-6">
        <h6 className="py-2 font-bold">Details</h6>
        <table className="w-full">
          <tbody>
            <tr key={hotel.id} className="even:bg-gray-100">
              <td className="px-2 py-1 whitespace-nowrap text-left">
                <div>
                  <span className="font-bold">{hotel.name}</span>
                  <span className="ml-5 text-yellow-400 text-sm">
                    <Rating value={hotel.category} />
                  </span>
                </div>
                <div className="text-sm">{hotel.location}</div>
              </td>
              <td className="px-2 py-1 text-left text-sm">
                <div className="whitespace-nowrap truncate max-w-[400px]">
                  {room.room}
                </div>
                <div>{room.board}</div>
              </td>
              <td className="text-sm p-3">
                <div>Check in: {hotel.checkin}</div>
                <div>Check out: {hotel.checkout}</div>
              </td>
              <td className="px-2 py-1 whitespace-nowrap text-left">
                <div className="text-lg whitespace-nowrap">
                  {priceEuro(room.price)}
                </div>
                {room.promotions > 0 && (
                  <del className="mr-2 whitespace-nowrap text-gray-400">
                    {priceEuro(room.price + room.promotions)}
                  </del>
                )}
                {room.stop_sale === 1 && (
                  <span className="bg-red-800 rounded text-white px-2 font-bold">
                    Stop sale
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <h6 className="mt-2 py-2 font-bold">Passenger Information</h6>
        <div>
          <table className="w-full">
            <thead>
              <th className="text-gray-600 text-sm text-left px-2">#</th>
              <th className="text-gray-600 text-sm text-left px-2">Title</th>
              <th className="text-gray-600 text-sm text-left px-2">
                First Name
              </th>
              <th className="text-gray-600 text-sm text-left px-2">
                Last Name
              </th>
              <th className="text-gray-600 text-sm text-left px-2">
                Birth Date
              </th>
              {REQUIRE_PASS_NO && (
                <th className="text-gray-600 text-sm text-left px-2">
                  Passport Number
                </th>
              )}
              {/*{REQUIRE_PASS_NO && <th className="text-gray-600 text-sm text-left px-2">Passport Expires</th>}*/}
            </thead>
            <tbody>
              {values.passengers.map((passenger, idx) => (
                <PassengerBookingInfo
                  key={idx}
                  number={idx + 1}
                  value={passenger}
                  field={fields.passengers[idx]}
                  errors={showErrors ? errors.passenger[idx] ?? {} : {}}
                  requirePassNo={REQUIRE_PASS_NO}
                  returnFlightDate={search.returnDate}
                />
              ))}
            </tbody>
          </table>

          <h6 className="mt-2 py-2 font-bold">Contact Information</h6>

          <ContactBookingInfo
            value={values.contact}
            field={fields.contact}
            errors={showErrors ? errors.contact : {}}
          />

          <h6 className="mt-2 py-2 font-bold">Special Request</h6>
          <div className="flex">
            <TextInput
              inputClassName="text-sm"
              value={values.demand}
              onChange={fields.demand}
              multiline
              rows={5}
            />
          </div>

          {paymentTypes.length > 1 && (
            <>
              <h6 className="mt-2 py-2 font-bold">Payment Method</h6>
              <div className="flex text-sm">
                <RadioInput
                  horizontal
                  name="payment-type"
                  options={paymentTypes}
                  value={values.paymentMethod}
                  onChange={fields.paymentMethod}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
