import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { FullPageLoader } from "@presentation/shared/components/loader/FullPageLoader";
import Modal from "@presentation/shared/components/modal/Modal";

type Props = {
  page: string;
  onHide(): void;
};

export function TermsModal({ page, onHide }: Props) {
  const { loading, data } = useService(Requests.static, true, {
    page,
  });

  const { title, description = "" } = data?.[0] ?? {};

  return (
    <Modal title={title} onHide={onHide}>
      <div className="text-lg">
        {loading && <FullPageLoader />}
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>
    </Modal>
  );
}
