import { Button } from "@presentation/shared/components/button/Button";
import TextInput from "@presentation/shared/components/text-input/TextInput";
import { useMemo, useState } from "react";
import { Settings } from "@config/tenants/settings";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { T } from "@config/lang";

const EMAIL_REGEX = /^[\w-.]+@([A-Z0-9-]+\.)+[A-Z]{2,}$/i;

type Props = {
  title?: string;
  description?: string;
};

export function Contact({ title, description }: Props) {
  const { map } = Settings.contact;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const isValid = useMemo(
    () => name && email && EMAIL_REGEX.test(email) && message?.length > 20,
    [name, email, message]
  );

  const [sent, setSent] = useState(false);
  const { loading, requestAsync } = useService(Requests.contact);

  const onSend = () => {
    requestAsync({ payload: { name, email, message } }).then(() => {
      setSent(true);
    });
  };

  return (
    <section className="mb-32 text-center">
      <div className="py-12 md:px-12">
        <div className="container mx-auto xl:px-32">
          <div className="grid items-center lg:grid-cols-2">
            <div className="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
              <div className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                <div className="mb-8">
                  <h2 className="text-3xl font-bold">
                    {title ?? T.contact.title}
                  </h2>
                  <div className="my-4 text-gray-500">
                    {description ? (
                      <div
                        className="rich-text"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    ) : (
                      <p>{T.contact.description}</p>
                    )}
                  </div>
                </div>

                {sent ? (
                  <div>{T.contact.we_will_get_back_to_you}</div>
                ) : (
                  <div>
                    <div className="relative mb-6">
                      <TextInput
                        value={name}
                        onChange={setName}
                        placeholder={T.contact.name}
                      />
                    </div>
                    <div className="relative mb-6">
                      <TextInput
                        value={email}
                        onChange={setEmail}
                        type="email"
                        placeholder={T.contact.email_address}
                      />
                    </div>
                    <div className="relative mb-6">
                      <TextInput
                        value={message}
                        onChange={setMessage}
                        multiline
                        rows={3}
                        placeholder={T.contact.message}
                      />
                    </div>
                    <Button
                      className="inline-block w-full"
                      type="primary"
                      onClick={onSend}
                      disabled={loading || !isValid}
                    >
                      {T.contact.send}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="md:mb-12 lg:mb-0">
              <div className="relative h-[700px] rounded-lg shadow-lg dark:shadow-black/20">
                {map && (
                  <iframe
                    src={map}
                    className="absolute left-0 top-0 h-full w-full rounded-lg border-0"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
