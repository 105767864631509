import { useMemo, useRef } from "react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function HotelGallery({ mainImage, gallery, onHide }) {
  const images = useMemo(
    () => [
      { src: mainImage.large },
      ...gallery.map(({ large: src }) => ({
        src,
      })),
    ],
    [mainImage, gallery]
  );

  const thumbnailsRef = useRef(null);

  return (
    <Lightbox
      open
      close={onHide}
      slides={images}
      plugins={[Thumbnails]}
      // @ts-ignore
      thumbnails={{ ref: thumbnailsRef }}
    />
  );
}

export default HotelGallery;
