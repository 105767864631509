import * as React from "react";
import { Link } from "react-router-dom";
import { useCompany } from "@application/context/company-context";
import { cx } from "@presentation/vendor/classnames";
import { AiOutlineMail, AiOutlinePhone, AiOutlineUser } from "react-icons/ai";
import { useTheme } from "../../theme/useTheme";
import HeaderTopLanguages from "./HeaderTopLanguages";
import { T } from "@config/lang";
import { Settings } from "@config/tenants/settings";

function HeaderTop() {
  const { header } = useTheme();
  const { email, phone } = useCompany();
  const topLinks = Settings.links.top;
  const links = [];
  if (phone && topLinks.includes("phone")) {
    links.push({
      href: `tel:${phone}`,
      Icon: AiOutlinePhone,
      text: phone,
    });
  }
  if (email && topLinks.includes("email")) {
    links.push({
      href: `mailto:${email}`,
      Icon: AiOutlineMail,
      text: email,
    });
  }
  if (topLinks.includes("b2b")) {
    links.push({
      to: "/agent",
      Icon: AiOutlineUser,
      text: T.b2b_login,
      smOnly: true,
    });
  }

  if (header.top.hide) {
    return null;
  }

  return (
    <div className={cx(header.top.container)}>
      <div className="container mx-auto flex justify-end items-center">
        <div className="flex">
          {links.map(({ text, to, href, Icon, smOnly }, idx) => {
            const Tag = to
              ? Link
              : ({ children, ...rest }) => <a {...rest}>{children}</a>;
            return (
              <Tag
                className={cx(
                  "whitespace-nowrap items-center",
                  header.top.link,
                  smOnly ? "hidden md:flex" : "flex"
                )}
                to={to}
                href={href}
                key={idx}
              >
                <Icon className="text-lg mr-1" />
                {text}
              </Tag>
            );
          })}
          <div className="hidden md:inline-block z-50">
            <HeaderTopLanguages />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderTop;
