import { UseSearchReturnType } from "@agent/hooks/useSearch";
import { T } from "@config/lang";
import { GiAirplaneDeparture, GiModernCity } from "react-icons/gi";
import SelectInput from "@presentation/shared/components/select/SelectInput";
import * as React from "react";
import {
  MdLocationOn,
  MdMap,
  MdOutlinedFlag,
  MdOutlineHotel,
} from "react-icons/md";
import { IoMdAirplane } from "react-icons/io";
import { useCallback } from "react";
import DateRangeInput from "@presentation/shared/components/date-range/DateRangeInput";
import { NumberGuestsInput } from "@presentation/shared/components/number-guests/NumberGuestsInput";
import { SEARCH_TYPES } from "@config/const/enums";
import { Settings } from "@config/tenants/settings";

const DESTINATION_ICONS = {
  country: MdOutlinedFlag,
  region: MdMap,
  city: GiModernCity,
  airport: IoMdAirplane,
  hotel: MdOutlineHotel,
  none: MdLocationOn,
};

type Props = {
  search: UseSearchReturnType;
};

export function SearchBox(props: Props) {
  const {
    searchType,
    departures,
    departureId,
    setDepartureId,
    destinations,
    destinationId,
    setDestinationId,
    departureDates,
    departDate,
    setDepartDate,
    returnDates,
    returnDate,
    setReturnDate,
    adults,
    setAdults,
    children,
    setChildren,
  } = props.search;

  const isCharter = searchType === SEARCH_TYPES.charter;
  const extraDays = isCharter ? Settings.search.charter.extraDays : 0;

  const destinationRenderer = useCallback((option) => {
    const Icon = DESTINATION_ICONS[option.type];

    return (
      <div className="flex flex-row cursor-pointer items-center">
        <div className="pr-1">
          <Icon className="mr-2" />
        </div>
        <div className="flex flex-col flex-grow">
          <div className="font-bold text-ellipsis">{option.name}</div>
          <div className="text-xs text-gray-500 text-ellipsis">
            {option.add}
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="w-full grid grid-cols-4 gap-4">
      {searchType === "charter" && (
        <SelectInput
          options={departures}
          value={departureId}
          onChange={setDepartureId}
          label={T.search.departure_airport}
          placeholder={T.inputs.select___}
          searchTextLength={1}
          // autocomplete
          disabled={!departures?.length}
          Icon={GiAirplaneDeparture}
        />
      )}
      <SelectInput
        options={destinations}
        value={destinationId}
        onChange={setDestinationId}
        label={T.search.region_hotel_airport}
        placeholder={T.inputs.select___}
        autocomplete
        disabled={!destinations?.length}
        // clearable
        searchTextLength={1}
        searchFields={["search"]}
        Icon={DESTINATION_ICONS[destinationId?.split("/")?.[0] ?? "none"]}
        renderOption={destinationRenderer}
      />
      <DateRangeInput
        startDate={departDate}
        onStartDateChange={setDepartDate}
        endDate={returnDate}
        onEndDateChange={setReturnDate}
        startDateOptions={departureDates}
        endDateOptions={returnDates}
        label={T.search.travel_dates}
        extraDays={extraDays}
        showDays={isCharter}
      />
      <NumberGuestsInput
        searchType={searchType}
        adults={adults}
        onAdultsChange={setAdults}
        children={children}
        onChildrenChange={setChildren}
        label={T.search.guests}
      />
    </div>
  );
}
