import * as React from "react";
import { SearchRepositoryProvider } from "@presentation/shared/context/search-repository-context";
import SearchBox from "@presentation/shared/components/search-box/SearchBox";
import { NavigationProvider } from "@presentation/shared/context/navigation-context";
import {
  IFrameProvider,
  PAGES,
  useIFrame,
} from "@presentation/shared/context/iframe-context";
import { Search } from "@presentation/iframe/Search";
import { Details } from "@presentation/iframe/Details";
import { Booking } from "@presentation/iframe/Booking";
import { Confirmation } from "@presentation/iframe/Confirmation";

function PageSwitcher() {
  const { page } = useIFrame();

  if (page === PAGES.SEARCH) {
    return <Search />;
  }
  if (page === PAGES.DETAILS) {
    return <Details />;
  }
  if (page === PAGES.BOOKING) {
    return <Booking />;
  }
  if (page === PAGES.CONFIRMATION) {
    return <Confirmation />;
  }
  return null;
}

export function FrameApp() {
  return (
    <IFrameProvider iframe>
      <div className="mx-auto px-6">
        <NavigationProvider>
          <SearchRepositoryProvider>
            <SearchBox />
            <PageSwitcher />
          </SearchRepositoryProvider>
        </NavigationProvider>
      </div>
    </IFrameProvider>
  );
}
