import { useEffect, useMemo } from "react";
import * as moment from "moment";
import SelectInput from "@presentation/shared/components/select/SelectInput";
import TextInput from "@presentation/shared/components/text-input/TextInput";
import DatePicker, {
  DateFormats,
} from "@presentation/shared/components/date-picker/DatePicker";

function PassengerBookingInfo({
  number,
  errors,
  value,
  field,
  requirePassNo,
  returnFlightDate,
}) {
  const titleOptions = useMemo(() => {
    if (value.type === "ADL") {
      return [
        { value: "MR", name: "Mr." },
        { value: "MRS", name: "Mrs." },
      ];
    }
    const val = value.maxAge <= 1 ? "INF" : "CHD";
    return [{ value: val, name: val }];
  }, [value]);

  useEffect(() => {
    if (!value.gender) {
      field.gender(titleOptions[0].value);
    }
  }, [titleOptions, value, field]);

  const today = moment.utc().format("YYYY-MM-DD");

  const minBirthDate = value.maxAge
    ? moment(returnFlightDate)
        .subtract(parseInt(value.maxAge) + 1, "years")
        .format("YYYY-MM-DD")
    : "1920-01-01";
  
  return (
    <tr>
      <td className="px-2 py-1 font-semibold">{number}</td>
      <td className="px-2 py-1 w-24">
        <SelectInput
          inputClassName="md:text-sm"
          value={value.gender}
          onChange={field.gender}
          options={titleOptions}
        />
      </td>
      <td className="px-2 py-1">
        <TextInput
          inputClassName="text-sm"
          value={value.firstName}
          onChange={field.firstName}
          required
          hasError={!!errors.firstName}
        />
      </td>
      <td className="px-2 py-1">
        <TextInput
          inputClassName="text-sm"
          value={value.lastName}
          onChange={field.lastName}
          required
          hasError={!!errors.lastName}
        />
      </td>
      <td className="px-2 py-1 w-32">
        <DatePicker
          value={value.birthDate}
          onChange={field.birthDate}
          dateFormat={DateFormats.STRING}
          maxDate={today}
          minDate={minBirthDate}
          rightAligned
        />
        <div className="text-red-500">{errors.birthDate}</div>
      </td>
      {requirePassNo && (
        <td className="px-2 py-1">
          <TextInput
            inputClassName="text-sm"
            value={value.passportNo}
            onChange={field.passportNo}
            required
            hasError={!!errors.passportNo}
          />
        </td>
      )}
      {/*
          <td className="px-2 py-1"><DatePicker
            value={passenger.passportExpireDate}
            onChange={handleChange('passportExpireDate')}
            dateFormat={DateFormats.STRING}
            hasError={!!errors.passportExpireDate}
            minDate={today}
          />
          <div className="text-red-500">{errors.passportExpireDate}</div>
          </td>
        */}
    </tr>
  );
}

export default PassengerBookingInfo;
