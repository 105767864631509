import { useState } from "react";
import HotelGalleryLightbox from "@presentation/pages/search/HotelGallery";
import { cx } from "@presentation/vendor/classnames";

export function HotelGallery({ hotel }) {
  const mainImage = hotel.image.large;
  const otherImages = hotel.gallery.slice(1) ?? [];
  const cols = 1 + Math.ceil(otherImages.length / 2);
  const minWidth = Math.max(100, 25 * cols);

  const [showGallery, setShowGallery] = useState(false);
  const hasMoreImages = !!hotel.gallery?.length;

  return (
    <div
      className={cx("w-full overflow-x-scroll", {
        "hover:cursor-pointer": hasMoreImages,
      })}
      onClick={hasMoreImages ? () => setShowGallery(true) : undefined}
    >
      {showGallery && (
        <HotelGalleryLightbox
          mainImage={hotel.image}
          gallery={hotel.gallery}
          onHide={() => setShowGallery(false)}
        />
      )}
      <div
        className="grid h-96 grid-cols-1 md:grid-rows-2 gap-2"
        style={{
          minWidth: `${minWidth}%`,
          gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
        }}
      >
        <div
          className="bg-cover bg-center bg-no-repeat md:col-span-2 md:row-span-2"
          style={{ backgroundImage: `url(${mainImage})` }}
        />
        {otherImages.map((image) => (
          <div
            key={image.medium}
            className="bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${image.medium})` }}
          />
        ))}
      </div>
    </div>
  );
}
