import * as React from "react";
import { useSearchRepository } from "../../context/search-repository-context";
import { Settings } from "@config/tenants/settings.default";
import SelectInput from "../select/SelectInput";
import DateRangeInput from "../date-range/DateRangeInput";
import { Button } from "../button/Button";
import { NumberGuestsInput } from "../number-guests/NumberGuestsInput";
import { GiAirplaneDeparture, GiModernCity } from "react-icons/gi";
import { IoMdAirplane } from "react-icons/io";
import {
  MdLocationOn,
  MdOutlineHotel,
  MdMap,
  MdOutlinedFlag,
} from "react-icons/md";
import { cx } from "@presentation/vendor/classnames";
import { ReactElement, useCallback } from "react";
import { T } from "@config/lang";
import { SEARCH_TYPES } from "@config/const/enums";
import { PAGES, useIFrame } from "@presentation/shared/context/iframe-context";
// import DateSelector from "./DateSelector";
// import SelectInput from "../../ui/components/forms/SelectInput";
// import FormField from "../../ui/components/forms/FormField";

const DESTINATION_ICONS = {
  country: MdOutlinedFlag,
  region: MdMap,
  city: GiModernCity,
  airport: IoMdAirplane,
  hotel: MdOutlineHotel,
};

function SearchBox(): ReactElement {
  const { current } = useSearchRepository();
  const { iframe, setPage } = useIFrame();
  const {
    searchType,
    departureOptions,
    departureId,
    setDepartureId,
    destinationOptions,
    destinationId,
    setDestinationId,
    returnType,
    departureDateOptions,
    departureDate,
    setDepartureDate,
    returnDateOptions,
    returnDate,
    setReturnDate,
    adults,
    setAdults,
    children,
    setChildren,
    canSearch,
    doSearch,
    performSearch,
  } = current;

  const searchIframe = () => {
    performSearch();
    setPage(PAGES.SEARCH);
  };

  const isCharter = searchType === SEARCH_TYPES.charter;
  const extraDays = isCharter ? Settings.search.charter.extraDays : 0;
  const showDepartures = Settings.search?.[searchType].displayDepartures ?? {};

  const DestinationIcon = DESTINATION_ICONS[returnType] ?? MdLocationOn;

  const destinationRenderer = useCallback((option) => {
    const Icon = DESTINATION_ICONS[option.type];

    return (
      <div className="flex flex-row cursor-pointer items-center">
        <div className="pr-1">
          <Icon className="mr-2" />
        </div>
        <div className="flex flex-col flex-grow">
          <div className="font-bold text-ellipsis">{option.name}</div>
          <div className="text-xs text-gray-500 text-ellipsis">
            {option.add}
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="mx-auto flex flex-col lg:flex-row justify-between text-sm">
      <div
        className={cx(
          "w-full grid xs:grid-cols-1 md:gap-x-3",
          showDepartures
            ? "md:grid-cols-6 lg:grid-cols-11"
            : "md:grid-cols-6 lg:grid-cols-8"
        )}
      >
        {showDepartures && (
          <div className="col-span-3 my-2">
            <SelectInput
              options={departureOptions}
              value={departureId}
              onChange={setDepartureId}
              label={T.search.departure_airport}
              placeholder={T.inputs.select___}
              searchTextLength={1}
              // autocomplete
              disabled={!departureOptions}
              Icon={GiAirplaneDeparture}
            />
          </div>
        )}
        <div className="col-span-3 my-2">
          <SelectInput
            options={destinationOptions}
            value={destinationId}
            onChange={setDestinationId}
            label={T.search.region_hotel_airport}
            placeholder={T.inputs.select___}
            autocomplete
            disabled={!destinationOptions}
            // clearable
            searchTextLength={1}
            searchFields={["search"]}
            Icon={DestinationIcon}
            renderOption={destinationRenderer}
          />
        </div>
        <div className="col-span-3 my-2">
          <DateRangeInput
            startDate={departureDate}
            onStartDateChange={setDepartureDate}
            endDate={returnDate}
            onEndDateChange={setReturnDate}
            startDateOptions={departureDateOptions}
            endDateOptions={returnDateOptions}
            label={T.search.travel_dates}
            extraDays={extraDays}
            showDays={isCharter}
          />
        </div>
        <div className="col-span-3 lg:col-span-2 my-2">
          <NumberGuestsInput
            searchType={searchType}
            adults={adults}
            onAdultsChange={setAdults}
            children={children}
            onChildrenChange={setChildren}
            label={T.search.guests}
          />
        </div>
      </div>
      <div className="flex items-stretch justify-end lg:justify-center my-2 lg:ml-4">
        <Button
          disabled={!canSearch}
          onClick={iframe ? searchIframe : doSearch}
          size="base"
        >
          {T.search.search}
        </Button>
      </div>
    </div>
  );
}

export default SearchBox;
