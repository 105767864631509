import * as React from "react";
import * as moment from "moment";
import { FiCalendar } from "react-icons/fi";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AiOutlineCaretRight,
  AiOutlineCaretLeft,
  // AiOutlineLine,
} from "react-icons/ai";
import { DEFAULT_DATE_FORMAT } from "@config/const/dates";
import { useOnClickOutside } from "@presentation/shared/hooks/useOnClickOutside";
import { RiArrowDownSLine } from "react-icons/ri";
import { cx } from "@presentation/vendor/classnames";
import * as classNames from "classnames";
import { T } from "@config/lang";

const DATE_FORMAT = "YYYY-MM-DD";

interface Props {
  startDate: string;
  endDate: string;
  dateFormat?: string;
  startDateOptions: string[];
  endDateOptions: string[];

  onStartDateChange(val: string): void;

  onEndDateChange(val: string): void;

  label?: string;
  extraDays?: number;
  showDays?: boolean;
}

function DateRangeInput(props: Props): ReactElement {
  const {
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    startDateOptions,
    endDateOptions,
    dateFormat = DEFAULT_DATE_FORMAT,
    label,
    extraDays = 1,
    showDays = false,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [chooseStart, setChooseStart] = useState<boolean>(true);
  useEffect(() => setChooseStart(open), [open]);

  const onCancel = useCallback(() => {
    if (open) {
      if (startDate && !endDate) {
        onStartDateChange(null);
        onEndDateChange(null);
      }
      setOpen(false);
    }
  }, [open, startDate, endDate, onStartDateChange, onEndDateChange]);
  const ref = useRef();
  useOnClickOutside(ref, onCancel);

  const days = useMemo(
    () =>
      startDate && endDate
        ? moment.utc(endDate).diff(startDate, "days") + extraDays
        : null,
    [startDate, endDate, extraDays]
  );

  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    if (open) {
      let date = null;
      if (startDateOptions?.length) {
        date = startDateOptions.includes(startDate)
          ? startDate
          : startDateOptions?.[0];
      }
      setCurrentDate(date);
    }
    // eslint-disable-next-line
  }, [open]);

  const [hover, setHover] = useState(null);

  const showDates = useMemo(() => {
    if (!currentDate) {
      return [];
    }

    function datesForMonth(month: string, options: string[]) {
      const result = [];
      let date = moment.utc(month);
      const end = moment.utc(month).endOf("month");
      while (date.isSameOrBefore(end, "day")) {
        const d = date.format(DATE_FORMAT);
        const selected = d === startDate || d === endDate;
        const end = startDate && endDate ? endDate : hover;
        const highlighted =
          startDate && end
            ? date.isBetween(
                moment.utc(startDate),
                moment.utc(end),
                "day",
                "[]"
              )
            : false;
        const disabled = !options.includes(d);
        result.push({
          disabled,
          selected,
          highlighted,
          date,
        });
        date = date.clone().add(1, "day");
      }
      return result;
    }

    const month = moment.utc(currentDate).startOf("month").format();
    const nextMonth = moment
      .utc(month)
      .add(35, "days")
      .startOf("month")
      .format();

    const options = chooseStart ? startDateOptions ?? [] : endDateOptions ?? [];
    return [
      {
        first: month,
        dates: datesForMonth(month, options),
      },
      {
        first: nextMonth,
        dates: datesForMonth(nextMonth, options),
      },
    ];
  }, [
    currentDate,
    chooseStart,
    startDateOptions,
    endDateOptions,
    startDate,
    endDate,
    hover,
  ]);

  const chooseDate = useCallback(
    (date) => {
      if (chooseStart) {
        onStartDateChange(date.format(DATE_FORMAT));
        onEndDateChange(null);
        setChooseStart(false);
      } else {
        setOpen(false);
        onEndDateChange(date.format(DATE_FORMAT));
        setChooseStart(true);
      }
    },
    [chooseStart, onStartDateChange, onEndDateChange]
  );

  const enabled = startDateOptions?.length > 0;

  const [canPrev, canNext] = useMemo(() => {
    if (!open) {
      return [false, false];
    }
    const prev = true;
    const next = true;
    // if (startDateOptions?.length) {
    //   return [
    //     startDateOptions.includes(startDate),
    //     startDateOptions.includes(endDate) || endDateOptions?.includes(endDate),
    //   ];
    // }
    return [prev, next];
  }, [open]);

  const onPrev = () => {
    setCurrentDate(moment.utc(currentDate).subtract(1, "month").format());
  };

  const onNext = () => {
    setCurrentDate(moment.utc(currentDate).add(1, "month").format());
  };

  return (
    <div
      className={cx(
        "relative flex flex-col justify-center items-center border border-gray-300 rounded shadow-sm select-none"
      )}
      onClick={enabled && !open ? () => setOpen(true) : undefined}
    >
      <div
        className={cx(
          "w-full flex flex-nowrap overflow-x-hidden justify-between items-center text-base cursor-default rounded",
          enabled ? "bg-white" : "bg-gray-100"
        )}
      >
        <span
          className={cx(
            "p-2 text-xl text-gray-500",
            open ? "text-gray-500" : "text-gray-300"
          )}
        >
          <FiCalendar />
        </span>
        <span className="ml-1 mr-2 pt-5 flex flex-col flex-grow justify-center items-start text-sm md:text-base flex-nowrap overflow-x-hidden">
          {label && (
            <div
              className={classNames(
                "absolute top-0 w-full text-gray-400 py-0.5 text-xs"
              )}
            >
              {label}
            </div>
          )}
          {startDate ? (
            <div className="flex items-center text-ellipsis text-sm md:text-base py-0.5">
              <span>{moment.utc(startDate).format(dateFormat)}</span>
              <span className="text-gray-500">-</span>
              {endDate && <span>{moment.utc(endDate).format(dateFormat)}</span>}
              {days && (
                <span className="whitespace-nowrap">
                  &nbsp;
                  {"(" +
                    days +
                    " " +
                    (days > 1
                      ? showDays
                        ? T.search.days
                        : T.search.nights
                      : showDays
                      ? T.search.day
                      : T.search.night) +
                    ")"}
                </span>
              )}
            </div>
          ) : (
            <span className="pb-0.5 text-gray-400 text-sm md:text-base">
              {T.inputs.select___}
            </span>
          )}
        </span>
        <span className="flex items-center pr-2">
          <RiArrowDownSLine
            className={cx(
              "w-5 h-5 text-gray-400 hover:text-gray-600 transition-transform ease-in-out duration-200 transform",
              open ? "rotate-180" : "rotate-0"
            )}
            aria-hidden="true"
          />
        </span>
      </div>
      <div ref={ref} className="relative w-full">
        {open && (
          <div className="absolute mt-2 border border-gray-200 shadow bg-white rounded p-4 text-base z-[1000]">
            <div className="flex items-start">
              <div>
                <span
                  className={cx(
                    canPrev
                      ? "cursor-pointer text-gray-500 hover:text-blue-500"
                      : "text-gray-300",
                    "mt-4"
                  )}
                  onClick={onPrev}
                >
                  <AiOutlineCaretLeft />
                </span>
              </div>
              <div className="flex flex-col md:flex-row md:flex-grow text-sm]">
                {showDates.map(({ first, dates }) => {
                  const weekday = moment.utc(first).weekday();
                  const start = moment.utc(first).startOf("week");
                  return (
                    <div key={first} className="mx-4 flex flex-col">
                      <div className="text-center font-bold text-gray-600">
                        {moment.utc(first).format("MMMM YYYY")}
                      </div>
                      <div className="w-64 grid grid-cols-7">
                        {Array.from(Array(7).keys()).map((key) => (
                          <div
                            key={key}
                            className="text-sm md:text-base text-gray-500 py-2 flex justify-center items-center"
                          >
                            {start.clone().add(key, "days").format("ddd")}
                          </div>
                        ))}
                        {Array.from(Array(weekday).keys()).map((key) => (
                          <div key={key}>&nbsp;</div>
                        ))}
                        {dates.map(
                          ({ disabled, selected, highlighted, date }) => (
                            <div
                              key={date.format("D")}
                              className={cx(
                                "border flex justify-end items-center px-2 py-1.5 text-sm md:text-base -mb-[1px] -mr-[1px]",
                                disabled
                                  ? "cursor-default"
                                  : "cursor-pointer border-primary-400",
                                disabled && !selected && "text-gray-400",
                                highlighted &&
                                  !selected &&
                                  "bg-primary-100 text-white",
                                selected
                                  ? "border-primary-500 bg-primary-500 text-white"
                                  : disabled && "border-transparent"
                              )}
                              onClick={() => {
                                if (!disabled) {
                                  chooseDate(date);
                                }
                              }}
                              onMouseEnter={() =>
                                setHover(date.format(DATE_FORMAT))
                              }
                              onMouseLeave={() => setHover(null)}
                            >
                              {date.format("D")}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <span
                  className={cx(
                    canNext
                      ? "cursor-pointer text-gray-500 hover:text-blue-500"
                      : "text-gray-300",
                    "mt-4"
                  )}
                  onClick={onNext}
                >
                  <AiOutlineCaretRight />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DateRangeInput;
