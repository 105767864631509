import axios from "axios";
import { Settings } from "@config/tenants/settings";

export const Axios = axios.create({
  baseURL: Settings.endpoint,
});

Axios.interceptors.request.use(
  async (config) => {
    const id_token = localStorage.getItem("id_token");
    config.headers.Accept = "application/json";
    if (id_token) {
      config.headers.Authorization = `Bearer ${id_token}`;
    }
    config.headers["Accept-Language"] = sessionStorage.getItem("lang") ?? "en";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
