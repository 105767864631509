import { HiArrowSmRight, HiTable, HiViewBoards } from "react-icons/hi";
import { BiBuoy } from "react-icons/bi";
import { T } from "@config/lang";
import { Bookings } from "@agent/pages/bookings/Bookings";
import { NewBooking } from "@agent/pages/new-booking/NewBooking";
import { BsCalendar, BsCalendarPlus } from "react-icons/bs";

export const menu = () => [
  {
    items: [
      {
        title: T.agent.new_booking,
        to: "",
        icon: BsCalendarPlus,
        Component: NewBooking,
      },
      {
        title: T.agent.bookings,
        to: "/bookings",
        icon: BsCalendar,
        Component: Bookings,
      },
      { title: T.agent.sign_in, to: "/sign_in", icon: HiArrowSmRight },
      { title: T.agent.sign_up, to: "/sign_up", icon: HiTable },
    ],
  },
  {
    items: [
      {
        title: T.agent.documentation,
        to: "/documentation",
        icon: HiViewBoards,
      },
      { title: T.agent.help, to: "/help", icon: BiBuoy },
    ],
  },
];
