import { FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";
import "./FlightDetails.css";

function FlightDetails({ flight, isDeparture }) {
  return (
    <div className="flex items-stretch my-4">
      <div className="text-3xl px-3 text-primary-200 flex flex-col items-center pt-2 rounded-l-lg border border-r-0 border-primary-200 overflow-hidden">
        <div>{isDeparture ? <FaPlaneDeparture /> : <FaPlaneArrival />}</div>
        <img
          className="inline-block h-16"
          src={flight.flight_company_logo}
          alt="Airline logo"
        />
      </div>
      <div className="flex justify-between flex-col">
        <div className="semi-circle low border-primary-200" />
        <div className="semi-circle high border-primary-200" />
      </div>
      <div className="px-3 py-2 rounded-r-lg border border-l-0 border-primary-200 flex-grow">
        <div className="font-semibold">
          {flight.from_airport} ({flight.from_airport_code}) -{" "}
          {flight.to_airport} ({flight.to_airport_code})
        </div>
        <div>
          {flight.flight_date}, {flight.depart.substring(0, 5)} -{" "}
          {flight.arrive.substring(0, 5)}
        </div>
        {/*<div>{flight.flight_company}</div>*/}
        <div>Flight no: {flight.code}</div>
      </div>
    </div>
  );
}

export default FlightDetails;
