import { useIFrame } from "@presentation/shared/context/iframe-context";
import { BookingConfirmation } from "@presentation/pages/confirmation/BookingConfirmation";

export function Confirmation() {
  const { confirmationCode } = useIFrame();
  return (
    <div className="mt-6">
      <BookingConfirmation code={confirmationCode} />
    </div>
  );
}
