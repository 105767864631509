import * as React from "react";
import { ImLocation } from "react-icons/im";
import { useCompany } from "@application/context/company-context";
import { cx } from "@presentation/vendor/classnames";
import { Settings } from "../../../config/tenants/settings";
import { useTheme } from "../../theme/useTheme";
import { T } from "@config/lang";
import visa from "../../assets/visa.png";
import master from "../../assets/master.png";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

function FooterMenu() {
  const { map, mapOnFooter } = Settings.contact;
  const { address, email, phone } = useCompany();
  const { logo, secondary_logo, name } = useCompany();
  const { footer } = useTheme();

  return (
    <div className={cx(footer.menu.container)}>
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start">
        <div>
          <img
            src={secondary_logo ?? logo}
            className={cx(footer.logo)}
            alt={name}
          />
        </div>
        <div>
          <div className="my-1 flex items-center">
            <ImLocation className="text-xl mr-2" />
            <span className="font-bold">{T.address}</span>
          </div>
          <div>{address}</div>
          {mapOnFooter && map && (
            <div className="relative min-w-full max-w-md h-32 my-4">
              <iframe
                src={map}
                className="absolute left-0 top-0 h-full w-full rounded-lg border-0"
                allowFullScreen
              ></iframe>
            </div>
          )}
          <div className="my-1 flex items-center">
            <AiOutlineMail className="mr-2" />
            <span>{email}</span>
          </div>
          <div className="my-1 flex items-center">
            <AiOutlinePhone className="mr-2" />
            <span>{phone}</span>
          </div>
        </div>
        {Settings.social && (
          <div>
            <div className="my-1 flex items-center">
              <span className="font-bold">{T.social_media}</span>
            </div>
            <div className="flex flex-col">
              {Settings.social.map(({ Icon, url, name }) => (
                <a
                  target="_blank"
                  href={url}
                  key={url}
                  rel="noreferrer"
                  className="flex my-1 hover:opacity-70"
                >
                  <Icon className="text-xl mr-2" />
                  {name}
                </a>
              ))}
            </div>
          </div>
        )}
        <div>
          <div className="inline-flex md:m-4 lg:m-6">
            <img src={visa} className="h-10 mr-4" alt="VISA" />
            <img src={master} className="h-10" alt="MasterCard" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterMenu;
