import FormField from "@presentation/shared/components/form-field/FormField";
import TextInput from "@presentation/shared/components/text-input/TextInput";

function ContactBookingInfo({ value, field, errors }) {
  return (
    <div className="grid grid-cols-7 gap-x-4 text-sm">
      <FormField className="col-span-2" label="Email Address" hideErrors>
        <TextInput
          inputClassName="md:text-sm"
          value={value.email}
          onChange={field.email}
          required
          type="email"
          hasError={!!errors.email}
        />
      </FormField>

      <FormField className="col-span-3" label="Address" hideErrors>
        <TextInput
          inputClassName="md:text-sm"
          value={value.address}
          onChange={field.address}
          required
          hasError={!!errors.email}
        />
      </FormField>

      <FormField className="col-span-2" label="Phone number" hideErrors>
        <TextInput
          inputClassName="md:text-sm"
          value={value.phone}
          onChange={field.phone}
          type="tel"
          required
          hasError={!!errors.phone}
        />
      </FormField>
    </div>
  );
}

export default ContactBookingInfo;
