import { Settings as TenantSettings } from "@config/tenants/settings";
import { LANGUAGE, LANGUAGE_NAMES } from "@config/lang";
import { ISettings } from "@config/tenants/types";
import { theme } from "@presentation/theme/theme";

const rootNode = document.getElementById("tuiroot");
const attributes = rootNode?.attributes;

function getAttribute(name: string): string | null {
  return attributes?.getNamedItem(name)?.value ?? null;
}

let language = getAttribute("lang");
if (!language || !TenantSettings.languages.includes(language as LANGUAGE)) {
  language = TenantSettings.languages[0].toString();
}

const languages = TenantSettings.languages.map((it) => ({
  locale: it.toString(),
  name: LANGUAGE_NAMES[it],
}));

const { endpoint } = TenantSettings;

export const Settings: ISettings = {
  rootNode,
  tenant: process.env.REACT_APP_TENANT,
  endpoint,
  language,
  languages,
  theme,
  menuLinks: TenantSettings.menuLinks,
  googleAnalyticsTrackingId: TenantSettings.googleAnalyticsTrackingId,
};
