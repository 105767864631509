import React, { useEffect, useMemo } from "react";
import moment from "moment";
import FormField from "../../shared/components/form-field/FormField";
import SelectInput from "../../shared/components/select/SelectInput";
import TextInput from "../../shared/components/text-input/TextInput";
import DatePicker, {
  DateFormats,
} from "../../shared/components/date-picker/DatePicker";
import { T } from "@config/lang";
import { useSearchRepository } from "@presentation/shared/context/search-repository-context";

function PassengerBookingInfo({ number, errors, value, field, requirePassNo }) {
  const { current } = useSearchRepository();

  const titleOptions = useMemo(() => {
    if (value.type === "ADL") {
      return [
        { value: "MR", name: "Mr." },
        { value: "MRS", name: "Mrs." },
      ];
    }
    const val = value.maxAge === 1 ? "INF" : "CHD";
    return [{ value: val, name: val }];
  }, [value]);

  useEffect(() => {
    if (!value.gender) {
      field.gender(titleOptions[0].value);
    }
  }, [titleOptions, value, field]);

  const today = moment.utc().format("YYYY-MM-DD");

  return (
    <div>
      <h6 className="py-2 font-semibold">
        {T.booking.passenger} {number}
      </h6>
      <div className="flex flex-wrap lg:flex-nowrap">
        <FormField label="Title" className="w-24 flex-shrink-0 mr-4">
          <SelectInput
            value={value.gender}
            onChange={field.gender}
            options={titleOptions}
          />
        </FormField>

        <FormField
          className="w-full lg:flex-grow mr-4"
          label={T.booking.first_name}
          error={errors.firstName}
        >
          <TextInput
            value={value.firstName}
            onChange={field.firstName}
            required
            hasError={!!errors.firstName}
          />
        </FormField>

        <FormField
          className="w-full lg:flex-grow mr-4"
          label={T.booking.last_name}
          error={errors.lastName}
        >
          <TextInput
            value={value.lastName}
            onChange={field.lastName}
            required
            hasError={!!errors.lastName}
          />
        </FormField>

        <FormField
          className="flex-shrink-0 w-full lg:w-40 mr-4"
          label={T.booking.birth_date}
          error={errors.birthDate}
          description={
            value.type !== "ADL" &&
            T.booking.maximum_age +
              " " +
              value.maxAge +
              " " +
              (value.maxAge > 1 ? T.booking.years : T.booking.year)
          }
        >
          <DatePicker
            value={value.birthDate}
            onChange={field.birthDate}
            dateFormat={DateFormats.STRING}
            hasError={!!errors.birthDate}
            maxDate={today}
            minDate={
              Number.isInteger(value.maxAge)
                ? moment
                    .utc(current.returnDate)
                    .subtract(value.maxAge + 1, "years")
                    .format()
                : undefined
            }
          />
        </FormField>

        {requirePassNo && (
          <FormField
            className="w-full lg:flex-grow mr-4"
            label={T.booking.passport_number}
            error={errors.passportNo}
          >
            <TextInput
              value={value.passportNo}
              onChange={field.passportNo}
              required
              hasError={!!errors.passportNo}
            />
          </FormField>
        )}
        {/*
        <FormField className="w-full lg:w-40 mr-4" label="Passport Expires" error={errors.passportExpireDate}>
          <DatePicker
            value={passenger.passportExpireDate}
            onChange={handleChange('passportExpireDate')}
            dateFormat={DateFormats.STRING}
            hasError={!!errors.passportExpireDate}
            minDate={today}
          />
        </FormField>
        */}
      </div>
    </div>
  );
}

export default PassengerBookingInfo;
