import * as React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useTheme } from '@presentation/theme/useTheme';
import * as classNames from 'classnames';

export function PageLayout({ children }) {
  const { main } = useTheme();

  return (
    <>
      <Header home={false} />
      {main.page && (
        <main className={classNames('flex-grow', main.page)}>{children}</main>
      )}
      <Footer />
    </>
  );
}
