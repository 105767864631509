import DetailsBreadcrumb from "./DetailsBreadcrumb";
// import DetailsImageSection from './DetailsImageSection';
import RoomsSection from "./RoomsSection";
import DescriptionSection from "./DescriptionSection";
import MapSection from "./MapSection";
import { FlightInfo } from "./FlightInfo";
import { HotelGallery } from "@presentation/pages/details/HotelGallery";

const CharterDetailsPage = ({ result, bookingUrl }) => {
  return (
    <>
      <HotelGallery hotel={result} />
      <section className="mx-auto my-3">
        <DetailsBreadcrumb hotel={result} />
        {/*<DetailsImageSection hotel={hotel} />*/}
        <FlightInfo charter={result} />
        <RoomsSection hotel={result} bookingUrl={bookingUrl} />
        <DescriptionSection hotel={result} />
        <MapSection hotel={result} />
      </section>
    </>
  );
};

export default CharterDetailsPage;
