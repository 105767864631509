import Result from "./Result";
import { useSearchRepository } from "../../shared/context/search-repository-context";
import { FullPageLoader } from "../../shared/components/loader/FullPageLoader";
import { useEffect, useMemo, useState } from "react";
import { Button } from "@presentation/shared/components/button/Button";
import { T } from "@config/lang";
import SelectInput from "@presentation/shared/components/select/SelectInput";

const PAGE_SIZE = 10;

const SORT_BY = {
  PRICE: "price",
};

const SORT_DIR = {
  ASC: -1,
  DESC: 1,
};

function ResultList({ results, allowBooking = true }) {
  const {
    current: { loadingResults },
  } = useSearchRepository();

  const [sortBy, setSortBy] = useState(SORT_BY.PRICE);
  const [sortDir, setSortDir] = useState(SORT_DIR.ASC);
  const sortOptions = useMemo(() => {
    return [
      {
        value: { by: SORT_BY.PRICE, dir: SORT_DIR.ASC },
        name: T.results.sort_by_price + " (" + T.results.low_to_high + ")",
      },
      {
        value: { by: SORT_BY.PRICE, dir: SORT_DIR.DESC },
        name: T.results.sort_by_price + " (" + T.results.high_to_low + ")",
      },
      {
        value: { by: SORT_BY.PRICE, dir: SORT_DIR.ASC },
        name: T.results.sort_by_category + " (" + T.results.low_to_high + ")",
      },
      {
        value: { by: SORT_BY.PRICE, dir: SORT_DIR.DESC },
        name: T.results.sort_by_category + " (" + T.results.high_to_low + ")",
      },
    ];
  }, []);
  const sort = useMemo(
    () =>
      sortOptions.find(
        (it) => it.value.by === sortBy && it.value.dir === sortDir
      ),
    [sortBy, sortDir, sortOptions]
  );
  const setSort = ({ by, dir }) => {
    setSortBy(by);
    setSortDir(dir);
  };

  const [page, setPage] = useState(0);
  const showMore = () => {
    setPage((p) => p + 1);
  };

  useEffect(() => {
    setPage(0);
  }, [results]);

  const paginated = useMemo(() => {
    return results.slice(0, PAGE_SIZE * (page + 1));
  }, [page, results]);

  return (
    <div className="w-full">
      {loadingResults ? (
        <FullPageLoader />
      ) : (
        <div>
          <div className="my-3 flex justify-between">
            <div className="px-3 py-2 focus:ring-indigo-500 w-full shadow-sm border rounded border-gray-300 focus:border-indigo-500 text-gray-500 text-left">
              {(results.length ?? 0) + " " + T.results.properties_found}
            </div>
            <div className="w-[300px] hidden">
              <SelectInput
                value={sort}
                onChange={setSort}
                options={sortOptions}
                placeholder={T.results.sort_by}
              />
            </div>
          </div>
          {paginated.map((result) => (
            <Result
              key={result.id}
              result={result}
              allowBooking={allowBooking}
            />
          ))}
          {!!results.length && paginated.length < results.length && (
            <div className="flex justify-center py-4 mb-10">
              <Button onClick={showMore} type="secondary">
                {T.results.show_more}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ResultList;
