import React from "react";
import FormField from "../../shared/components/form-field/FormField";
import TextInput from "../../shared/components/text-input/TextInput";
import { T } from "@config/lang";

function ContactBookingInfo({ value, field, errors }) {
  return (
    <div className="mt-6">
      <h6 className="py-2 font-bold text-lg">
        {T.booking.contact_information}
      </h6>
      <div className="border border-gray-200 bg-gray-50 p-6 pb-0">
        <div className="lg:grid grid-cols-7 gap-x-6">
          <FormField
            className="col-span-2"
            label={T.booking.email_address}
            error={errors.email}
            description={T.booking.confirmation_sent_here}
          >
            <TextInput
              value={value.email}
              onChange={field.email}
              required
              type="email"
              hasError={!!errors.email}
            />
          </FormField>

          <FormField
            className="col-span-3"
            label={T.booking.address}
            error={errors.address}
          >
            <TextInput
              value={value.address}
              onChange={field.address}
              required
              hasError={!!errors.email}
            />
          </FormField>

          <FormField
            className="col-span-2"
            label={T.booking.phone_number}
            error={errors.phone}
          >
            <TextInput
              value={value.phone}
              onChange={field.phone}
              type="tel"
              required
              hasError={!!errors.phone}
            />
          </FormField>
        </div>
      </div>
    </div>
  );
}

export default ContactBookingInfo;
