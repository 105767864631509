import { AiOutlineClose } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import classNames from 'classnames';

function Modal({
  title,
  children,
  onHide,
  hideOnClickOutside = false,
  footer,
  width = 'fixed',
  height = 'fixed',
  fullScreen = false,
  embedded = false,
}) {
  useEffect(() => {
    if (!embedded) {
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.documentElement.style.scrollBehavior = 'auto';

      return () => {
        document.body.style.position = '';
        window.scroll(0, scrollY);
        document.documentElement.style.scrollBehavior = 'smooth';
      };
    }
  }, [embedded]);

  return (
    <div
      className={classNames(
        'fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-40 flex justify-center items-center',
        { 'pb-12': !fullScreen }
      )}
      style={{ zIndex: 1000 }}
      onClick={() => hideOnClickOutside && onHide?.()}
    >
      <div
        className={classNames(
          'flex flex-col bg-white',
          { 'h-4/5': height === 'fixed' },
          fullScreen
            ? 'w-screen h-screen'
            : width === 'auto'
              ? 'rounded-lg shadow-lg'
              : 'w-full sm:w-4/5 md:w-4/6 lg:w-2/3 rounded-lg shadow-lg'
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-xl font-bold border-b border-gray-300 p-4 flex justify-between items-center">
          <div>{title}</div>
          <div
            className="text-gray-400 hover:text-gray-600 cursor-pointer"
            onClick={onHide}
          >
            <AiOutlineClose size={20} />
          </div>
        </div>
        <div className="p-4 flex flex-grow flex-col overflow-y-auto">
          {children}
        </div>
        {footer && (
          <div className="border-t border-gray-300 p-4 flex justify-between items-center">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  onHide: PropTypes.func,
  hideOnClickOutside: PropTypes.bool,
  footer: PropTypes.node,
  width: PropTypes.oneOf(['fixed', 'auto']),
  height: PropTypes.oneOf(['fixed', 'auto']),
  fullScreen: PropTypes.bool,
  embedded: PropTypes.bool,
};

export default Modal;
