import * as React from "react";
import Modal from "../../shared/components/modal/Modal";
import { priceEuro } from "../../shared/utils/price-format";
import { useSearchRepository } from "../../shared/context/search-repository-context";
import { useIsMobile } from "@presentation/shared/hooks/useIsMobile";
import { T } from "@config/lang";
import { PAGES, useIFrame } from "@presentation/shared/context/iframe-context";
import { Button } from "@presentation/shared/components/button/Button";

function MoreRooms({ charter, rooms, onHide, allowBooking }) {
  const { iframe, setPage } = useIFrame();
  const goToBooking = (id, accommodationId) => {
    setPage(PAGES.BOOKING, id, accommodationId);
  };

  const {
    current: { bookingUrl },
  } = useSearchRepository();

  const isMobile = useIsMobile();

  return (
    <Modal
      onHide={onHide}
      title="More rooms"
      fullScreen={isMobile}
      height={isMobile ? "auto" : "fixed"}
    >
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Room type</th>
            <th>Room Information</th>
            <th>Period</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {rooms.map((room) => {
            const days = Number.isInteger(room.real_days)
              ? room.real_days
              : room.days;

            return (
              <tr key={room.accommodation_id} className="odd:bg-gray-100">
                <td className="text-center p-3">
                  <img src={room.acc_image} className="h-24" alt="" />
                </td>
                <td className="text-center p-3 text-lg">{room.room_type}</td>
                <td className="text-center p-3">
                  <div>
                    Guests: {room.adl} adults, {room.chd} child(ren)
                  </div>
                  <div>{room.board}</div>
                </td>
                <td className="text-center p-3">
                  <div>Check in: {charter.checkin}</div>
                  <div>Check out: {charter.checkout}</div>
                  {days && (
                    <div className="whitespace-nowrap">
                      {charter.nights} {T.results.nights}
                      {/*{"(" +
                        days +
                        " " +
                        (days > 1 ? T.search.days : T.search.day) +
                        ")"}*/}
                    </div>
                  )}
                </td>
                <td className="text-center p-3">
                  {room.stop_sale === 1 && (
                    <div className="bg-red-800 text-white px-2 font-bold">
                      Stop sale
                    </div>
                  )}
                  <div>
                    {room.promotions > 0 && (
                      <del className="mr-2 whitespace-nowrap">
                        {priceEuro(room.price + room.promotions)}
                      </del>
                    )}
                    <span className="text-lg whitespace-nowrap">
                      {priceEuro(room.price)}
                    </span>
                  </div>
                  {allowBooking && (
                    <Button
                      to={
                        iframe
                          ? null
                          : bookingUrl(charter.id, room.accommodation_id)
                      }
                      onClick={
                        iframe
                          ? () => goToBooking(charter.id, room.accommodation_id)
                          : null
                      }
                      type="primary"
                    >
                      {T.results.book_now}
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Modal>
  );
}

export default MoreRooms;
