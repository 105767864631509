import * as en from "./en.json";
import * as sq from "./sq.json";
import * as mk from "./mk.json";

export enum LANGUAGE {
  EN = "en",
  SQ = "sq",
  MK = "mk",
}

export const LANGUAGE_NAMES = {
  [LANGUAGE.EN.toString()]: "English",
  [LANGUAGE.SQ.toString()]: "Albanian",
  [LANGUAGE.MK.toString()]: "Macedonian",
};

type Translation = Record<string, string | object>;
type Translations = Record<"sq" | "en" | "mk", Translation>;

export interface ILanguage {
  locale: string;
  name: string;
}

export const translations: Translations = {
  en,
  sq,
  mk,
};

// Global object used for easier use of translations
export const T = { ...en };
