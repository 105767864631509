import { useMemo } from "react";

const POSSIBLE_STAR_VALUES = [
  { value: 5, name: "five" },
  { value: 4, name: "four" },
  { value: 3, name: "three" },
  { value: 2, name: "two" },
  { value: 1, name: "one" },
];

export function useCalculatedPriceRange(results) {
  // Calculate minimum and maximum prices
  return useMemo(() => {
    if (results && results.length > 0) {
      const first = results[0];
      const priceRange = results.reduce(
        (prev, charter) => {
          return [
            Math.min(prev[0], charter.price),
            Math.max(prev[1], charter.price),
          ];
        },
        [first.price, first.price]
      );
      return [Math.floor(priceRange[0]), Math.ceil(priceRange[1])];
    }
    return null;
  }, [results]);
}

export function countStarsFilteredResults(results, values) {
  const counts = {};

  results?.forEach((result) => {
    counts[result.category] = (counts[result.category] || 0) + 1;
  });

  return POSSIBLE_STAR_VALUES.map((star) => ({
    ...star,
    count: counts[star.value] || 0,
    selected: values.includes(star.value),
  }));
}

export function countFacilitiesFilteredResults(
  filteredResults,
  allFacilityCategories,
  values
) {
  const counts = {};

  filteredResults?.forEach((result) => {
    result?.facilities?.forEach((facility) => {
      counts[facility] = (counts[facility] || 0) + 1;
    });
  });

  return allFacilityCategories.map((cat) => ({
    ...cat,
    facilities: cat.facilities.map((fac) => ({
      ...fac,
      count: counts[fac.id] || 0,
      selected: values.includes(fac.id),
    })),
  }));
}

export function countHighlightsFilteredResults(
  filteredResults,
  allHighlights,
  values
) {
  const counts = {};

  filteredResults?.forEach((result) => {
    result?.highlights?.forEach((highlight) => {
      counts[highlight] = (counts[highlight] || 0) + 1;
    });
  });

  return allHighlights.map((high) => ({
    ...high,
    count: counts[high.id] || 0,
    selected: values.includes(high.id),
  }));
}
