import * as React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { cx } from "@presentation/vendor/classnames";
import { useTheme } from "../../theme/useTheme";
import { AiOutlineGlobal } from "react-icons/ai";
import { useApp } from "@application/context/app-context";
import { Settings } from "@config/tenants/bootstrap";

function HeaderTopLanguages() {
  const { header } = useTheme();
  const { language, languageName, setLanguage } = useApp();

  return (
    <Menu>
      <Menu.Button
        className={cx(
          "whitespace-nowrap flex items-center mr-0",
          header.top.link
        )}
      >
        <AiOutlineGlobal className="text-lg mr-1" />
        {languageName}
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className={cx(header.top.language.container)}>
          {Settings.languages.map(({ locale, name }) => {
            const selected = locale === language;
            return (
              <Menu.Item key={locale} as={Fragment}>
                {({ close }) => (
                  <span
                    onClick={() => {
                      setLanguage(locale);
                      close();
                    }}
                    className={cx(
                      header.top.language.item,
                      selected
                        ? header.top.language.selected
                        : header.top.language.default
                    )}
                  >
                    {name}
                  </span>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default HeaderTopLanguages;
