import { useCallback, useMemo, useState } from "react";
import { useSearchRepository } from "@presentation/shared/context/search-repository-context";
import {
  filterByFacilities,
  filterByHighlights,
  filterByName,
  filterByPriceRange,
  filterByStarRating,
} from "@presentation/pages/search/hotel-filters";
import {
  countFacilitiesFilteredResults,
  countHighlightsFilteredResults,
  countStarsFilteredResults,
  useCalculatedPriceRange,
} from "@presentation/pages/search/filter-counts";
import { useCompany } from "@application/context/company-context";

export function useFiltering() {
  const {
    current: { results },
  } = useSearchRepository();

  const { facilities: allFacilityCategories, highlights: allHighlights } =
    useCompany();

  const priceRange = useCalculatedPriceRange(results);

  const [byName, setByName] = useState("");
  const [byPriceRange, setByPriceRange] = useState(null);
  const [byHighlights, setByHighlights] = useState([]);
  const [byFacilities, setByFacilities] = useState([]);
  const [byStars, setByStars] = useState([]);

  const { filteredResults, stars, facilityCategories, highlights } =
    useMemo(() => {
      if (!results || !allFacilityCategories || !allHighlights) {
        return {
          filteredResults: results,
          stars: [],
          facilityCategories: [],
          highlights: [],
        };
      }
      let filteredResults = results;
      if (filteredResults && filteredResults.length > 0) {
        filteredResults = filterByName(filteredResults, byName);
        filteredResults = filterByPriceRange(filteredResults, byPriceRange);
        filteredResults = filterByHighlights(filteredResults, byHighlights);
        filteredResults = filterByFacilities(filteredResults, byFacilities);
        filteredResults = filterByStarRating(filteredResults, byStars);
      }
      const stars = countStarsFilteredResults(results, byStars);
      const facilityCategories = countFacilitiesFilteredResults(
        filteredResults,
        allFacilityCategories,
        byFacilities
      );
      const highlights = countHighlightsFilteredResults(
        filteredResults,
        allHighlights,
        byHighlights
      );

      return {
        filteredResults,
        stars,
        facilityCategories,
        highlights,
      };
    }, [
      results,
      byName,
      byPriceRange,
      byHighlights,
      byFacilities,
      byStars,
      allFacilityCategories,
      allHighlights,
    ]);

  const toggleStar = useCallback(
    (option) => {
      setByStars(
        option.selected
          ? [...byStars.filter((it) => it !== option.value)]
          : [...byStars, option.value]
      );
    },
    [byStars]
  );

  const toggleHighlight = useCallback(
    (option) => {
      setByHighlights(
        option.selected
          ? [...byHighlights.filter((it) => it !== option.id)]
          : [...byHighlights, option.id]
      );
    },
    [byHighlights]
  );

  const toggleFacility = useCallback(
    (option) => {
      setByFacilities(
        option.selected
          ? [...byFacilities.filter((it) => it !== option.id)]
          : [...byFacilities, option.id]
      );
    },
    [byFacilities]
  );

  return {
    filteredResults,
    stars,
    facilityCategories,
    highlights,
    filters: {
      byName: { value: byName, setValue: setByName },
      byPriceRange: {
        value: byPriceRange,
        setValue: setByPriceRange,
        options: priceRange,
      },
      byHighlights: { toggle: toggleHighlight, options: highlights },
      byFacilities: { toggle: toggleFacility, options: facilityCategories },
      byStars: { toggle: toggleStar, options: stars },
    },
  };
}
