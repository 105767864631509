function comma(number) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

export function priceEuro(price, digits = 0) {
  return price ? comma(price.toFixed(digits)) + ' €' : '';
}

export function priceMKD(price, digits = 0) {
  return price ? comma(price.toFixed(digits)) + ' MKD' : '';
}
