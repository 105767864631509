import * as React from "react";
import { useTheme } from "../../../theme/useTheme";
import { Link } from "react-router-dom";
import { cx } from "@presentation/vendor/classnames";
import { ReactNode } from "react";
import { Argument } from "classnames";

interface ButtonProps {
  type?: "primary" | "secondary" | "outline";
  size?: "xs" | "sm" | "base" | "lg" | "xl";
  disabled?: boolean;

  onClick?(): void;

  to?: string;

  children: ReactNode;
  className?: Argument;
}

export function Button(props: ButtonProps): React.ReactElement {
  const { type = "primary", size = "base", className, ...rest } = props;
  const { button } = useTheme();
  if (rest.to) {
    return (
      <Link
        to={rest.to}
        className={cx(
          className,
          button.base,
          button[type][rest.disabled ? 1 : 0],
          button.size[size]
        )}
      >
        {rest.children}
      </Link>
    );
  }
  return (
    <button
      className={cx(
        className,
        button.base,
        button[type][rest.disabled ? 1 : 0],
        button.size[size]
      )}
      {...rest}
    />
  );
}
