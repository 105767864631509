import { useMemo } from "react";
import CharterDetailsPage from "@presentation/pages/details/CharterDetailsPage";
import { useSearchRepository } from "@presentation/shared/context/search-repository-context";
import { useIFrame } from "@presentation/shared/context/iframe-context";

export function Details() {
  const { hotelId } = useIFrame();
  const {
    current: { results, bookingUrl },
  } = useSearchRepository();
  const result = useMemo(
    () => results?.find((it) => it.id === hotelId),
    [results, hotelId]
  );

  return (
    <div className="mt-6">
      <CharterDetailsPage result={result} bookingUrl={bookingUrl} />
    </div>
  );
}
