import * as React from "react";
import { useUser } from "@agent/context/user-context";
import { Navbar } from "flowbite-react";
import { useCompany } from "@application/context/company-context";
import { T } from "@config/lang";
import { menu } from "@agent/menu";
import { NavLink, Outlet } from "react-router-dom";
import { cx } from "@presentation/vendor/classnames";
import { Settings } from "@config/tenants/bootstrap";

export function Main() {
  const logoClasses = Settings.theme.b2b?.logo;
  const { name, logo } = useCompany();
  const { user, logout } = useUser();
  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col text-sm">
      <Navbar fluid className="shadow shadow-blue-200">
        <Navbar.Brand href="/agent">
          <img src={logo} className={cx("h-8", logoClasses)} alt={name} />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <span>{user.name}</span>
          <Navbar.Link className="cursor-pointer" onClick={logout}>
            {T.logout}
          </Navbar.Link>
        </Navbar.Collapse>
      </Navbar>

      <div className="flex flex-grow mt-3">
        <aside className="w-fit">
          <div
            className="h-full overflow-y-auto overflow-x-hidden rounded bg-white py-4 px-3 dark:bg-gray-800"
            aria-label="Main navigation"
          >
            <div>
              {menu().map(({ items }, idx) => (
                <ul
                  key={idx}
                  className="mt-4 space-y-2 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700 w-60"
                >
                  {items
                    .filter((it) => it.Component)
                    .map(({ title, icon: Icon, to }) => (
                      <NavLink
                        key={to}
                        to={"/agent" + to}
                        end
                        className={({ isActive, isPending }) =>
                          cx(
                            "flex items-center text-base px-4 py-2 text-gray-800 rounded-md",
                            isPending
                              ? "text-gray-400"
                              : isActive
                              ? "bg-gray-100"
                              : "hover:bg-gray-100"
                          )
                        }
                      >
                        <Icon className="mr-2" size={20} /> {title}
                      </NavLink>
                    ))}
                </ul>
              ))}
            </div>
          </div>
        </aside>
        <main className="flex-grow px-6">
          <Outlet />
        </main>
      </div>
      <footer className="px-4 py-2 bg-black bg-opacity-20 flex justify-between">
        <div>&copy; 2023 {name}.</div>
        <div></div>
      </footer>
    </div>
  );
}
