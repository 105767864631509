import * as React from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "@presentation/shared/layout/PageLayout";
import { BookingConfirmation } from "@presentation/pages/confirmation/BookingConfirmation";

export function ConfirmationPage() {
  const { code } = useParams();

  return (
    <PageLayout>
      <section className="container mx-auto pt-6">
        <BookingConfirmation code={code} />
      </section>
    </PageLayout>
  );
}
