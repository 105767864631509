import FlightDetails from "./FlightDetails";

export function FlightInfo({ charter }) {
  if (!charter.depart_flight) {
    return null;
  }

  return (
    <div>
      <h6 className="pt-4 font-bold text-lg">Flight Information</h6>
      <div className="md:grid grid-cols-2 gap-4">
        <FlightDetails flight={charter.depart_flight} isDeparture={true} />
        <FlightDetails flight={charter.return_flight} isDeparture={false} />
      </div>
    </div>
  );
}
