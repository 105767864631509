import * as React from "react";
import { useRouteError } from "react-router-dom";

function NotFoundError(): React.ReactElement {
  // @ts-ignore
  const error: { statusText: string; message: string } = useRouteError();

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

export default NotFoundError;
