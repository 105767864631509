import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "../../shared/layout/PageLayout";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { FullPageLoader } from "../../shared/components/loader/FullPageLoader";
import { useApp } from "@application/context/app-context";
import { Contact } from "@presentation/pages/static/Contact";

function StaticPage() {
  const { page } = useParams();
  const { language } = useApp();
  const { request, loading, data } = useService(Requests.static);

  useEffect(() => {
    request({ page });
    // eslint-disable-next-line
  }, [language, page]);

  const { title, description = "" } = data?.[0] ?? {};

  return (
    <PageLayout>
      <div className="container mx-auto prose-lg py-4">
        {loading && <FullPageLoader />}
        <h2 className="font-display">{title}</h2>
        <div>
          {page === "contact" ? (
            <Contact description={description} />
          ) : (
            <div
              className="mb-20"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      </div>
    </PageLayout>
  );
}

export default StaticPage;
