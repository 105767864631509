import * as React from "react";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as classNames from "classnames";
// import { HiCheck } from "react-icons/hi";
import { RiArrowDownSLine, RiCloseLine } from "react-icons/ri";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { IconType } from "react-icons";
import { Argument } from "classnames";
import { cx } from "@presentation/vendor/classnames";

type OptionType = number | string | object;

interface SelectInputOption {
  id?: number | string;
  value?: OptionType;
  name: string;
  active?: boolean;
  disabled?: boolean;
  add?: string;
  type?: string;
}

interface SelectInputProps {
  loading?: boolean;

  onSearchText?(text: string): void;

  searchTextLength?: number;
  placeholder?: string;
  label?: string;
  value: OptionType | OptionType[];

  onChange(value: OptionType | OptionType[]): void;

  options: SelectInputOption[];
  className?: Argument;
  inputClassName?: Argument;
  multiple?: boolean;
  onlyActive?: boolean;
  autocomplete?: boolean;
  clearable?: boolean;
  maxSelect?: number;
  selectedLabel?: number;

  renderOption?(option: SelectInputOption): ReactNode;

  searchFields?: string[];
  disabled?: boolean;
  Icon?: IconType;
}

function SelectInput(props: SelectInputProps) {
  const {
    onSearchText = () => {},
    searchTextLength = 3,
    loading = false,
    options,
    value,
    onChange,
    placeholder = "",
    label,
    multiple = false,
    onlyActive = false,
    clearable = false,
    maxSelect = null,
    selectedLabel = "items selected",
    autocomplete = false,
    className,
    inputClassName,
    renderOption,
    searchFields = ["name"],
    disabled = false,
    Icon = null,
  } = props;
  const ref = useRef();
  const inputRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [delayedOpen, setDelayedOpen] = useState(false);
  const flatSearchFields = searchFields.join("||");
  const optionsWithIds = useMemo(() => {
    return (
      options
        ?.map((it) => ({
          ...it,
          value: it.value !== undefined ? it.value : it.id,
          search: flatSearchFields
            .split("||")
            .map((f) => (Array.isArray(it[f]) ? it[f].join("||") : it[f]))
            .join("||")
            .toLowerCase(),
        }))
        .filter((it) => !onlyActive || it.active) ?? []
    );
  }, [options, onlyActive, flatSearchFields]);

  const filteredOptions = useMemo(() => {
    if (!searchText?.length) {
      return optionsWithIds;
    }
    return optionsWithIds.filter((it) =>
      it.search.includes(searchText.toLowerCase())
    );
  }, [optionsWithIds, searchText]);

  useEffect(() => {
    if (open) {
      setDelayedOpen(true);
      // @ts-ignore
      inputRef?.current?.focus();
    } else {
      const timeout = setTimeout(() => {
        setDelayedOpen(false);
      }, 200);
      return () => clearTimeout(timeout);
    }
  }, [open]);

  useOnClickOutside(ref, () => setOpen(false));

  const selected = optionsWithIds.find((it) => it.value === value);
  const toggleSelection = useCallback(
    (val, e = null) => {
      e?.stopPropagation();
      if (multiple) {
        if (e) setOpen(false);
        const isSelected = (value as OptionType[])?.includes(val);
        onChange(
          isSelected
            ? (value as OptionType[])?.filter((it) => it !== val)
            : [...(value as OptionType[]), val]
        );
      } else {
        onChange(val);
      }
      setSearchText("");
      setOpen(false);
    },
    [multiple, value, onChange]
  );

  const clearSelection = useCallback(
    (e) => {
      e.stopPropagation();
      onChange(multiple ? [] : null);
      setOpen(false);
      setSearchText("");
    },
    [multiple, onChange]
  );

  const canSelectMore = useMemo(
    () =>
      !multiple ||
      (maxSelect ? (value as OptionType[])?.length < maxSelect : true),
    [multiple, value, maxSelect]
  );

  useEffect(() => {
    onSearchText(
      searchText.length >= searchTextLength ? searchText.toLowerCase() : ""
    );
  }, [searchText, searchTextLength, onSearchText]);

  const hasSelection = multiple
    ? (value as OptionType[])?.length > 0
    : !!selected;
  const valueText = multiple
    ? (value as OptionType[])?.length > 0
      ? (value as OptionType[]).length + " " + selectedLabel
      : undefined
    : selected?.name;
  const placeholderText =
    autocomplete && open ? "Type to search..." : valueText ?? placeholder;

  return (
    <div className={classNames("w-full relative", className)} ref={ref}>
      <div className="relative">
        <div
          onClick={() => setOpen((o) => !disabled && !o)}
          className="relative cursor-default"
        >
          {autocomplete ? (
            <div>
              {Icon && (
                <span
                  className={classNames(
                    "absolute mx-3 my-2",
                    open ? "text-gray-700" : "text-gray-400",
                    { "pt-1.5": label }
                  )}
                >
                  <Icon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
              {label && (
                <span
                  className={classNames(
                    "absolute top-1 left-10 text-xs text-gray-400"
                  )}
                >
                  {label}
                </span>
              )}
              <input
                disabled={disabled}
                className={classNames(
                  inputClassName,
                  "py-2 px-3 w-full text-left bg-white border border-gray-300 rounded flex-grow",
                  "shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white",
                  "focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 md:text-base sm:text-sm",
                  "placeholder:mr-10 placeholder:overflow-ellipsis",
                  hasSelection && !open
                    ? "placeholder:text-gray-800"
                    : "placeholder:text-gray-400",
                  {
                    "cursor-pointer": !autocomplete,
                    "bg-gray-200": disabled,
                    "pl-10": Icon,
                    "pt-5 pb-1": label,
                  }
                )}
                ref={inputRef}
                placeholder={placeholderText}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          ) : (
            <div
              className={classNames(
                "py-2 pr-5 w-full text-left bg-white border border-gray-300 rounded cursor-pointer inline-flex items-center",
                "shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white",
                "focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm md:text-base",
                hasSelection ? "text-gray-800" : "text-gray-400",
                { "bg-gray-200": disabled, "pt-5 pb-1": label },
                Icon ? "pl-10" : "pl-3",
                inputClassName
              )}
            >
              {multiple && !autocomplete && hasSelection ? (
                <div className={cx("flex flex-wrap -mb-2 -mt-1")}>
                  {optionsWithIds
                    .filter((it) =>
                      (value as OptionType[])?.includes?.(it.value)
                    )
                    .map(({ id, name, value }) => (
                      <span
                        key={id}
                        className="rounded-full bg-gray-100 mr-2 py-1 px-2 truncate whitespace-nowrap mb-1 inline-flex justify-between items-center"
                      >
                        <span className="ellipsis">{name}</span>
                        <span
                          onClick={(e) => toggleSelection(value, e)}
                          className="rounded-full bg-gray-300 hover:bg-gray-500 text-gray-500 hover:text-white cursor-pointer inline-flex justify-center items-center w-4 h-4 ml-1"
                        >
                          <RiCloseLine />
                        </span>
                      </span>
                    ))}
                </div>
              ) : (
                <span className="w-full truncate">{placeholderText}</span>
              )}
              {Icon && (
                <span
                  className={classNames(
                    "absolute left-0 inset-y-0 mx-3 my-2",
                    open ? "text-gray-700" : "text-gray-400",
                    { "pt-1.5": label }
                  )}
                >
                  <Icon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
              {label && (
                <span
                  className={classNames(
                    "absolute top-0.5 left-10 text-xs text-gray-400"
                  )}
                >
                  {label}
                </span>
              )}
            </div>
          )}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2">
            <RiArrowDownSLine
              className={classNames(
                "w-5 h-5 text-gray-400 hover:text-gray-600 transition-transform ease-in-out duration-200 transform",
                open ? "rotate-180" : "rotate-0"
              )}
              aria-hidden="true"
            />
          </span>
          {clearable &&
            (multiple
              ? (value as OptionType[]).length > 0 && autocomplete
              : selected) && (
              <div
                className="absolute inset-y-0 right-5 flex items-center pr-2"
                onClick={clearSelection}
              >
                <RiCloseLine
                  className={classNames(
                    "w-5 h-5 text-gray-400 hover:text-gray-600"
                  )}
                />
              </div>
            )}
        </div>
        <div
          key="animated-select"
          className={classNames(
            "absolute w-full z-50 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm md:text-base",
            "transition-opacity ease-linear duration-200",
            open ? "opacity-100" : "opacity-0"
          )}
        >
          {delayedOpen &&
            (loading ? (
              <div className="flex justify-center items-center">Loading...</div>
            ) : filteredOptions.length === 0 ? (
              <div className="flex justify-center items-center">No data</div>
            ) : (
              disabled ||
              filteredOptions.map((option) => {
                const active =
                  option.value === value ||
                  (multiple &&
                    (value as OptionType[])?.includes?.(option.value));
                const disable = option.disabled || (!active && !canSelectMore);
                const onClick = () => {
                  if (disable) return;
                  toggleSelection(option.value);
                };
                if (renderOption) {
                  return (
                    <div
                      className={classNames(
                        "select-none relative py-2 px-4",
                        active
                          ? "cursor-pointer text-blue-900"
                          : disable
                          ? "cursor-default text-gray-400"
                          : "cursor-pointer text-gray-900"
                      )}
                      key={option.id}
                      onClick={onClick}
                    >
                      {renderOption(option)}
                    </div>
                  );
                }

                return (
                  <div
                    key={option.id}
                    className={classNames(
                      "select-none relative py-2 px-4",
                      active
                        ? "cursor-pointer text-blue-900"
                        : disable
                        ? "cursor-default text-gray-400"
                        : "cursor-pointer text-gray-900"
                    )}
                    onClick={onClick}
                  >
                    <span
                      className={classNames(
                        "block truncate",
                        active ? "font-bold" : "font-normal"
                      )}
                    >
                      {option.name}
                    </span>
                  </div>
                );
              })
            ))}
        </div>
      </div>
    </div>
  );
}

export default SelectInput;
