import * as React from 'react';
import CustomCheckbox from './CustomCheckbox';

const Highlights = ({ options, toggle }) => {
  return (
    <div className="pt-4">
      <h6 className="text-lg border-b border-gray-300">Highlights</h6>
      <div className="collection-brand-filter">
        {options.map((highlight) => (
          <CustomCheckbox
            key={highlight.id}
            option={highlight}
            toggle={toggle}
          />
        ))}
      </div>
    </div>
  );
};

export default Highlights;
