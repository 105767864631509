import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { useEffect, useMemo, useState } from "react";
import { Settings } from "@config/tenants/settings.default";
import * as moment from "moment/moment";
import { SEARCH_TYPES } from "@config/const/enums";

const DATE_FORMAT = "YYYY-MM-DD";

type SearchOption = {
  id: string;
  name: string;
};

export interface UseSearchReturnType {
  searchType: string;
  loading: boolean;
  departures: SearchOption[];
  departureId: string;
  destinations: SearchOption[];
  destinationId: string;
  returnType: string;
  returnId: string;
  departureDates: string[] | null;
  departDate: string;
  returnDates: string[] | null;
  returnDate: string;
  extendLastNight: boolean;
  adults: number;
  children: number[];
  isValid: boolean;

  setDepartureId(id: string): void;

  setDestinationId(id: string): void;

  setDepartDate(date: string): void;

  setExtendLastNight(extended: boolean): void;

  setReturnDate(date: string): void;

  setAdults(adults: number): void;

  setChildren(children: number[]): void;
}

export function useSearch(searchType: string): UseSearchReturnType {
  const [departureId, setDepartureId] = useState(null);
  const [destinationId, setDestinationId] = useState(null);
  const [returnType, returnId] = useMemo(
    () => destinationId?.split("/") ?? [null, null],
    [destinationId]
  );
  const [departDate, setDepartDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [extendLastNight, setExtendLastNight] = useState<boolean>(false);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState([]);

  useEffect(() => {
    setDestinationId(null);
    setDepartDate(null);
    setReturnDate(null);
  }, [searchType]);

  /**
   *  Departures
   */
  const {
    loading: loadingDepartures,
    data: departures,
    request: loadDepartures,
  } = useService(Requests.departures, false);
  useEffect(() => {
    if (Settings.search[searchType].displayDepartures) {
      loadDepartures({ searchType });
    }
  }, [searchType]);
  useEffect(() => {
    if (departures?.length && !departureId) {
      setDepartureId(
        departures.find((it) => it.default)?.id ?? departures[0].id
      );
    }
  }, [departureId, departures]);

  /**
   *  Destinations
   */
  const {
    loading: loadingDestinations,
    data: destinationData,
    requestAsync: loadDestinations,
  } = useService(Requests.destinations);

  const destinations = useMemo(
    () =>
      destinationData?.map((it) => ({
        ...it,
        id: it.type + "/" + it.id,
      })),
    [destinationData]
  );

  useEffect(() => {
    if (departureId !== null || searchType !== SEARCH_TYPES.charter) {
      loadDestinations({ searchType, departureId }).then(() => {
        setDestinationId(null);
        setDepartDate(null);
        setReturnDate(null);
      });
    }
  }, [departureId, loadDestinations, searchType]);

  /**
   * Departure date
   */
  const {
    loading: loadingDepartureDates,
    data: charterDepartureDates,
    requestAsync: loadDepartureDates,
  } = useService(Requests.departureDates);

  useEffect(() => {
    if (searchType === "charter" && departureId && returnType && returnId) {
      loadDepartureDates({
        searchType,
        departureId,
        returnType,
        returnId,
      }).then(() => {
        setDepartDate(null);
        setReturnDate(null);
      });
    } else {
      setReturnDate(null);
    }
  }, [searchType, departureId, returnType, returnId, loadDepartureDates]);

  const departureDates = useMemo(() => {
    if (searchType === "charter") {
      return charterDepartureDates;
    }
    const { minBookingStartDays, maxBookingStartDays } =
      Settings.search[searchType];
    const dates = [];
    for (let i = minBookingStartDays; i <= maxBookingStartDays; i++) {
      dates.push(moment.utc().add(i, "days").format(DATE_FORMAT));
    }
    return dates;
  }, [searchType, charterDepartureDates]);

  /**
   * Return dates
   */
  const {
    loading: loadingReturnDates,
    data: charterReturnDates,
    requestAsync: loadReturnDates,
  } = useService(Requests.returnDates);

  useEffect(() => {
    if (
      searchType === "charter" &&
      departureId &&
      returnType &&
      returnId &&
      departDate
    ) {
      loadReturnDates({
        searchType,
        departureId,
        returnType,
        returnId,
        departureDate: departDate,
      }).then(() => {
        setReturnDate(null);
      });
    }
  }, [
    searchType,
    departureId,
    returnType,
    returnId,
    departDate,
    loadReturnDates,
  ]);

  const returnDates = useMemo(() => {
    if (searchType === "charter") {
      return charterReturnDates;
    }
    const dates = [];
    if (departDate) {
      const { minBookingDurationDays, maxBookingDurationDays } =
        Settings.search[searchType];
      const departure = moment.utc(departDate);
      for (let i = minBookingDurationDays; i <= maxBookingDurationDays; i++) {
        dates.push(departure.clone().add(i, "days").format(DATE_FORMAT));
      }
    }
    return dates;
  }, [searchType, departDate, charterReturnDates]);

  /**
   * Are options valid for search
   */
  const isValid = useMemo(() => {
    return Boolean(
      searchType &&
        destinationId &&
        returnType &&
        returnId &&
        departDate &&
        returnDate &&
        adults
    );
  }, [
    searchType,
    adults,
    departDate,
    destinationId,
    returnDate,
    returnId,
    returnType,
  ]);

  const loading =
    loadingDepartures ||
    loadingDestinations ||
    loadingDepartureDates ||
    loadingReturnDates;
  return {
    searchType,
    loading,
    departures,
    departureId,
    setDepartureId,
    destinations,
    destinationId,
    setDestinationId,
    returnType,
    returnId,
    departureDates,
    departDate,
    setDepartDate,
    returnDates,
    returnDate,
    setReturnDate,
    adults,
    setAdults,
    children,
    setChildren,
    extendLastNight,
    setExtendLastNight,
    isValid,
  };
}
