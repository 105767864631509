import { useMemo, useState } from "react";
import * as moment from "moment";
import { Settings } from "../../../config/tenants/settings";
import { PaymentTypeEnum } from "@application/types/PaymentType.enum";
import { T } from "@config/lang";

const REQUIRE_PASS_NO = Settings.booking.passengers.require_passport_no;

type PassengerInfo = {
  firstName: string;
  lastName: string;
  gender: string | null;
  birthDate: string | null;
  passportNo: string;
  passportExpireDate: string | null;
  maxAge: number | null;
  type: "ADL" | "CHD";
};

type ContactInfo = {
  email: string;
  address: string;
  phone: string;
  type: "ADL";
};

type BookingForm = {
  passengers: PassengerInfo[];
  contact: ContactInfo;
  agreeTerms: boolean;
  demand: string;
  paymentMethod: PaymentTypeEnum;
};

const contactInfo: ContactInfo = {
  email: "",
  address: "",
  phone: "",
  type: "ADL",
};

const passengerInfo: PassengerInfo = {
  firstName: "",
  lastName: "",
  gender: null,
  birthDate: null,
  passportNo: "",
  passportExpireDate: null,
  maxAge: null,
  type: "ADL",
};

function createForm(adults = 0, children = []): BookingForm {
  const passengers: PassengerInfo[] = [];

  for (let i = adults; i > 0; i--) {
    passengers.push({ ...passengerInfo });
  }

  [...children]
    .sort((a, b) => (a < b ? -1 : 1))
    .forEach((maxAge) =>
      passengers.push({
        ...passengerInfo,
        maxAge,
        type: "CHD",
      })
    );

  return {
    passengers,
    contact: {
      ...contactInfo,
    },
    agreeTerms: false,
    demand: "",
    paymentMethod: PaymentTypeEnum.Cash,
  };
}

function recursiveChange(obj, path: Array<string | number>, val) {
  if (!path.length) {
    return val;
  }
  const [segment, ...segments] = path;

  if (typeof segment === "number") {
    return obj.map((o, i) =>
      i !== segment ? o : recursiveChange(o, segments, val)
    );
  }

  return {
    ...obj,
    [segment]: recursiveChange(obj[segment], segments, val),
  };
}

export function useBookingForm({ adults, children, departureDate }) {
  const [values, setValues] = useState(() => createForm(adults, children));

  const fields = useMemo(() => {
    const changeField = (
      segments: Array<string | number>,
      value: string | boolean | number | null
    ) => {
      setValues((values) => recursiveChange(values, segments, value));
    };

    const psngIdx = Array.from(new Array(adults + children.length).keys());

    return {
      passengers: psngIdx.map((idx) =>
        Object.fromEntries(
          Object.keys(passengerInfo).map((key) => [
            key,
            (val) => changeField(["passengers", idx, key], val),
          ])
        )
      ),
      contact: Object.fromEntries(
        Object.keys(contactInfo).map((key) => {
          return [key, (val) => changeField(["contact", key], val)];
        })
      ),
      agreeTerms: (val) => changeField(["agreeTerms"], val),
      demand: (val) => changeField(["demand"], val),
      paymentMethod: (val) => changeField(["paymentMethod"], val),
    };
  }, [adults, children.length]);

  const { isValid, errors } = useMemo(() => {
    const psgnErrors = values.passengers.map((passenger) => {
      const psngErrors: { [key: string]: string } = {};
      if (!passenger.gender) {
        psngErrors.gender = "Please select gender";
      }
      if (!passenger.firstName) {
        psngErrors.firstName = "First Name is required";
      }
      if (!passenger.lastName) {
        psngErrors.lastName = "Last Name is required";
      }
      if (passenger.maxAge) {
        if (!passenger.birthDate) {
          psngErrors.birthDate = "Birth Date is required";
        } else {
          // Check the date of birth if it matches the maximum age
          const birthDate = moment.utc(passenger.birthDate);
          const checkinDate = moment.utc(departureDate);
          const age = checkinDate.diff(birthDate, "years");
          if (age > passenger.maxAge) {
            psngErrors.birthDate = `Age must not exceed ${passenger.maxAge} years`;
          }
        }
      }

      if (REQUIRE_PASS_NO && !passenger.passportNo) {
        psngErrors.passportNo = "Passport Number is required";
      }

      /*
      if (!passenger.passportExpireDate) {
      psngErrors.passportExpireDate = 'Passport Expiration Date is required';
    } else {
      const passportExpireDate = moment.utc(passenger.passportExpireDate);
      const returnDate = moment.utc(params.returnDate);
      const expiresInMonths = passportExpireDate.diff(returnDate, 'months');
      if (expiresInMonths < 1) {
        psngErrors.passportExpireDate = `Passport Expires before return`;
      }
    }
    */

      return Object.keys(psngErrors).length ? psngErrors : null;
    });

    const isValidPsng = !psgnErrors.some((it) => it);

    const contactErrors: { [key: string]: string } = {};
    if (!values.contact.email) {
      contactErrors.email = T.booking.email_required;
    }
    if (!values.contact.address) {
      contactErrors.address = T.booking.address_required;
    }
    if (!values.contact.phone) {
      contactErrors.phone = T.booking.phone_required;
    }
    const isValidContact = Object.keys(contactErrors).length === 0;

    return {
      isValid: isValidPsng && isValidContact,
      errors: {
        passenger: psgnErrors,
        contact: contactErrors,
      },
    };
  }, [values, departureDate]);

  return {
    values,
    fields,
    isValid,
    errors,
  };
}
