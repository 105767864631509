import { PageLayout } from "../../shared/layout/PageLayout";
import { useSearchRepository } from "../../shared/context/search-repository-context";
import { useEffect, useMemo, useRef } from "react";
import SearchBox from "../../shared/components/search-box/SearchBox";
import { cx } from "@presentation/vendor/classnames";
import * as React from "react";
import { useTheme } from "@presentation/theme/useTheme";
import { HotelGallery } from "@presentation/pages/details/HotelGallery";
import DetailsBreadcrumb from "@presentation/pages/details/DetailsBreadcrumb";
import { FlightInfo } from "@presentation/pages/details/FlightInfo";
import RoomsSection from "@presentation/pages/details/RoomsSection";
import DescriptionSection from "@presentation/pages/details/DescriptionSection";
import MapSection from "@presentation/pages/details/MapSection";

function DetailsPage() {
  const { searchBox } = useTheme();
  const {
    current: {
      results,
      bookingUrl,
      hotelId,
      canSearch,
      performSearch,
      loadingResults,
    },
  } = useSearchRepository();

  const result = useMemo(
    () => results?.find((it) => it.id === hotelId),
    [results, hotelId]
  );

  const loaded = useRef(false);
  useEffect(() => {
    if (!loaded.current && canSearch && !results?.length && !loadingResults) {
      loaded.current = true;
      performSearch();
    }
  }, [canSearch, results, performSearch, loadingResults, loaded]);

  return (
    <PageLayout>
      <div className={cx("py-4", searchBox.internal.container)}>
        <div className="container mx-auto">
          <SearchBox />
        </div>
      </div>
      {result && (
        <div className="container mx-auto mt-6">
          <HotelGallery hotel={result} />
          <section className="mx-auto my-3">
            <DetailsBreadcrumb hotel={result} />
            {/*<DetailsImageSection hotel={hotel} />*/}
            <FlightInfo charter={result} />
            <RoomsSection hotel={result} bookingUrl={bookingUrl} />
            <DescriptionSection hotel={result} />
            <MapSection hotel={result} />
          </section>
        </div>
      )}
    </PageLayout>
  );
}

export default DetailsPage;
