import { useState } from "react";
import { Button } from "@presentation/shared/components/button/Button";
import { FiCheckSquare, FiSquare } from "react-icons/fi";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { FullPageLoader } from "@presentation/shared/components/loader/FullPageLoader";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";

const questions = [
  {
    question: "Каде се наоѓа ETC TRAVEL?",
    choices: ["Ул. Македонија", "Ул. Мајка Тереза", "Ул. Даме Груев"],
  },
  {
    question: "За кои дестинации имаме чартери?",
    choices: [
      "Бодрум, Анталија, Хургада",
      "Барселона, Неапол, Сплит",
      "Дубаи, Фетие, Малага",
    ],
  },
];

export function RegistrationForm() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(questions.map(() => null));

  const { question, choices } = questions?.[currentQuestion] ?? {};

  const chooseAnswer = (q, ch) => {
    setAnswers(answers.map((a, i) => (i === q ? ch : a)));
  };

  const onSubmitAnswer = () => {
    if (answers[currentQuestion] !== null) {
      setCurrentQuestion((q) => q + 1);
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const isValidForm = Boolean(firstName && lastName);

  const [liked, setLiked] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { loading, requestAsync } = useService(Requests.saveGiveAway);
  const onSubmit = () => {
    const quiz = questions.map(({ question, choices }, i) => {
      return {
        question,
        questionNr: i + 1,
        answer: choices[answers[i]],
        answerNr: answers[i] + 1,
      };
    });
    const payload = {
      first_name: firstName,
      last_name: lastName,
      phone,
      quiz,
    };
    requestAsync({ payload }).then(() => {
      setSubmitted(true);
    });
  };

  return (
    <div className="bg-white w-full md:w-auto md:rounded-3xl flex flex-col">
      {loading && <FullPageLoader />}

      {currentQuestion < questions.length ? (
        <div className="px-10 py-6">
          <div className="text-3xl">Одговорете на следните прашања</div>
          <div>
            <div className="text-xl font-bold pt-6 pb-3">
              {currentQuestion + 1}. {question}
            </div>
            {choices.map((choice, idx) => {
              const isChecked = answers[currentQuestion] === idx;
              return (
                <div
                  className="my-3 text-lg flex items-center cursor-pointer"
                  key={idx}
                  onClick={() => chooseAnswer(currentQuestion, idx)}
                >
                  <span className="mr-4">
                    {isChecked ? <FiCheckSquare /> : <FiSquare />}
                  </span>
                  <span>{choice}</span>
                </div>
              );
            })}
          </div>
          <div className="py-6 text-right">
            <Button
              onClick={onSubmitAnswer}
              disabled={answers[currentQuestion] === null}
            >
              Следно
            </Button>
          </div>
        </div>
      ) : !liked ? (
        <div className="px-10 py-6">
          <div className="text-2xl mb-6">Заследете нè на:</div>
          <a
            href="https://www.facebook.com/www.etc.mk"
            className="flex items-center my-3 font-lg hover:text-blue-500 cursor-pointer"
            target="_blank"
          >
            <span className="text-[#0866FF] text-xl mr-3">
              <FaFacebookSquare />
            </span>

            <span>Facebook</span>
          </a>

          <a
            href="https://www.instagram.com/etctravel/"
            className="flex items-center my-3 font-lg hover:text-blue-500"
            target="_blank"
          >
            <span className="text-black text-xl mr-3">
              <FaInstagramSquare />
            </span>

            <span>Instagram</span>
          </a>

          <div className="text-right pt-4">
            <Button onClick={() => setLiked(true)}>Потврди</Button>
          </div>
        </div>
      ) : !submitted ? (
        <div className="px-10 py-6">
          <div className="text-2xl mb-6">Внесете детали за контакт</div>
          <div className="md:table">
            <div className="md:table-row">
              <label
                className="text-right table-cell pr-4 whitespace-nowrap"
                htmlFor="firstName"
              >
                Име *
              </label>
              <input
                className="md:table-cell min-w-[300px] my-3"
                name="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="md:table-row">
              <label
                className="text-right table-cell pr-4 whitespace-nowrap"
                htmlFor="lastName"
              >
                Презиме *
              </label>
              <input
                className="md:table-cell min-w-[300px] my-3"
                name="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="md:table-row">
              <label
                className="text-right table-cell pr-4 whitespace-nowrap"
                htmlFor="firstName"
              >
                Телефон за котакт *
              </label>
              <input
                className="md:table-cell min-w-[300px] my-3"
                name="phone"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="md:table-row">
              <div className="md:table-cell" />
              <div className="md:table-cell text-right pt-4">
                <Button onClick={onSubmit} disabled={!isValidForm}>
                  Потврди
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="px-10 py-6">
          <div className="text-2xl mb-6">
            Вашата апликација е успешно регистрирана!
          </div>
          <div className="table-cell text-right">
            <Button to="/">Продолжете кон веб страницата</Button>
          </div>
        </div>
      )}
    </div>
  );
}
