import PropTypes from "prop-types";
import classNames from "classnames";

function RadioInput({
  className,
  name,
  options,
  value,
  onChange,
  horizontal = false,
}) {
  const classes = classNames(
    "mt-1 block w-full ",
    { "flex-row": horizontal },
    { "flex-col": !horizontal },
    className
  );

  return (
    <div className={classes}>
      {options.map((option, idx) => (
        <div
          key={option.value}
          className={classNames("items-center", {
            "inline-flex mr-8": horizontal,
            "mb-1": !horizontal,
          })}
        >
          <input
            id={name + idx}
            type="radio"
            name={name}
            className="mr-3 shadow-sm"
            checked={value === option.value}
            onChange={() => onChange(option.value)}
          />
          <label
            htmlFor={name + idx}
            className="cursor-pointer"
            onClick={() => onChange(option.value)}
          >
            {option.name}
          </label>
        </div>
      ))}
    </div>
  );
}

RadioInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  horizontal: PropTypes.bool,
};

export default RadioInput;
