import { priceEuro } from "../../shared/utils/price-format";
import { Button } from "../../shared/components/button/Button";
import { PAGES, useIFrame } from "@presentation/shared/context/iframe-context";
import { T } from "@config/lang";

const RoomsSection = ({ hotel, bookingUrl }) => {
  const { iframe, setPage } = useIFrame();
  const goToBooking = (id, accommodationId) => {
    setPage(PAGES.BOOKING, id, accommodationId);
  };

  return (
    <div className="w-full mb-6">
      <h4 className="text-xl font-bold">Choose a room</h4>

      <table className="w-full table table-auto">
        <thead>
          <tr>
            <th className="text-gray-500 text-left font-normal">Image</th>
            <th className="text-gray-500 text-left font-normal">Room</th>
            <th className="text-gray-500 text-left font-normal">Period</th>
            <th className="text-gray-500 text-left font-normal">Price</th>
          </tr>
        </thead>
        <tbody>
          {hotel.room_details.map((room) => (
            <tr key={room.accommodation_id}>
              <td className="py-4 px-2 bg-blue-50 border-t-4 border-white">
                <div className="w-40 h-24 flex justify-center items-center overflow-hidden">
                  {room.acc_image && (
                    <img src={room.acc_image} className="w-full" alt="Room" />
                  )}
                </div>
              </td>
              <td className="py-4 px-2 bg-blue-50 border-t-4 border-white">
                <div>
                  {room.room} {room.room_type}
                </div>
                <div>
                  Guests: {room.adl} {room.adl > 1 ? "adults" : "adult"},{" "}
                  {room.chd === 0
                    ? "No children"
                    : room.chd === 1
                    ? "1 child"
                    : room.chd + " children"}
                </div>
                <div>{room.board}</div>
              </td>
              <td className="py-4 px-2 bg-blue-50 border-t-4 border-white">
                <div>Check in: {hotel.checkin}</div>
                <div>Check out: {hotel.checkout}</div>
                <div>{room.days} nights</div>
              </td>
              <td className="py-4 px-2 bg-blue-50 border-t-4 border-white">
                <div className="flex flex-col justify-center items-center">
                  {room.stop_sale === 1 && (
                    <div className="bg-red-800 text-white px-2 font-bold">
                      Stop sale
                    </div>
                  )}
                  <div className="flex flex-col">
                    {hotel.promotions > 0 && (
                      <del className="text-sm whitespace-nowrap">
                        {priceEuro(room.price + room.promotions)}
                      </del>
                    )}
                    <span className="text-lg font-bold whitespace-nowrap">
                      {priceEuro(room.price)}
                    </span>
                  </div>
                  {iframe ? (
                    <Button type="primary">{T.results.book_now}</Button>
                  ) : (
                    <Button
                      type="primary"
                      to={
                        iframe
                          ? null
                          : bookingUrl(hotel.id, room.accommodation_id)
                      }
                      onClick={
                        iframe
                          ? () => goToBooking(hotel.id, room.accommodation_id)
                          : null
                      }
                    >
                      {T.results.book_now}
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RoomsSection;
