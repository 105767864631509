import * as React from 'react';
import FooterMenu from './FooterMenu';
import FooterBottom from './FooterBottom';
import { useTheme } from '@presentation/theme/useTheme';

function Footer() {
  const { footer } = useTheme();

  if (footer.hide) {
    return null;
  }

  return (
    <footer>
      <FooterMenu />
      <FooterBottom />
    </footer>
  );
}

export default Footer;
