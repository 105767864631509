import { T } from "@config/lang";
import { cx } from "@presentation/vendor/classnames";
import { PaymentTypeEnum } from "@application/types/PaymentType.enum";
import { CCPayment } from "@presentation/pages/confirmation/CCPayment";
import * as React from "react";
import { useTheme } from "@presentation/theme/useTheme";
import { useCompany } from "@application/context/company-context";
import { priceEuro, priceMKD } from "@presentation/shared/utils/price-format";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { Settings } from "@config/tenants/settings.default";

function FlightDetails({ flight }) {
  return (
    <div className="my-3 rounded-lg bg-blue-50 p-4">
      <div className="media-body col-lg-2 col-sm-6">
        <h6 className="media-heading">{flight.origin}</h6>
        <p>{flight.code}</p>
      </div>
      <div className="media-body col-lg-2 col-sm-6">
        <h6>From: {flight.from}</h6>
      </div>
      <div className="media-body col-lg-2 col-sm-6">
        <h6>To: {flight.to}</h6>
      </div>
      <div className="media-body col-lg-2 col-sm-6">
        <h6>Flight Date: {flight.flight_date}</h6>
      </div>
      <div className="media-body col-lg-2 col-sm-6">
        <h6>Departure: {flight.departure}</h6>
      </div>
      <div className="media-body col-lg-2 col-sm-6">
        <h6>Arrival: {flight.arrival}</h6>
      </div>
    </div>
  );
}

type Props = {
  code: string;
};

export function BookingConfirmation({ code }: Props) {
  const { button } = useTheme();
  const { currency } = useCompany();
  const currencyFormat =
    currency.toUpperCase() === "MKD" ? priceMKD : priceEuro;

  const { loading, data: booking } = useService(Requests.getBooking, true, {
    code,
  });

  const invoiceUrl =
    Settings.endpoint +
    "/pdf-files/pro-invoices/ProInvoice-" +
    booking?.code +
    ".pdf";

  if (loading) {
    return <div>Loading...</div>;
  }

  const { hotel, depart, return: returnFlight } = booking;
  const isCharter = !!depart;
  const ccPaymentSuccessUrl = window.location.href;

  return (
    <div>
      <h2 className="text-2xl font-bold text-center">{T.booking.success}</h2>
      <div className="flex justify-between mt-6">
        <p>Reservation code: {booking.code}!</p>
        <a
          href={invoiceUrl}
          className={cx(button.base, button.primary[0], button.size.base)}
          target="_blank"
          download
          rel="noreferrer"
        >
          Download invoice
        </a>
      </div>
      <div className="my-6">
        <h6 className="text-lg font-bold border-b border-b-blue-200">
          Hotel Information
        </h6>
        <div className="mb-3 mt-1 rounded-lg bg-blue-50 p-4">
          <div className="media row">
            <div className="media-body col-lg-3 col-sm-6">{hotel.name}</div>
            <div className="media-body col-lg-3 col-sm-6">
              <h6 className="media-heading">{hotel.accommodation.room}</h6>
              <p>{hotel.accommodation.board}</p>
            </div>
            <div className="media-body col-lg-3 col-sm-6">
              <h6 className="media-heading">
                {hotel.from_date} - {hotel.to_date}
              </h6>
              <p>
                {isCharter ? "Passengers" : "Guests"}:{" "}
                <span>{booking.passengers.length}</span>
              </p>
            </div>
            <div className="media-body col-lg-3 col-sm-6">
              <span>
                {hotel.nights} nights / {hotel.days} days
              </span>
            </div>
          </div>
        </div>

        {isCharter && (
          <div className="my-6">
            <h6 className="text-lg font-bold border-b border-b-blue-200">
              Flight Information
            </h6>
            <FlightDetails flight={depart} />
            <FlightDetails flight={returnFlight} />
          </div>
        )}

        <div className="my-6">
          <h6 className="text-lg font-bold border-b border-b-blue-200">
            {isCharter ? "Passenger" : "Guest"} information
          </h6>

          {booking.passengers.map((passenger, idx) => (
            <div key={idx} className="mt-2">
              <h6>
                {isCharter ? "Passenger" : "Guest"} {idx + 1}
              </h6>
              <div className="mb-3 mt-1 rounded-lg bg-blue-50 p-4">
                <div className="detail-middle col-lg-12">
                  <div className="media row">
                    <div className="media-body col-lg-4 col-sm-6">
                      {passenger.name}
                    </div>
                    <div className="media-body col-lg-4 col-sm-6">
                      <h6 className="media-heading">Age: {passenger.age}</h6>
                      <p>Birthday: {passenger.birthdate}</p>
                    </div>
                    <div className="media-body col-lg-4 col-sm-6">
                      <h6 className="media-heading">
                        Passport number: {passenger.passport_no}
                      </h6>
                      <p>
                        Expiry date:{" "}
                        <span>{passenger.passport_expire_date}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end">
          <h1 className="text-xl font-bold float-right">
            Total price: {currencyFormat(parseFloat(booking.total_price_mkd))}
          </h1>
        </div>

        <p className="mt-2">{T.booking.processed}</p>

        {booking.payment_method === PaymentTypeEnum.CreditCard && (
          <CCPayment
            successUrl={ccPaymentSuccessUrl}
            ccPaymentData={booking.cc_pay_data}
          />
        )}
      </div>
    </div>
  );
}
