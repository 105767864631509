import { useSearchRepository } from "../../context/search-repository-context";
import classNames from "classnames";
import { SEARCH_TYPES } from "@config/const/enums";
import { useTheme } from "../../../theme/useTheme";
import { T } from "@config/lang";
import { Settings } from "@config/tenants/settings";

export default function SearchBoxTabs() {
  const { searchBox } = useTheme();
  const { current, setSearchType } = useSearchRepository();
  const tabs =
    Settings.search.order?.map((it) => [it, SEARCH_TYPES[it]]) ??
    Object.entries(SEARCH_TYPES);

  const isActive = (tab) => current.searchType === tab;

  return (
    <div className={classNames(searchBox.tabs)}>
      {tabs.map(([key, searchType]) => (
        <div
          key={key}
          className={classNames([
            "cursor-pointer",
            searchBox.tab.any,
            isActive(key) ? searchBox.tab.active : searchBox.tab.inactive,
          ])}
          onClick={() => setSearchType(key)}
        >
          {T[`search_type_${searchType}`]}
        </div>
      ))}
    </div>
  );
}
