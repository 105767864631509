import { useMemo } from "react";
import Modal from "../../shared/components/modal/Modal";
import { useIsMobile } from "@presentation/shared/hooks/useIsMobile";

const DETAILS = {
  food_beverages: "Food Beverages",
  halls: "Halls",
  pools: "Pools",
  aquapark: "Aquapark",
  services: "Services",
  beach: "beach",
  for_chd: "For child",
  meals: "Meals",
  ala_carte_restaurants: "Ala Carte Restaurants",
  activities: "Activities",
  bars: "Bars",
  rooms: "Rooms",
};

const HotelDetails = ({ charter, onHide }) => {
  const isMobile = useIsMobile();

  const items = useMemo(() => {
    return Object.entries(DETAILS)
      .map(([key, label]) => ({ label, value: charter[key] }))
      .filter((it) => it.value);
  }, [charter]);

  return (
    <Modal
      onHide={onHide}
      title="Hotel Details"
      fullScreen={isMobile}
      height={isMobile ? "auto" : "fixed"}
    >
      <table>
        <tr>
          <td
            colSpan={2}
            className="py-1 px-2"
            dangerouslySetInnerHTML={{ __html: charter.description }}
          />
        </tr>
        {items.map(({ label, value }, idx) => (
          <tr key={idx}>
            <td className="font-bold text-gray-500 py-1 px-2">{label}:</td>
            <td className="py-1 px-2">{value}</td>
          </tr>
        ))}
      </table>
    </Modal>
  );
};

export default HotelDetails;
