import { useIFrame } from "@presentation/shared/context/iframe-context";
import { NewBooking } from "@presentation/pages/booking/NewBooking";

export function Booking() {
  const { hotelId, accommodationId } = useIFrame();

  return (
    <div className="mt-6">
      <NewBooking hotelId={hotelId} accommodationId={accommodationId} />
    </div>
  );
}
