import classNames from 'classnames';
import SearchBoxTabs from './SearchBoxTabs';
import SearchBox from './SearchBox';
import { useTheme } from '../../../theme/useTheme';

export default function SearchBoxHome() {
  const { searchBox } = useTheme();

  return (
    <div className={classNames(searchBox.container)}>
      <SearchBoxTabs />
      <div className={classNames(searchBox.form)}>
        <SearchBox />
      </div>
    </div>
  );
}
