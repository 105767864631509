import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";
import * as classNames from "classnames";
import { useTheme } from "@presentation/theme/useTheme";
import { BeforeFooter } from "@presentation/shared/layout/BeforeFooter";

export function HomeLayout({ children }) {
  const { main } = useTheme();

  return (
    <>
      <Header home />
      {main.home && (
        <main className={classNames("flex-grow", main.home)}>{children}</main>
      )}
      <BeforeFooter />
      <Footer />
    </>
  );
}
