import * as React from "react";
import { useUser } from "./context/user-context";
import { LoginForm } from "./LoginForm";
import { Main } from "@agent/Main";

export function Agent() {
  const { loggedIn } = useUser();

  if (!loggedIn) {
    return <LoginForm />;
  }

  return <Main />;
}
