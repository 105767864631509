import { FaStar } from "react-icons/fa";

type Props = {
  value: number;
  text?: string;
};

function Rating({ value, text }: Props) {
  const result = Array.from(new Array(value).keys());

  return (
    <div className="inline-flex">
      {result.map((iconType, i) => (
        <FaStar key={i} />
      ))}
      {text && <span>{text}</span>}
    </div>
  );
}

export default Rating;
