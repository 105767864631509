import { Outlet } from "react-router-dom";
import { NavigationProvider } from "./shared/context/navigation-context";
import { SearchRepositoryProvider } from "./shared/context/search-repository-context";
import { IFrameProvider } from "@presentation/shared/context/iframe-context";

function App() {
  return (
    <IFrameProvider>
      <NavigationProvider>
        <SearchRepositoryProvider>
          <div className="App h-screen w-screen flex flex-col font-base">
            <Outlet />
          </div>
        </SearchRepositoryProvider>
      </NavigationProvider>
    </IFrameProvider>
  );
}

export default App;
