export function parseTimeFromIso(date) {
  return date
    .substring(11, 16)
    .split(':')
    .map((it) => Number.parseInt(it, 10));
}

export function padzero(num) {
  return num > 9 ? num.toString() : `0${num}`;
}

export function formatTime([hour, min]) {
  return `${padzero(hour)}:${padzero(min)}`;
}

export function formatTimeToIso(time) {
  return `1970-01-01T${formatTime(time)}:00.000Z`;
}

export function maxTime([h1, m1], [h2, m2]) {
  return h1 === h2 ? [h1, Math.max(m1, m2)] : h1 > h2 ? [h1, m1] : [h2, m2];
}

export function minTime([h1, m1], [h2, m2]) {
  return h1 === h2 ? [h1, Math.min(m1, m2)] : h1 < h2 ? [h1, m1] : [h2, m2];
}

export function prevTime(t) {
  if (t === [0, 0]) return t;
  return t[1] > 0 ? [t[0], t[1] - 15] : [t[0] - 1, 45];
}

export function nextTime(t) {
  if (t === [23, 45]) return t;
  return t[1] < 45 ? [t[0], t[1] + 15] : [t[0] + 1, 0];
}

export function allTimesEqual(time, ...args) {
  const [h, m] = time;
  for (let t of args) {
    if (t[0] !== h || t[1] !== m) {
      return false;
    }
  }
  return true;
}
