import { useService } from "@infrastructure/api/hooks/use-service";
import { T } from "@config/lang";
import * as React from "react";
import { priceEuro } from "@presentation/shared/utils/price-format";
import { useMemo, useState } from "react";
import { cx } from "@presentation/vendor/classnames";
import { Dropdown } from "flowbite-react";
import { BsCalendar } from "react-icons/bs";
import { PageTitle } from "@agent/components/PageTitle";
import { ButtonGroup } from "@agent/components/ButtonGroup";
import { Requests } from "@infrastructure/api/requests";
import { SEARCH_TYPES } from "@config/const/enums";
import { Settings } from "@config/tenants/settings";

enum DOCUMENT_TYPES {
  VOUCHER = "/hotel-vouchers/HotelVoucher-{code}.pdf",
  TICKET = "/flight-tickets/FlightTicket-{code}.pdf",
  CONTRACT = "/contracts/Contract-{code}.pdf",
  INVOICE = "/pro-invoices/ProInvoice-{code}.pdf",
}

export function Bookings() {
  const { data: bookings } = useService(Requests.bookings, true);
  const { show_document_download } = Settings.b2b.bookings;

  const [confirmation, setConfirmation] = useState(-1);
  const confirmationOptions = useMemo(
    () => [
      { value: -1, name: T.agent.all },
      { value: 0, name: T.agent.pending, color: "bg-yellow-400" },
      { value: 3, name: T.agent.unpaid, color: "bg-orange-500" },
      { value: 1, name: T.agent.confirmed, color: "bg-green-500" },
      { value: 2, name: T.agent.rejected, color: "bg-red-500" },
    ],
    []
  );

  const filteredBookings = useMemo(
    () =>
      bookings?.filter(
        (it) => confirmation < 0 || confirmation === it.confirmation
      ),
    [confirmation, bookings]
  );

  const download = ({ code, pdf_url }, path) => {
    const url = pdf_url + path.replace("{code}", code);
    window.open(url);
  };

  return (
    <div>
      <div className="flex items-center mb-4 justify-between">
        <PageTitle Icon={BsCalendar} title={T.agent.bookings} />
        <ButtonGroup
          options={confirmationOptions}
          value={confirmation}
          onChange={setConfirmation}
        />
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.agent.code}
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.agent.service}
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.agent.hotel}
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.agent.checkin_checkout}
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.agent.client}
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.agent.price}
              <br />({T.agent.commission})
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.agent.date_time}
            </th>
            <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
              {T.agent.status}
            </th>
            {show_document_download && (
              <th className="px-2 py-2 whitespace-nowrap text-left font-bold text-gray-500 bg-gray-200">
                {T.agent.action}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {filteredBookings?.map((booking) => {
            const {
              // value,
              color = "gray",
              name = "N/A",
            } =
              confirmationOptions.find(
                (it) => it.value === booking.confirmation
              ) ?? {};
            return (
              <tr key={booking.code} className="even:bg-alter-50">
                <td className="px-2 py-2 whitespace-nowrap text-left tex-sm">
                  {booking.code}
                </td>
                <td className="px-2 py-2 whitespace-nowrap text-left tex-sm">
                  {booking.service}
                </td>
                <td className="px-2 py-2 text-left tex-sm max-w-[300px]">
                  <span className="font-bold">{booking.hotel}</span>
                  <br />
                  {booking.board}
                  <br />
                  {booking.acc}
                </td>
                <td className="px-2 py-2 whitespace-nowrap text-left tex-sm">
                  {booking.checkin} - {booking.checkout}
                </td>
                <td className="px-2 py-2 whitespace-nowrap text-left tex-sm">
                  {booking.client}
                  <br />
                  {booking.phone}
                </td>
                <td className="px-2 py-2 whitespace-nowrap tex-sm text-right">
                  <span className="font-bold">{booking.price} &euro;</span>
                  <br />
                  {booking.commission > 0
                    ? "(" + priceEuro(booking.commission) + ")"
                    : null}
                </td>
                <td className="px-2 py-2 whitespace-nowrap text-left tex-sm">
                  {booking.created_at?.replace(" - ", " ")}
                </td>
                <td className="px-2 py-2 whitespace-nowrap text-left tex-sm">
                  <span
                    className={cx(
                      "rounded-md px-3 py-1 text-white text-xs font-bold",
                      color
                    )}
                  >
                    {name}
                  </span>
                </td>
                {show_document_download && (
                  <td className="px-2 py-2 whitespace-nowrap text-left text-sm">
                    {[1, 3].includes(booking.confirmation) && (
                      <Dropdown label={T.agent.download} inline={true}>
                        {booking.confirmation === 1 && (
                          <Dropdown.Item
                            onClick={() =>
                              download(booking, DOCUMENT_TYPES.VOUCHER)
                            }
                          >
                            {T.agent.hotel_voucher}
                          </Dropdown.Item>
                        )}
                        {booking.service === SEARCH_TYPES.charter &&
                          booking.confirmation === 1 && (
                            <Dropdown.Item
                              onClick={() =>
                                download(booking, DOCUMENT_TYPES.TICKET)
                              }
                            >
                              {T.agent.flight_ticket}
                            </Dropdown.Item>
                          )}
                        {booking.confirmation === 1 && (
                          <Dropdown.Item
                            onClick={() =>
                              download(booking, DOCUMENT_TYPES.CONTRACT)
                            }
                          >
                            {T.agent.contract}
                          </Dropdown.Item>
                        )}
                        {[1, 3].includes(booking.confirmation) && (
                          <Dropdown.Item
                            onClick={() =>
                              download(booking, DOCUMENT_TYPES.INVOICE)
                            }
                          >
                            {T.agent.invoice}
                          </Dropdown.Item>
                        )}
                      </Dropdown>
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
