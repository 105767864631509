const DescriptionSection = ({ hotel }) => {
  if ((hotel.description?.trim() ?? '').length === 0) {
    return null;
  }
  return (
    <div className="mt-10">
      <h6 className="text-lg font-bold">Description</h6>
      <p dangerouslySetInnerHTML={{ __html: hotel.description }} />
    </div>
  );
};

export default DescriptionSection;
