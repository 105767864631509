import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import { cx } from "@presentation/vendor/classnames";
import { useTheme } from "../../theme/useTheme";
import { HiMenu } from "react-icons/hi";
import { useRef, useState } from "react";
import { useOnClickOutside } from "@presentation/shared/hooks/useOnClickOutside";
import { AiOutlineClose, AiOutlineUser } from "react-icons/ai";
import { useIsMobile } from "@presentation/shared/hooks/useIsMobile";
import { useApp } from "@application/context/app-context";
import { Settings } from "@config/tenants/settings";
import { Settings as LanguageSettings } from "@config/tenants/bootstrap";
import { T } from "@config/lang";
import { useCompany } from "@application/context/company-context";

interface MenuItem {
  to?: string;
  href?: string;
  target?: string;
  items?: MenuItem[];
  name: string;
  active?: boolean;

  onClick?(): void;
}

function HeaderMenu() {
  const { logo, name } = useCompany();
  const { header } = useTheme();
  const { language, setLanguage } = useApp();
  const isMobile = useIsMobile();

  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));

  if (header.menu.hide) {
    return null;
  }

  const topLinks = Settings.links.top;
  const menuLinks = Settings.links.menu;
  const links = [];
  if (menuLinks.includes("b2b")) {
    links.push({
      to: "/agent",
      Icon: AiOutlineUser,
      name: T.b2b_login,
    });
  }

  const menu: MenuItem[] = [
    { to: "/", name: T.menu.home },
    ...Settings.menuLinks.map(({ name, url, target }) => ({
      name,
      href: url,
      target,
    })),
    { to: "/about-us", name: T.menu.about_us },
    { to: "/contact", name: T.menu.contact_us },
    ...links,
  ];

  if (isMobile) {
    if (!menuLinks.includes("b2b") && topLinks.includes("b2b")) {
      menu.push({ name: T.b2b_login, to: "/agent" });
    }
    if (!menuLinks.includes("lang") && topLinks.includes("lang")) {
      menu.push({
        name: T.menu.language,
        items: LanguageSettings.languages.map(({ locale, name }) => {
          const active = locale === language;
          return {
            name,
            active,
            onClick: () => {
              setLanguage(locale);
              setOpen(false);
            },
          };
        }),
      });
    }
  }

  return (
    <div className={cx(header.menu.container)} ref={ref}>
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <img src={logo} className={cx(header.logo)} alt={name} />
        </Link>
        <ul
          className={cx(
            header.menu.ul,
            isMobile ? header.menu.mobile.ul : header.menu.desktop.ul,
            isMobile && !open && "hidden"
          )}
        >
          {isMobile && (
            <li className="md:hidden flex justify-end p-4">
              <div
                onClick={() => setOpen(false)}
                className="absolute text-gray-400 hover:text-gray-600 cursor-pointer"
              >
                <AiOutlineClose size={20} />
              </div>
            </li>
          )}
          {menu.map(({ name, to, href, target, items }) => (
            <li key={name} className={cx(header.menu.li)}>
              {to ? (
                <NavLink
                  to={to}
                  onClick={() => setOpen(false)}
                  className={({ isActive, isPending }) =>
                    cx(
                      header.menu.link,
                      isPending
                        ? header.menu.pending
                        : isActive
                        ? header.menu.active
                        : ""
                    )
                  }
                >
                  {name}
                </NavLink>
              ) : href ? (
                <a href={href} className={cx(header.menu.link)} target={target}>
                  {name}
                </a>
              ) : (
                <div>
                  <div className="border-b">{name}</div>
                  <ul>
                    {items.map(({ onClick, name, active }) => (
                      <li
                        key={name}
                        onClick={onClick}
                        className={cx(
                          "my-2 ml-2",
                          active
                            ? "font-bold"
                            : "cursor-pointer hover:text-primary-500"
                        )}
                      >
                        {name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div
          className="md:hidden text-2xl mouse-pointer opacity-75 hover:text-gray-600 cursor-pointer"
          onClick={() => setOpen((b) => !b)}
        >
          <HiMenu />
        </div>
      </div>
    </div>
  );
}

// 'md:hidden'

export default HeaderMenu;
