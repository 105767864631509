import Modal from "@presentation/shared/components/modal/Modal";
import * as React from "react";
import { priceEuro } from "@presentation/shared/utils/price-format";
import { T } from "@config/lang";
import {
  HotelResult,
  HotelResultRoom,
} from "@agent/pages/new-booking/SearchResults";
import { Button } from "@presentation/shared/components/button/Button";
import Rating from "@presentation/shared/components/rating/Rating";

type Props = {
  hotel: HotelResult;
  bookNow(hotel: HotelResult, room: HotelResultRoom): void;
  onHide(): void;
};

export function MoreRooms({ hotel, bookNow, onHide }: Props) {
  return (
    <Modal
      title={
        <div className="w-full flex justify-between">
          <div className="mr-10">
            <div>
              <span className="font-bold hover:text-primary-500 cursor-pointer">
                {hotel.name}
              </span>
              <span className="ml-5 text-yellow-400 text-sm">
                <Rating value={hotel.category} />
              </span>
            </div>
            <div className="text-sm">{hotel.location}</div>
          </div>
          <div className="font-base text-base mr-10">
            <div>Adults: {hotel.adl}</div>
            <div>Children: {hotel.chd}</div>
          </div>
          {hotel.depart_flight && (
            <div className="font-base text-base mr-10">
              <div>
                {T.results.departure}: {hotel.depart_flight.flight_date}{" "}
                {hotel.depart_flight.depart?.substring(0, 5)}
              </div>
              <div>
                {T.results.return}: {hotel.return_flight.flight_date}{" "}
                {hotel.return_flight.depart?.substring(0, 5)}
              </div>
            </div>
          )}
          <div className="font-base text-base mr-10">
            <div>
              {T.results.check_in}: {hotel.checkin}
            </div>
            <div>
              {T.results.check_out}: {hotel.checkout}
            </div>
          </div>
        </div>
      }
      onHide={onHide}
    >
      <table>
        <thead>
          <tr>
            <th>{T.results.room_type}</th>
            <th>{T.results.board}</th>
            <th>{T.results.price}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {hotel.room_details.map((room) => (
            <tr key={room.accommodation_id} className="odd:bg-gray-100">
              <td className="p-3">
                <div className="whitespace-nowrap truncate max-w-[400px]">
                  {hotel.room}
                </div>
                <div className="whitespace-nowrap truncate max-w-[400px]">
                  {room.room_type}
                </div>
              </td>
              <td className="text-center p-3">
                <div>{room.board}</div>
              </td>
              <td className="text-center p-3">
                <div>
                  {room.promotions > 0 && (
                    <div>
                      <del className="mr-2 whitespace-nowrap">
                        {priceEuro(room.price + room.promotions)}
                      </del>
                    </div>
                  )}
                  <div className="text-lg whitespace-nowrap">
                    {priceEuro(room.price)}
                  </div>
                  {room.stop_sale === 1 && (
                    <div>
                      <span className="bg-red-800 text-white px-2 my-1 font-bold">
                        Stop sale
                      </span>
                    </div>
                  )}
                </div>
              </td>
              <td className="text-center p-3">
                <Button type="primary" onClick={() => bookNow(hotel, room)}>
                  {T.results.book_now}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
}
