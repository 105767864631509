import { useNavigate } from "react-router-dom";
import { useSearchRepository } from "@presentation/shared/context/search-repository-context";

type Props = {
  offers: {
    id: string;
    title: string;
    description: string;
    price: number;
    image: {
      medium: string;
    };
    link: string;
  }[];
};

export function Offers({ offers }: Props) {
  const {
    current: { resetSearch },
  } = useSearchRepository();
  const navigate = useNavigate();

  const visitOffer = (url: string) => {
    resetSearch();
    navigate(url);
  };

  if (!offers) {
    return null;
  }

  return (
    <div className="container mx-auto sm:grid sm:grid-cols-2 md:grid-cols-3 gap-10 py-10">
      {offers?.map((offer) => {
        const {
          id,
          title,
          description,
          price,
          image: { medium },
          link,
        } = offer;
        return (
          <div
            key={id}
            className="flex flex-col rounded-md border border-primary-50 hover:shadow-xl cursor-pointer mb-10 sm:mb-0"
            onClick={() =>
              visitOffer(
                "/search/" +
                  link
                    .split("/")
                    .filter((it) => it.length)
                    .at(-1)
              )
            }
          >
            <div
              className="bg-cover bg-center min-h-[200px]"
              style={{ backgroundImage: `url(${medium})` }}
            />
            <div className="flex flex-col flex-grow px-4 py-2">
              <div className="font-bold text-lg">{title}</div>
              <div className="flex-grow">
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
              </div>
              <div className="text-right font-bold">{price}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
