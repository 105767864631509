import { ThemeConfig } from "./ThemeConfig";

export const theme: Partial<ThemeConfig> = {
  fonts: [
    {
      font: "Source Sans Pro",
      weights: ["400", "400i", "500", "700"],
    },
    {
      font: "Averia Serif Libre",
      weights: ["400"],
    },
  ],
  header: {
    top: {
      container: "hidden md:block bg-gray-800 bg-opacity-50 text-white py-2",
      link: "mx-4 last:mr-0 my-1 relative inline-block text-left text-sm font-bold hover:opacity-70",
      language: {
        container:
          "absolute py-2 rounded-md text-lg right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
        item: "px-4 py-2 block",
        default:
          "cursor-pointer ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black",
        selected: "text-gray-500",
      },
    },
    menu: {
      container: "bg-white bg-opacity-70 text-pink-500 py-2",
      ul: "",
      li: "px-3 py-2",
      link: "block md:inline-block text-base font-bold hover:text-primary-500",
      pending: "opacity-60",
      active: "text-primary-500",
      mobile: {
        ul: "z-50 absolute left-0 top-0 min-h-screen w-full bg-white",
      },
      desktop: {
        ul: "flex -mr-2",
      },
    },
    logo: "w-52 my-2",
    home: {
      background: "min-h-screen md:min-h-[550px]",
      container:
        "container mx-auto flex-grow flex flex-col justify-start md:justify-around bg-opacity-50",
      message: "my-10 md:my-0 container mx-auto text-white text-shadow-md",
      title:
        "mx-auto text-3xl md:text-4xl lg:text-5xl my-6 font-bold text-center font-display text-shadow-md",
      subtitle: "mx-auto my-4 text-xl lg:text-2xl text-center",
      showBackground: true,
    },
    page: {
      background: "bg-blue-400",
      container: null,
      message: false,
      title: "hidden",
      subtitle: "mx-auto my-4 text-xl lg:text-2xl text-center",
      showBackground: false,
    },
  },
  footer: {
    menu: {
      container: "border-primary-500 border-t-2 bg-primary-800 text-white py-6",
    },
    logo: "w-36 my-2",
    bottom: {
      container: "bg-primary-900 text-sm text-white py-2",
    },
  },
  button: {
    base: "border border-transparent cursor-pointer rounded-md",
    primary: [
      "bg-primary-600 hover:bg-primary-400 text-white",
      "bg-gray-400 text-white",
    ],
    secondary: [
      "bg-alter-600 hover:bg-alter-400 text-white",
      "bg-gray-400 text-white",
    ],
    outline: [
      "border-primary-400 hover:border-primary-600",
      "text-gray-400 border-gray-400",
    ],
    size: {
      xs: "text-xs px-2 py-1",
      sm: "text-sm px-3 py-1.5",
      base: "text-base px-4 py-1.5",
      lg: "text-lg px-5 py-2.5",
      xl: "text-xl px-6 py-3",
    },
  },
  searchBox: {
    container: "mb-10 shadow-md drop-shadow-md",
    tabs: "flex justify-start",
    tab: {
      any: "px-3 py-2 border-r border-blue-300 rounded-t-md",
      inactive: "bg-blue-300 hover:bg-white",
      active: "bg-white",
    },
    form: "bg-white p-4 rounded-b-md rounded-tr-md",
    input: "text-base",
    internal: {
      container: "bg-cyan-100",
    },
  },
  main: {
    home: "w-full",
    page: "w-full",
  },
  results: {
    single: "border rounded-lg overflow-hidden",
    image: "",
    data: "p-4",
  },
};
